import { useContext, useState, useMemo, useEffect } from "react";
import "./compFileListItem.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as Home from "../pages/home"
import * as AssetUtils from "../utils/AssetUtils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as UserInfo from "../common/userInfo"
import ButtonSpeechAnalyze from "./buttonSpeechAnalyze"
import * as Department from "../utils/Department"
import { SelectManagementTabContext } from "./compManagement"
import { SelectMovieWatchTabContext } from "./compMovieWatch"

export default function (props) {
    const userInfo = UserInfo.getUsetInfo();
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
    const [condFileSearchAndFilter, setCondFileSearchAndFilter] = props.condFileSearchAndFilter;
    const [historyAssetIds, setHistoryAssetIds] = (Utils.isEmpty(props.stateHistoryAssetIds) == true) ? [[], null] : props.stateHistoryAssetIds;
    const [favoriteAssetIds, setFavoriteAssetIds] = (Utils.isEmpty(props.stateFavoriteAssetIds) == true) ? [[], null] : props.stateFavoriteAssetIds;
    const isWordCloud = (Utils.isEmpty(props.isWordCloud)) ? false : props.isWordCloud;

    const [dataAsset, setDataAsset] = useState(props.asset);
    useMemo(() => {
        setDataAsset(props.asset);
    }, [props.asset])
    const isFromComponent = (Utils.isEmpty(props.isFromComponent)) ? Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id : props.isFromComponent;
    const isDebug = (Utils.isEmpty(props.isDebug)) ? false : props.isFromReferences;
    const isCanDelete = (Utils.isEmpty(props.isCanDelete)) ? false : props.isCanDelete;
    const isCanFavorite = (Utils.isEmpty(props.isCanFavorite)) ? false : props.isCanFavorite;
    const isCanForceDelete = (Utils.isEmpty(props.isCanForceDelete)) ? false : props.isCanForceDelete;
    const isCanRestore = (Utils.isEmpty(props.isCanRestore)) ? false : props.isCanRestore;
    const FileDeleteCallback = (Utils.isEmpty(props.onFileDeletedCallback) ? () => { } : props.onFileDeletedCallback);
    const FileRestoreCallback = (Utils.isEmpty(props.onFileRestoredCallback) ? () => { } : props.onFileRestoredCallback);

    if (Utils.isEmpty(dataAsset) == true) return;

    const [selectedTabItemsValue, setSelectedTabItemsValue] =
        (isFromComponent == Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id
            || isFromComponent == Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING.id
            || isFromComponent == Defines.SELECT_TAB.MANAGEMENT.DELETED_MOVIE.id)
            ? useContext(SelectManagementTabContext) : useContext(SelectMovieWatchTabContext);

    const [isFavoriteInUser, setIsFavoriteInUser] = useState(favoriteAssetIds.includes(dataAsset.asset_id));
    useEffect(() => {
        var _isFavorite;
        if (favoriteAssetIds.includes(dataAsset.asset_id)) {
            _isFavorite = true;
        } else {
            _isFavorite = false;
        }
        setIsFavoriteInUser(_isFavorite);
    }, [favoriteAssetIds, dataAsset])

    const handleAssetItemClick = (item) => {
        if (isFromComponent == Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING.id) return;

        setSelectAsset(item);

        if (Utils.isEmpty(setFavoriteAssetIds) != true) {
            var _histories = historyAssetIds.filter((_item) => _item != item.asset_id);
            _histories.unshift(item.asset_id);   // insert top
            setHistoryAssetIds(_histories);
        }
    };

    const handleAssetItemDoubleClick = (item) => {
        if (isFromComponent == Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING.id) return;

        if (isFromComponent == Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id) {
            setSelectedTabItemsValue(Defines.SELECT_TAB.MANAGEMENT.SPEECH_ANALYZE.id);
        } else if (isFromComponent == Defines.SELECT_TAB.MOVIE_WATCH.MOVIE_SEARCH.id) {
            setSelectedTabItemsValue(Defines.SELECT_TAB.MOVIE_WATCH.DISP_SPEECH_TO_TEXT.id);
        }
    }

    const handleAssetDeleteClick = (item) => {
        if (isFromComponent != Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id) return;

        const isOk = window.confirm("アセット(" + item.asset_name + ")の削除を行いますか？");
        if (isOk) {
            AssetUtils.AsyncDoDelete(item)
                .then((data) => {
                    setCondFileSearchAndFilter({ ...condFileSearchAndFilter });
                    FileDeleteCallback();
                });
        }
    };

    const updateDeliveryStatusRepeatedly = (item) => {
        const updateStatus = (item) => {
            RestBase.GET(
                Config.DaAlpsRestServer + "assets/" + item.asset_id
            ).then((data) => {
                console.log(data.asset)
                if (data.asset.status.delivery == Defines.ASSET_STATUS.ASSET_STATUS_PROCESSING) {
                    setTimeout(updateStatus(item), 1000)
                } else {
                    setDataAsset(data.asset)
                }
            })
        }
        updateStatus(item);
    }

    const handleDeliverdClick = (item) => {
        if (isFromComponent != Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id) return;

        AssetUtils.AsyncDoStopDelivery(item)
            .then((data) => {
                updateDeliveryStatusRepeatedly(item)
            });
    }

    const handleNoDeliverdClick = (item) => {
        if (isFromComponent != Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id) return;

        AssetUtils.AsyncDoDelivery(item)
            .then((data) => {
                updateDeliveryStatusRepeatedly(item)
            });
    }

    const styleShow = { visibility: "visible" }
    const styleHidden = { visibility: "collapse" }

    const restSetReference = (asset, referenceId) => {
        RestBase.POST(
            Config.DaAlpsRestServer + "assets/reference"
            , {
                asset_id: asset.asset_id,
                reference_asset_id: referenceId,
            }
        ).then((data) => {
            RestBase.GET(
                Config.DaAlpsRestServer + "assets/" + selectAsset.asset_id
            )
                .then((data) => {
                    setSelectAsset(data.asset);
                });
        });
    }
    const restDeleteReference = (asset, referenceId) => {
        RestBase.DELETE(
            Config.DaAlpsRestServer + "assets/reference"
            , {
                asset_id: asset.asset_id,
                reference_asset_id: referenceId,
            }
        ).then((data) => {
            RestBase.GET(
                Config.DaAlpsRestServer + "assets/" + selectAsset.asset_id
            )
                .then((data) => {
                    setSelectAsset(data.asset);
                });
        });
    }
    const handleClick_SetReference = (baseAsset, ownAsset) => {
        const referenceIds = baseAsset.references.map(item => item["reference_asset_id"])
        if (referenceIds.includes(ownAsset.asset_id) == true) return;

        restSetReference(baseAsset, ownAsset.asset_id);
    }
    const handleClick_ResetReference = (baseAsset, ownAsset) => {
        var referenceIds = baseAsset.references.map(item => item["reference_asset_id"])
        if (referenceIds.includes(ownAsset.asset_id) != true) return;

        restDeleteReference(baseAsset, ownAsset.asset_id);
    }

    const handleAssetFavoriteClick = (item) => {
        if (isCanFavorite != true) return;
        RestBase.DELETE(
            Config.DaAlpsRestServer + "assets/favorite",
            {
                user_id: UserInfo.getUsetInfo().user_id,
                asset_id: dataAsset.asset_id
            }
        ).then(async () => {
            setFavoriteAssetIds(await UserInfo.getFavoriteAssetIds());
        })
    }

    const handleAssetFavoriteNoneClick = (item) => {
        if (isCanFavorite != true) return;
        RestBase.POST(
            Config.DaAlpsRestServer + "assets/favorite",
            {
                user_id: UserInfo.getUsetInfo().user_id,
                asset_id: dataAsset.asset_id
            }
        ).then(async () => {
            setFavoriteAssetIds(await UserInfo.getFavoriteAssetIds());
        })
    }

    const handleRecoveryClick = (item) => {
        if (isFromComponent != Defines.SELECT_TAB.MANAGEMENT.DELETED_MOVIE.id) return;

        const isOk = window.confirm("アセット(" + item.asset_name + ")を復元しますか？");
        if (isOk) {
            AssetUtils.AsyncDoRestore(item)
                .then((data) => {
                    setCondFileSearchAndFilter({ ...condFileSearchAndFilter });
                });
        }
        FileRestoreCallback();
    }

    const handleAssetForceDeleteClick = (item) => {
        if (isFromComponent != Defines.SELECT_TAB.MANAGEMENT.DELETED_MOVIE.id) return;

        const isOk = window.confirm("アセット(" + item.asset_name + ")を強制削除しますか？");
        if (isOk) {
            AssetUtils.AsyncDoForceDelete(item)
                .then((data) => {
                    setCondFileSearchAndFilter({ ...condFileSearchAndFilter });
                })
        }
    }

    const [allDepartment, setAllDepartment] = useState([]);
    useEffect(() => {
        (async () => {
            // setAllDepartment(await Department.GetAllDepartment());
        })();
    }, []);

    const valueCategory = useMemo(() => {
        if (Utils.isEmpty(allDepartment) == true) return "";

        const metaInfo = AssetUtils.GetMetaInfo(dataAsset, Defines.ASSET_META_ID.DEPARTMENT_ID.id);
        if (Utils.isEmpty(metaInfo) == true) return "";

        const department = allDepartment.find((item) => {
            if (item.id == metaInfo.value_text) {
                return item;
            }
        });
        if (Utils.isEmpty(department) == true) return "";

        return department.name;
    }, [dataAsset, allDepartment])

    const tagButtons = (() => {
        switch (isFromComponent) {
            case Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id:
                {
                    if (userInfo.permission_id != Defines.USER_PERMISSION_ID.ADMIN) return;
                    return (
                        <div>
                            <ButtonSpeechAnalyze {...[dataAsset, setDataAsset, props.analyzeSettings]} />
                            {
                                ((item) => {
                                    if (item.status.delivery == Defines.ASSET_STATUS.ASSET_STATUS_NONE) {
                                        return (
                                            <button className="buttonNoDeliverd" id={item.id} onClick={(e) => handleNoDeliverdClick(dataAsset)}>
                                                <label className="cursorUnset" htmlFor={item.id}>配信開始する</label>
                                            </button>
                                        )
                                    } else if (item.status.delivery == Defines.ASSET_STATUS.ASSET_STATUS_STOP) {
                                        return (
                                            <button className="buttonNoDeliverd" id={item.id} onClick={(e) => handleNoDeliverdClick(dataAsset)}>
                                                <label className="cursorUnset" htmlFor={item.id}>
                                                    <img className="sizeIconSmall" src="../images/pause.png" />
                                                </label>
                                                <label className="cursorUnset" htmlFor={item.id}>配信停止中</label>
                                            </button>
                                        )
                                    } else {
                                        return (
                                            <button className="buttonDeliverd" id={item.id} onClick={(e) => handleDeliverdClick(dataAsset)}>
                                                <label className="cursorUnset" htmlFor={item.id}>
                                                    <img className="sizeIconSmall" src="../images/play.png" />
                                                </label>
                                                <label className="cursorUnset" htmlFor={item.id}>配信中</label>
                                            </button>
                                        )
                                    }
                                })(dataAsset)
                            }
                        </div>
                    )
                }
                break;
            case Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING.id:
                {
                    const referenceBaseAsset = props.referenceBaseAsset;

                    if (Utils.isEmpty(referenceBaseAsset) == true) return;
                    const references_asset_ids = referenceBaseAsset.references.map(item => item["reference_asset_id"]);

                    var className = "";
                    var label = "";
                    var callback = null;
                    if (dataAsset.asset_id == referenceBaseAsset.asset_id) {
                        className = "buttonReferenceCurrent";
                        label = "設定対象の動画（自分自身）";
                        callback = null;
                    } else {
                        if (references_asset_ids.includes(dataAsset.asset_id)) {
                            className = "buttonReferenceReferenced";
                            label = "関連動画から外す";
                            callback = handleClick_ResetReference;
                        } else {
                            className = "buttonReferenceNoReference";
                            label = "関連動画に設定";
                            callback = handleClick_SetReference;
                        }
                    }

                    return (
                        <div>
                            <button className={className} id={dataAsset.asset_id} onClick={(e) => callback(referenceBaseAsset, dataAsset)}>
                                <label className="cursorUnset" htmlFor={dataAsset.asset_id}>{label}</label>
                            </button>
                        </div>
                    )
                }
                break;
            case Defines.SELECT_TAB.MANAGEMENT.DELETED_MOVIE.id:
                return (
                    <button className="buttonRecovery" id={dataAsset.asset_id} onClick={(e) => handleRecoveryClick(dataAsset)}>
                        <label className="labelButtonRecovery" htmlFor={dataAsset.asset_id}>復元する</label>
                    </button>
                )
                break;
            case Defines.SELECT_TAB.MOVIE_WATCH.MOVIE_SEARCH.id:
                {

                }
                break;
        }
    })

    const styleMetaLabel = {
        width: "70px"
    }
    const styleMetaColon = {
        width: "20px"
    }

    return (
        <div className="compFileListItem" id={dataAsset.asset_id} onClick={(e) => handleAssetItemClick(dataAsset)} onDoubleClick={(e) => handleAssetItemDoubleClick(dataAsset)}>
            <div className={(dataAsset.asset_id == selectAsset.asset_id) ? "selectedItem" : "nonSelectItem"}>
                <table width="100%">
                    <tbody>
                        <tr style={{ position: "relative" }}>
                            <td className="areaThumbnail">
                                <img className="imgThumbnail" src={AssetUtils.GetThumbnailWwwPath(dataAsset, isWordCloud)} onError={(e) => { e.target.src = "/images/offline.png"; e.target.style.width = "50%" }} alt={dataAsset.asset_id} />
                                <button className="positionTR visibleNone" id="buttonDelete" onClick={(e) => handleAssetDeleteClick(dataAsset)}>
                                    <img className="buttonAssetDelete positionTR" htmlFor="buttonDelete" src="../images/close.png"
                                        style={(isCanDelete == true) ? styleShow : styleHidden} />
                                </button>
                                <button className="positionTR visibleNone" id="buttonFavorite" onClick={(e) => handleAssetFavoriteClick(dataAsset)}>
                                    <img className="buttonAssetFavorite positionTR" htmlFor="buttonFavorite" src="../images/favorite.png"
                                        style={(isCanFavorite == true && isFavoriteInUser == true) ? styleShow : styleHidden} />
                                </button>
                                <button className="positionTR visibleNone" id="buttonFavorite" onClick={(e) => handleAssetFavoriteNoneClick(dataAsset)}>
                                    <img className="buttonAssetFavorite positionTR" htmlFor="buttonFavorite" src="../images/favorite_none.png"
                                        style={(isCanFavorite == true && isFavoriteInUser == false) ? styleShow : styleHidden} />
                                </button>
                                <button className="positionTR visibleNone" id="buttonForceDelete" onClick={(e) => handleAssetForceDeleteClick(dataAsset)}>
                                    <img className="buttonAssetDelete positionTR" htmlFor="buttonForceDelete" src="../images/close.png"
                                        style={(isCanForceDelete == true) ? styleShow : styleHidden} />
                                </button>
                            </td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={styleMetaLabel}>講義日</td>
                                            <td style={styleMetaColon}>：</td>
                                            <td>{
                                                dataAsset.metas.map((item) => {
                                                    if (item.meta_id == Defines.ASSET_META_ID.DAY_ATTENDED.id) {
                                                        return Utils.ConvStrDateTime2Date(item.value_date, "YYYY/MM/DD");
                                                    }
                                                })
                                            }</td>
                                        </tr>
                                        <tr>
                                            <td style={styleMetaLabel}>講義名</td>
                                            <td style={styleMetaColon}>：</td>
                                            <td>{
                                                dataAsset.metas.map((item) => {
                                                    if (item.meta_id == Defines.ASSET_META_ID.LECTURE_NAME.id) {
                                                        return item.value_text;
                                                    }
                                                })
                                            }</td>
                                        </tr>
                                        <tr>
                                            <td style={styleMetaLabel}>所属</td>
                                            <td style={styleMetaColon}>：</td>
                                            <td>{
                                                dataAsset.metas.map((item) => {
                                                    if (item.meta_id == Defines.ASSET_META_ID.AFFILIATION.id) {
                                                        return item.value_text;
                                                    }
                                                })
                                            }</td>
                                        </tr>
                                        <tr>
                                            <td style={styleMetaLabel}>教員名</td>
                                            <td style={styleMetaColon}>：</td>
                                            <td>{
                                                dataAsset.metas.map((item) => {
                                                    if (item.meta_id == Defines.ASSET_META_ID.TEACHER.id) {
                                                        return item.value_text;
                                                    }
                                                })
                                            }</td>
                                        </tr>
                                        <tr>
                                            <td style={styleMetaLabel}>長さ</td>
                                            <td style={styleMetaColon}>：</td>
                                            <td>{dataAsset.property.movie?.duration ? dataAsset.property.movie.duration : ""}</td>
                                        </tr>
                                        <tr height="10px" />
                                        <tr>
                                            <td colSpan={3}>
                                                {tagButtons()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}