import Config from "../config"
import * as RestBase from '../utils/RestBase.js'

export const NAME_SETTINGS = {
    AUTO_ANALYZE_SPEECH: "AutoAnalyzeSpeech",
    ANALYZE_SPEECH_CONFIDENCE_THRESHOLD: "AnalyzeSpeechConfidenceThreshold",
    AUTO_DELIVERY: "AutoDelivery",
    CINNAMON_DEFAULT_SETTING: "CinnamonDefaultSetting",
}

// 収録後に自動音声解析 の取得
export async function GetSettingAutoAnalyzeSpeech() {
    const resp = await RestBase.GET(
        Config.DaAlpsRestServer + "system_info/AutoAnalyzeSpeech"
    );
    return (resp.length > 0 ? resp[0] : null);
}

// 音声解析 信頼度 閾値 の取得
export async function GetSettingAnalyzeSpeechConfidenceThreshold() {
    const resp = await RestBase.GET(
        Config.DaAlpsRestServer + "system_info/AnalyzeSpeechConfidenceThreshold"
    );
    return (resp.length > 0 ? resp[0] : null);
}

// 収録後に自動配信 の取得
export async function GetSettingAutoDelivery() {
    const resp = await RestBase.GET(
        Config.DaAlpsRestServer + "system_info/AutoDelivery"
    );
    return (resp.length > 0 ? resp[0] : null);
}

// 設定データの更新
export async function SetSettings(arySetting) {
    const promise = await RestBase.POST(
        Config.DaAlpsRestServer + "system_info",
        arySetting
    );
    return promise;
}
