import React, { useContext, useEffect, useState, useMemo } from "react";
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from "chart.js"
import { Line, Pie } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Scrollbar } from "swiper/modules"
import "../common/graphOptions"
import { graphCommonOptions, viewCountOptions, viewStatusOptions, viewTimeOptions } from "../common/graphOptions";
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import * as Home from "../pages/home"
import * as RestBase from "../utils/RestBase"
import * as Utils from "../utils/Utils"
import Ranking from "./graphComponents/compRanking"
import PlayAreaTable from "./graphComponents/compPlayAreaTable"
import Config from "../config"
import "./compWatchInfo.css"


Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    ChartDataLabels,
    Legend
)

export default function (props) {
    // console.log(props)
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
    const [playStatusPieChart, setPlayStatusPieChart] = useState(<div />);
    const [searchWordRanking, setSearchWordRanking] = useState(<div />);
    const [topChartSwiper, setTopChartSwiper] = useState(<div />);
    const [swiper, setSwiper] = useState(null);
    React.useEffect(() => {
        if (Utils.isEmpty(selectAsset)) return;
        // Swiperに表示するグラフを作る
        const createCharts = async () => {
            var ret = [];
            var historyDatas = await getPlayHistoryData(selectAsset);
            var historyChart = PlayHistoryLineChart(historyDatas);
            ret.push(historyChart);

            // 管理者の場合のみ表示する
            if (props.admin) {
                var countDatas = await getPlayCountData(selectAsset);
                var countChart = PlayCountLineChart(countDatas);
                ret.push(countChart);

                var partDatas = await getPartData(selectAsset);
                var playAreaChart = makePlayAreaTable(partDatas);
                ret.push(playAreaChart);
            }
            return ret
        }
        createCharts().then((charts) => {
            PlayStatusPieChart();
            SearchWordRanking();
            TopChartSwiper(charts);
        })
    }, [selectAsset])
    React.useEffect(() => {
        if (swiper == null) return;
        if (swiper.destroyed) return;
        // 表示が行われるまで一定間隔でサイズを更新し続ける(swiperは画面に表示されたタイミングで高さを再計算する必要があるため)
        var timer = setInterval(() => {
            var swip = document.getElementById("top_chart_swiper")
            // 高さ更新前に画面切り替えなど行った場合elementが消えてもintervalが残り続けるので、取得できなかった場合は削除する
            if (swip == null || swip.destroyed) {
                clearInterval(timer);
                return;
            } else {
                if ("0px" != swip.children[0].style.height) {
                    clearInterval(timer);
                }
            }
            swiper.updateAutoHeight()
        }, 1000);
    }, [swiper])
    const getPlayHistoryData = (asset) => {
        return RestBase.GET(Config.DaAlpsRestServer + `assets/viewing/part/${asset.asset_id}`)
    }
    const PlayHistoryLineChart = (datas) => {
        const options = { ...viewTimeOptions }
        const labels = datas.map(d => d.minute);
        const partDatas = datas.map(d => d.count);
        const PlayHistoryData = {
            labels: labels,
            datasets: [{
                data: partDatas,
                fill: true,
                borderColor: 'rgb(38, 119, 158)',
                backgroundColor: 'rgba(38, 119, 158, 0.18)',
                tension: 0,
                order: true,
                datalabels: {
                    display: false,
                }
            }]
        }
        return (
            <div className="chartPlayHistory" style={{ width: "100%" }}>
                <Line data={PlayHistoryData} responsive="true" options={{ ...options, scales: { ...options.scales, y: { ...options.scales.y, max: Math.max(...partDatas) + (Math.max(Math.max(...partDatas) / 10, 5)), min: 0 } } }} />
            </div>
        )
    }

    const getPlayCountData = (asset) => {
        return RestBase.GET(Config.DaAlpsRestServer + `assets/viewing/count/${asset.asset_id}`)
    }
    const PlayCountLineChart = (datas) => {
        const options = { ...viewCountOptions }
        const labels = datas.map((data) => data.date.substring(5));
        const data = datas.map((data) => parseInt(data.count));
        const PlayCountData = {
            labels: labels,
            datasets: [{
                data: data,
                fill: true,
                borderColor: 'rgb(38, 119, 158)',
                backgroundColor: 'rgba(38, 119, 158, 0.18)',
                tension: 0,
                order: true,
                datalabels: {
                    font: {
                        size: 28,
                    },
                    align: "top",
                }
            }]
        }
        return (
            <div className="chartPlayCount">
                <Line data={PlayCountData} responsive="true" options={{ ...options, scales: { ...options.scales, y: { ...options.scales.y, max: Math.max(...data) + (Math.max(Math.max(...data) / 10, 5)), min: 0 } } }} />
            </div>
        )
    }

    const getPartData = (asset) => {
        return RestBase.GET(Config.DaAlpsRestServer + `assets/viewing/user_part/${selectAsset.asset_id}`
        ).then((part_data) => {
            return part_data;
        })
    }
    const makePlayAreaTable = (datas) => {
        return (
            <PlayAreaTable data={datas.map(data => { return { ...data, name: data.user_name } })} h1text="氏名" h2text="状況" h3text="再生箇所" />
        );
    }

    const TopChartSwiper = (charts) => {
        setTopChartSwiper(
            <div className="topChartSwiper">
                <Swiper
                    id={`top_chart_swiper`}
                    modules={[Navigation, Pagination, Scrollbar]}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{
                        el: '.topChartSwiperPagination',
                        clickable: true
                    }}
                    autoHeight
                    observer
                    observeSlideChildren
                    observeParents
                    onSwiper={(swiper) => { setSwiper(swiper) }}
                >
                    {charts.map((chart, index) => {
                        return <SwiperSlide key={`swiper_chart_${index}`}>{chart}</SwiperSlide>
                    })}
                </Swiper>
                <div className="topChartSwiperPagination" style={{ textAlign: "center" }} />
            </div >
        )
    }

    const PlayStatusPieChart = () => {
        RestBase.GET(
            Config.DaAlpsRestServer + `assets/viewing/status/${selectAsset.asset_id}`
        ).then((data) => {
            const labels = ["視聴済の学生", "視聴中の学生", "未視聴の学生"];
            const graphData = data.total == 0 ? [data.viewed, data.viewing, data.not_viewed].map(d => parseInt(d)) : [data.viewed, data.viewing, data.not_viewed].map(d => parseInt(d / data.total * 100));
            const options = { ...viewStatusOptions }
            const playStatusData = {
                labels: labels,
                datasets: [{
                    data: graphData,
                    backgroundColor: [
                        'rgba(38, 119, 158, 1)',
                        'rgba(38, 119, 158, 0.5)',
                        'rgb(222, 222, 222)'
                    ],
                    offset: 0,
                    datalabels: {
                        font: {
                            size: "18",
                        },
                        color: "rgba(0,0,0,1)",
                        padding: {
                            bottom: "10"
                        },
                        offset: 0,
                        formatter: function (value) {
                            return value + ' %';
                        }
                    }
                }]
            }
            setPlayStatusPieChart(
                <div className="chartPlayStatus">
                    <Pie data={playStatusData} options={options} />
                </div>
            )
        });
    }

    const SearchWordRanking = () => {
        RestBase.GET(
            Config.DaAlpsRestServer + `assets/tag/press/${selectAsset.asset_id}/6`
        ).then(tagDatas => {
            const rankData = tagDatas.map(data => {
                return {
                    contents: [data.press_tag]
                }
            })
            setSearchWordRanking(
                <Ranking datas={rankData} titleText="タグランキング" titleAreaClass="areaLabelRankingTitleWatchInfo" />
            )
        })
    }

    return (
        <div className="compWatchInfo" >
            <div className="areaChartTop">
                {topChartSwiper}
            </div>
            <div className="areaChartBottom">
                {props.admin && !Utils.isEmpty(selectAsset) &&
                    <span className="areaChartBottomLeft">
                        {playStatusPieChart}
                    </span>
                }
                {!Utils.isEmpty(selectAsset) &&
                    <span className="areaChartBottomRight">
                        {searchWordRanking}
                    </span>
                }
            </div>
        </div>
    )
}
