import { useContext, useEffect, useState } from "react";
import "./compViewLimitation.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as Home from "../pages/home"
import * as AssetUtils from "../utils/AssetUtils"
import CompAssetTag from "../components/compAssetTag"

export default function () {
	const headers = ["ID", "氏名", "視聴可能"];
	const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
	const [userDatas, setUserDatas] = useState([]);
	const [checkedItems, setCheckedItems] = useState([]);

	const GetUserViewableData = () => {
		RestBase.GET(
			Config.DaAlpsRestServer + `assets/viewable/${selectAsset.asset_id}`
		).then((datas) => {
			const checked = datas.map((d) => d.is_enabled);
			setCheckedItems(checked);
			setUserDatas(datas);
		})
	}

	useEffect(() => {
		if (Utils.isEmpty(selectAsset)) return;
		GetUserViewableData();
	}, [selectAsset])

	const handleClick_Check = targetIndex => {
		setCheckedItems(checkedItems.map((item, index) => index == targetIndex ? !item : item))
	}

	const handleClick_CancelAll = () => {
		setCheckedItems(checkedItems.map(() => false));
	}

	const handleClick_SelectAll = () => {
		setCheckedItems(checkedItems.map(() => true));
	}

	const handleClick_Update = () => {
		if (Utils.isEmpty(selectAsset)) return;
		let enabledUserIds = [];
		let disabledUserIds = [];
		userDatas.map((user, index) => {
			checkedItems[index] ? enabledUserIds.push(user.user_id) : disabledUserIds.push(user.user_id);
		})
		RestBase.POST(
			Config.DaAlpsRestServer + "assets/viewable",
			{
				asset_id: selectAsset.asset_id,
				enabled_user_ids: enabledUserIds,
				disabled_user_ids: disabledUserIds
			}
		).then(() => {
			RestBase.GET(
				Config.DaAlpsRestServer + `assets/viewable/${selectAsset.asset_id}`
			).then((datas) => {
				setUserDatas(datas)
				console.log(datas);
			})
		})
	}
	return (
		<div className="compViewLimitation">
			<div className="areaViewLimitation">
				<div className="areaTableTop">
					<div className="areaLabelTitle"><label className="labelTitle">視聴可能ユーザー登録</label></div>
					<div className="areaButtonTableTop">
						<button className="buttonTableTop backgroundGrayGradiation buttonPadding" onClick={() => { handleClick_SelectAll() }}>全選択</button>
						<button className="buttonTableTop backgroundGrayGradiation buttonPadding" onClick={() => { handleClick_CancelAll() }}>全解除</button>
					</div>
				</div>
				<div className="areaTableStudents" style={{ margin: "0 auto" }}>
					<table className="tableStudents" style={{ margin: "0 auto", width: "70%" }}>
						<thead>
							<tr className="tableStudentsHeader">
								<th width="100px">{headers[0]}</th>
								<th width="130px">{headers[1]}</th>
								<th width="100px">{headers[2]}</th>
							</tr>
						</thead>
						<tbody>
							{userDatas.map((user, index) => {
								return (
									<tr className="tableStudentsBody" key={index}>
										<td>{user.gakuseki_no}</td>
										<td>{user.name}</td>
										<td><input
											type="checkbox"
											id={`check_${index}`}
											index={index}
											checked={checkedItems[index]}
											onClick={() => { handleClick_Check(index) }}
											readOnly
										/>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
				<div className="areaTableBottom">
					<button className="buttonTableTop backgroundGrayGradiation buttonPadding" onClick={() => { handleClick_Update() }}>更新</button>
				</div>
			</div>
		</div>
	)
}