import React, { useContext, useEffect, useState, useMemo } from "react";
import "./compStatistic.css"

import * as Defines from "../common/defines"
import CompStatisticWhole from "./compStatisticWhole"
import CompStatisticUser from "./compStatisticUser"
import CompStatisticMaintenance from "./compStatisticMaintenance"
import * as UserInfo from "../common/userInfo"

export default function () {
    const selectTabItems = [
        Defines.SELECT_TAB.STATISTIC.WHOLE,
        Defines.SELECT_TAB.STATISTIC.USER,
        Defines.SELECT_TAB.STATISTIC.MAINTENANCE,
    ];

    const userInfo = UserInfo.getUsetInfo();

    var selectedTabIndex = (userInfo.permission_id === Defines.USER_PERMISSION_ID.STUDENT) ? 1 : 0;
    const [selectedTabItemsValue, setSelectedTabItemsValue] = useState(selectTabItems[selectedTabIndex].id);
    const handleChange_SelectedTabItems = (e) => {
        changeSelectedTabItem(e.target.value);
    };
    const changeSelectedTabItem = ((tab_id) => {
        setSelectedTabItemsValue(tab_id);
        selectedTabIndex = selectTabItems.findIndex((item) => item.id == tab_id);
    })

    var prevKeyupTimestamp = 0;
    function handleKeyup(e) {
        { // 重複発火に対応（かっこ悪い）
            const limitDurationKeyUpMsec = 10;
            if ((prevKeyupTimestamp + limitDurationKeyUpMsec) > e.timeStamp) return;
            prevKeyupTimestamp = e.timeStamp
        }

        if (e.target.tagName != 'BODY') return;

        if (e.key == "ArrowLeft") {
            var nextTabIndex = selectedTabIndex - 1;
            if (nextTabIndex < 0) nextTabIndex = selectTabItems.length - 1;
            if (nextTabIndex >= selectTabItems.length) nextTabIndex = 0;

            if (0 <= nextTabIndex && nextTabIndex < selectTabItems.length) {
                changeSelectedTabItem(selectTabItems[nextTabIndex].id);
            }
        } else if (e.key == "ArrowUp") {
            // nop
        } else if (e.key == "ArrowRight") {
            var nextTabIndex = selectedTabIndex + 1;
            if (nextTabIndex < 0) nextTabIndex = selectTabItems.length - 1;
            if (nextTabIndex >= selectTabItems.length) nextTabIndex = 0;

            if (0 <= nextTabIndex && nextTabIndex < selectTabItems.length) {
                changeSelectedTabItem(selectTabItems[nextTabIndex].id);
            }
        } else if (e.key == "ArrowDown") {
            // nop
        }
    }
    useEffect(() => {
        if (Defines.ENABLE_HANDLE_KEYUP_TAB_MOVE == true) {
            document.removeEventListener('keyup', handleKeyup);
            document.addEventListener('keyup', handleKeyup);
        } else {
            document.removeEventListener('keyup', handleKeyup);
        }
    }, []);

    const styleShow = { display: "contents" }
    const styleHidden = { display: "none" }

    return (
        <div className="compStatistic widthFull fromTop">
            <div className="areaTab">
                {selectTabItems.map((item) => {
                    if (userInfo.permission_id === Defines.USER_PERMISSION_ID.STUDENT && item.id != Defines.SELECT_TAB.STATISTIC.USER.id) return;
                    if (userInfo.permission_id === Defines.USER_PERMISSION_ID.TEACHER && item.id == Defines.SELECT_TAB.STATISTIC.MAINTENANCE.id) return;

                    return (
                        <span key={item.id}>
                            <input className="radioSelectTab" type="radio" name="selectedTab" value={item.id} id={item.id} onChange={(e) => handleChange_SelectedTabItems(e)} checked={item.id === selectedTabItemsValue} />
                            <label className="labelTab" htmlFor={item.id}>{item.dispName}</label>
                        </span>
                    )
                })}
            </div>

            <div style={(selectedTabItemsValue === Defines.SELECT_TAB.STATISTIC.WHOLE.id) ? styleShow : styleHidden}>
                <span className="areaStatisticWhole">
                    <CompStatisticWhole />
                </span>
            </div>

            <div style={(selectedTabItemsValue === Defines.SELECT_TAB.STATISTIC.USER.id) ? styleShow : styleHidden}>
                <span className="areaStatisticUser">
                    <CompStatisticUser />
                </span>
            </div>

            <div style={(selectedTabItemsValue === Defines.SELECT_TAB.STATISTIC.MAINTENANCE.id) ? styleShow : styleHidden}>
                <span className="areaStatisticMaintenance">
                    <CompStatisticMaintenance />
                </span>
            </div>

        </div>
    )
}