import React, { useContext, useState, useEffect } from 'react';
import "./home.css";

import * as HomeLeft from "./homeLeft"
import * as HomeCenter from "./homeCenter"
import * as HomeRight from "./homeRight"
import * as Faculty from "../utils/Faculty"
import * as UserInfo from "../common/userInfo"
import * as Defines from "../common/defines"

export const SelectLeftContext = React.createContext();
export const SelectAssetContext = React.createContext();

export function Home() {
    const userInfo = UserInfo.getUsetInfo();
    const [selectLeft, setSelectLeft] = useState();
    const [selectAsset, setSelectAsset] = useState({});

    useEffect(() => {
        // 初期選択タブ
        setSelectLeft(userInfo.permission_id == Defines.USER_PERMISSION_ID.STUDENT ? Defines.SELECCT_LEFT.MOVIE_WATCH.id : Defines.SELECCT_LEFT.MANAGEMENT.id)
    }, [])

    return (
        <div className="allHome">
            <SelectLeftContext.Provider value={[selectLeft, setSelectLeft]}>
                <SelectAssetContext.Provider value={[selectAsset, setSelectAsset]}>
                    <HomeLeft.HomeLeft />
                    <HomeCenter.HomeCenter />
                    <HomeRight.HomeRight />
                </SelectAssetContext.Provider>
            </SelectLeftContext.Provider>
        </div>
    );
}