export async function POST(uri, req_body) {
    const fetchOptions = {
        method: "POST",
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(req_body),
    };

    const _POST = async () => {
        const res_body = await fetch(uri, fetchOptions)
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return await response.json();
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        return res_body;
    }
    const resp = await _POST();

    return resp;
}

export async function GET(uri) {
    const fetchOptions = {
        method: "GET",
        mode: "cors",
    };

    const _GET = async () => {
        const res_body = await fetch(uri, fetchOptions)
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return await response.json();
            })
            .then(async (data) => {
                return await data;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        return res_body;
    }
    const resp = await _GET();

    return resp;
}

export async function PUT(uri, req_body) {
    const fetchOptions = {
        method: "PUT",
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(req_body),
    };

    const _PUT = async () => {
        const res_body = await fetch(uri, fetchOptions)
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return await response.json();
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        return res_body;
    }
    const resp = await _PUT();

    return resp;
}

export async function DELETE(uri, req_body) {
    const fetchOptions = {
        method: "DELETE",
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(req_body),
    };

    const _DELETE = async () => {
        const res_body = await fetch(uri, fetchOptions)
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return await response.json();
            })
            .then(async (data) => {
                return await data;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        return res_body;
    }
    const resp = await _DELETE();

    return resp;
}