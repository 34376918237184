import { useContext, useMemo, useEffect } from "react";
import "./homeCenter.css";

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as AssetUtils from "../utils/AssetUtils"
import * as UserInfo from "../common/userInfo"
import * as Home from "./home"
import CompVideoViewer from "../components/compVideoViewer"
import CompAssetTag from "../components/compAssetTag"
import CompAssetMeta from "../components/compAssetMeta"
import CompAssetComment from "../components/compAssetComment"
import player_unload from "../resources/player_unload.png"

export function HomeCenter() {
    const [selectLeft, setSelectLeft] = useContext(Home.SelectLeftContext);
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);

    const styleNormal = {
        display: "block",
    };
    const styleAbnormal = {
        display: "none",
    };

    const gridParent = useMemo(() => {
        if (selectLeft == Defines.SELECCT_LEFT.MOVIE_WATCH.id) return "gridParentMovieWatch widthFull";
        else return "gridParentManagement widthFull"
    }, [selectLeft])

    const isCanChangeAssetMeta = (() => {
        const userInfo = UserInfo.getUsetInfo();

        if (Utils.isEmpty(userInfo) == true) return false;

        const _judge1 = (selectLeft == Defines.SELECCT_LEFT.MANAGEMENT.id);
        const _judge2 = (userInfo.permission_id == Defines.USER_PERMISSION_ID.ADMIN);

        return _judge1 && _judge2
    })();

    const isOnlyDeliverd = useMemo(() => {
        switch (selectLeft) {
            case Defines.SELECCT_LEFT.MANAGEMENT.id:
                return false;
                break;
            default:
                return true;
                break;
        }
    }, [selectLeft]);

    return (
        <div className="homeCenter"
            style={(selectLeft === Defines.SELECCT_LEFT.STATISTIC.id || selectLeft === Defines.SELECCT_LEFT.SETTINGS.id)
                ? styleAbnormal : styleNormal}>
            <div className={gridParent}>
                {Utils.isEmpty(selectAsset) && <img src={player_unload} style={{ width: "100%", height: "100%" }} />}
                {!Utils.isEmpty(selectAsset) && <CompVideoViewer src={AssetUtils.GetWowzaWwwPath(selectAsset)} asset={selectAsset} />}
                <CompAssetTag asset={[selectAsset, setSelectAsset]} isDragEnabled={false} isDispAll={false} title="タグ情報" />
                <CompAssetMeta
                    isCanChange={isCanChangeAssetMeta}
                    isOnlyDeliverd={isOnlyDeliverd}
                    isUploadEnabled={selectLeft === Defines.SELECCT_LEFT.MANAGEMENT.id}
                />
                <CompAssetComment isDeleteEnabled={selectLeft === Defines.SELECCT_LEFT.MANAGEMENT.id} />
            </div>
        </div>
    )
}