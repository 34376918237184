import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import "./homeLeft.css";

import * as Defines from "../common/defines"
import * as Home from "./home"
import * as UserInfo from "../common/userInfo"
import * as Utils from "../utils/Utils"

export function HomeLeft() {
    const [selectLeft, setSelectLeft] = useContext(Home.SelectLeftContext);

    const naviagate = useNavigate();
    const styleSelected = {
        color: "#000000",
        fontWeight: "bold",
    };
    const styleNonSelected = {
        color: "#AAAAAA",
        fontWeight: "normal",
    };

    const selectItems = [
        Defines.SELECCT_LEFT.MANAGEMENT,
        Defines.SELECCT_LEFT.MOVIE_WATCH,
        Defines.SELECCT_LEFT.STATISTIC,
        Defines.SELECCT_LEFT.SETTINGS,
    ];

    const handleSelectItemClick = (e) => {
        const id = e;
        // const id = e.target.value;

        const elm = Utils.GetVideo();
        if (elm) {
            if (!(id == Defines.SELECCT_LEFT.MANAGEMENT.id || id == Defines.SELECCT_LEFT.MOVIE_WATCH.id)) {
                elm.pause();
            } else {
                elm.play();
            }
        }

        setSelectLeft(id);
    };

    const handleClick_SignOut = (e) => {
        e.preventDefault();

        UserInfo.setUsetInfo(null);

        naviagate("/");
    }

    const userInfo = UserInfo.getUsetInfo();
    let user_id = "";
    let fomsrec_user_id = "";
    let permission_id = "";
    if (userInfo != null) {
        user_id = userInfo.user_id;
        fomsrec_user_id = userInfo.fomsrec_user_id;
        permission_id = userInfo.permission_id
    }

    return (
        <div className="homeLeft homeLeftGridParent widthFull">
            <img className="homeIcon" src="../images/title.png" />

            <div className="allSelectItems">
                {selectItems.map((item) => {
                    if (item.isDispPermissions.includes(permission_id) != true) return;

                    return (
                        <span className="selectItem"
                            key={item.id} id={item.id}
                            onClick={(e) => handleSelectItemClick(item.id)}>

                            <input className="radioSelectLeftItem" type="radio" name="selectLeftItem" checked={item.id === selectLeft} readOnly/>
                            <img src={item.img} />
                            <label>{item.dispName}</label>
                        </span>
                    )
                })}
            </div>

            <div className="userInfo">
                <div className="userId">
                    <label className="title">ユーザID : </label>
                    <div className="userIdItems">
                        <label>{fomsrec_user_id}</label>
                    </div>
                </div>
                <div className="permissionId">
                    <label className="title">分類 : </label>
                    <div className="permissionIdItems">
                        <label id="labelAdmin" style={permission_id == Defines.USER_PERMISSION_ID.ADMIN ? styleSelected : styleNonSelected}>管理者</label>

                        <label id="labelTeacher" style={permission_id == Defines.USER_PERMISSION_ID.TEACHER ? styleSelected : styleNonSelected}>教員</label>

                        <label id="labelStudent" style={permission_id == Defines.USER_PERMISSION_ID.STUDENT ? styleSelected : styleNonSelected}>学生</label>
                    </div>
                </div>
                <div className="toSignin">
                    <button className="buttonSignout backgroundGrayGradiation" onClick={(e) => handleClick_SignOut(e)}>サインアウト</button>
                </div>
            </div>
        </div>
    )
}