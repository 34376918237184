import Encoding from "encoding-japanese"

import * as Defines from "../common/defines"

export function NotImplement(mes) {
  alert(mes);
}

export function ConvStrDateTime2Date(strDateTime, format, is12hours) {
    const date = new Date(strDateTime);

    var _weekday = ["日", "月", "火", "水", "木", "金", "土"];
    if (!format) {
        format = 'YYYY/MM/DD(WW) hh:mm:ss'
    }
    var year = date.getFullYear();
    var month = (date.getMonth() + 1);
    var day = date.getDate();
    var weekday = _weekday[date.getDay()];
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var secounds = date.getSeconds();

    var ampm = hours < 12 ? 'AM' : 'PM';
    if (is12hours) {
        hours = hours % 12;
        hours = (hours !== 0) ? hours : 12; // 0時は12時と表示する
    }

    var replaceStrArray =
        {
            'YYYY': year,
            'Y': year,
            'MM': ('0' + (month)).slice(-2),
            'M': month,
            'DD': ('0' + (day)).slice(-2),
            'D': day,
            'WW': weekday,
            'hh': ('0' + hours).slice(-2),
            'h': hours,
            'mm': ('0' + minutes).slice(-2),
            'm': minutes,
            'ss': ('0' + secounds).slice(-2),
            's': secounds,
            'AP': ampm,
        };

    var replaceStr = '(' + Object.keys(replaceStrArray).join('|') + ')';
    var regex = new RegExp(replaceStr, 'g');

    const ret = format.replace(regex, function (str) {
        return replaceStrArray[str];
    });

    return ret;
}

export function zeroPadding(num, len){
	return ( Array(len).join('0') + num ).slice( -len );
}

export const GetNowDateTime = () => {
  const now = new Date();

  return zeroPadding(now.getFullYear(), 4)
     + "/" + zeroPadding((now.getMonth() + 1), 2)
     + "/" + zeroPadding(now.getDate(), 2)
     + " " + zeroPadding(now.getHours(), 2)
     + ":" + zeroPadding(now.getMinutes(), 2)
     + ":" + zeroPadding(now.getSeconds(), 2);
}

export const GetNowDateTime_NoSep = () => {
  const now = new Date();

  return zeroPadding(now.getFullYear(), 4)
     + "" + zeroPadding((now.getMonth() + 1), 2)
     + "" + zeroPadding(now.getDate(), 2)
     + "_" + zeroPadding(now.getHours(), 2)
     + "" + zeroPadding(now.getMinutes(), 2)
     + "" + zeroPadding(now.getSeconds(), 2);
}

export const GetMetaDate = (date) => {
  return zeroPadding(date.getFullYear(), 4)
    + "" + zeroPadding((date.getMonth() + 1), 2)
    + "" +zeroPadding(date.getDate(), 2)
}

export const ConvHHMMSSFromSec = (sec) => {
  let hour = Math.floor(sec / 3600);
  let min = Math.floor(sec % 3600 / 60);
  let rem = sec % 60;

  return [hour, min, rem];
}

export const fileSizeUnit = (size) => {
    if(size === undefined) return ""
    
    // 1 KB = 1024 Byte
    const kb = 1024
    const mb = Math.pow(kb, 2)
    const gb = Math.pow(kb, 3)
    const tb = Math.pow(kb, 4)
    const pb = Math.pow(kb, 5)
    const round = (size, unit) => {
        return Math.round(size / unit * 100.0) / 100.0
    }

    if (size >= pb) {
        return round(size, pb) + 'PB';
    } else if (size >= tb) {
        return round(size, tb) + 'TB';
    } else if (size >= gb) {
        return round(size, gb) + 'GB';
    } else if (size >= mb) {
        return round(size, mb) + 'MB';
    } else if (size >= kb) {
        return round(size, kb) + 'KB';
    }
    return size + 'B';
}

export function isEmpty(_var) {
    if ( _var == null ) {
        // typeof null -> object : for hack a bug of ECMAScript
        return true;
      }
      switch( typeof _var ) {
        case 'object':
          if ( Array.isArray( _var ) ) {
            // When object is array:
            return ( _var.length === 0 );
          } else {
            // When object is not array:
            if ( Object.keys( _var ).length > 0 || Object.getOwnPropertySymbols(_var).length > 0 ) {
              return false;
            } else
            if ( _var.valueOf().length !== undefined ) {
              return ( _var.valueOf().length === 0 );
            } else
            if ( typeof _var.valueOf() !== 'object' ) {
              return isEmpty( _var.valueOf() );
            } else {
              return true;
            }
          }
        case 'string':
          return ( _var === '' );
        case 'number':
          return ( _var === 0 );
        case 'boolean':
          return ! _var;
        case 'undefined':
        case 'null':
          return true;
        case 'symbol': // Since ECMAScript6
        case 'function':
        default:
          return false;
    }
}

export function GetOnlyFileName(path) {
  return path.split('/').pop().split('\\').pop()
}

export function CopyToClipboard(str) {
  navigator.clipboard.writeText(str);
}

export function ConvCsvFromArrayInArray(array_in_array) {
  let all_text = "";

  array_in_array.forEach(outer_array => {
    let str = outer_array.join();
    all_text += str + Defines.NewLine;
  });

  return all_text;
}

export function DownloadText(file, body) {
  const str = Encoding.stringToCode(body);
  const convert = Encoding.convert(str, 'sjis', 'unicode');
  const u8a = new Uint8Array(convert);
  
  let blob = new Blob([u8a], {type: "text/csv"});
  let uri = URL.createObjectURL(blob);
  
  let link = document.createElement("a");
  link.download = file;
  link.href = uri;
  link.click();

  URL.revokeObjectURL(uri);
}

export function isEqualObject(obj1, obj2) {
  if(obj1 === obj2){
    // 同一インスタンスならtrueを返す
    return true;
  }

  // 比較対象双方のキー配列を取得する（順番保証のためソートをかける）
  const obj1Keys = Object.keys(obj1).sort();
  const obj2Keys = Object.keys(obj2).sort();

  // 比較対象同士のキー配列を比較する
  if(obj1Keys.toString() !== obj2Keys.toString()){
      // キーが違う場合はfalse
      return false;
  }

  // 値をすべて調べる。
  const wrongIndex = obj1Keys.findIndex(function(value){
      // 注意！これは等価演算子で正常に比較できるもののみを対象としています。
      // つまり、ネストされたObjectやArrayなどには対応していないことに注意してください。
      return obj1[value] !== obj2[value];
  });

  // 合致しないvalueがなければ、trueを返す。
  return wrongIndex === -1;
}

// エレメントに指定のclass属性を追加する
export function AddAttributeClass(elm, attribute) {
  const prevAttr = elm.getAttribute("class");
  if (prevAttr) {
    if (prevAttr.indexOf(attribute) < 0) {
      elm.setAttribute("class", (prevAttr + " " + attribute).trim());
    }
  } else {
    elm.setAttribute("class", attribute);
  }
}

// エレメントから指定のclass属性を削除する
export function DeleteAttributeClass(elm, attribute) {
  const prevAttr = elm.getAttribute("class");
  if (prevAttr && prevAttr.indexOf(attribute) >= 0) {
    elm.setAttribute("class", prevAttr.replace(attribute, "").trim());
  }
}

export function GetVideo() {
  const elm = document.getElementById(Defines.idCompVideoViewer);
  if (elm) {
    return elm.player;
  }
  return null;
}

export function GetVideoCurrentTime() {
  const elm = GetVideo();
  return elm ? elm.currentTime() : -1;
}

export function SetVideoCurrentTime(sec) {
  const elm = GetVideo();
  if (elm) {
    elm.currentTime(sec);
  }
}

export function GetVideoPaused() {
  const elm = GetVideo();
  return elm ? elm.paused() : true;
}

export function GetVideoEnded() {
  const elm = GetVideo();
  return elm ? elm.ended() : false;
}
