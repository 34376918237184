const CryptoJS  = require('crypto-js');
const PASS_PHRASE = "DaAlps";

export function encrypt(text) {
	return CryptoJS.AES.encrypt(text, PASS_PHRASE).toString()
}

export function decrypt(ciphertext) {
	var bytes  = CryptoJS.AES.decrypt(ciphertext, PASS_PHRASE);
	return bytes.toString(CryptoJS.enc.Utf8);
}