import React, { useContext, useEffect, useState, useMemo } from "react";
import "./compFavoriteAsset.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as AssetUtils from "../utils/AssetUtils"
import * as RestBase from "../utils/RestBase"
import Config from "../config"
import CompFileListItem from "./compFileListItem";

export default function (props) {
    const [favoriteAssetIds, setFavoriteAssetIds] = (Utils.isEmpty(props.stateFavoriteAssetIds) == true) ? [[], null] : props.stateFavoriteAssetIds;
    const [historyAssetIds, setHistoryAssetIds] = (Utils.isEmpty(props.stateHistoryAssetIds) == true) ? [[], null] : props.stateHistoryAssetIds;

    const [resultsValue, setResultsValue] = useState([]);
    useEffect(() => {
        AssetUtils.AsyncGetAssetsFromAssetIds(favoriteAssetIds)
            .then((data) => {
                // No sorted
                var sorted = data;
                // var sorted = [];
                // favoriteAssetIds.map((item) => {
                //     const find = data.filter((_item) => _item.asset_id == item);
                //     if(find.length == 1) sorted.push(find[0]);
                // })

                setResultsValue(sorted);
            })
    }, [favoriteAssetIds])

    return (
        <div className="compFavoriteAsset">
            {
                resultsValue.map((item) => {
                    return (
                        <CompFileListItem
                            key={item.asset_id}
                            isFromComponent={Defines.SELECT_TAB.MOVIE_WATCH.FAVORITE_LIST.id}
                            asset={item}
                            condFileSearchAndFilter={[null, null]}
                            isCanDelete={false}
                            isCanFavorite={true}
                            stateHistoryAssetIds={[historyAssetIds, setHistoryAssetIds]}
                            stateFavoriteAssetIds={[favoriteAssetIds, setFavoriteAssetIds]}
                        />
                    )
                })
            }
        </div>
    )
}