import Config from "../config"
import * as Defines from "../common/defines"
import * as Utils from "./Utils"
import * as RestBase from '../utils/RestBase.js'

export function GetThumbnailWwwPath(asset, isWordCloud) {
    if (Utils.isEmpty(asset) !== true) {
        const bAnalyzed = (asset.status) ? (asset.status.ai_analyze_speech === Defines.ASSET_STATUS.ASSET_STATUS_DONE) : false;
        if (isWordCloud && bAnalyzed) {
            return Config.DaAlpsWebServer + "/DaAlps_VOD/VOD/" + asset.asset_id + "/wordcloud.png";
        } else {
            return Config.DaAlpsWebServer + "/DaAlps_VOD/VOD/" + asset.asset_id + "/thumbnail.png";
        }
    } else {
        return "";
    }
}

export function GetWowzaWwwPath(asset) {
    if (Utils.isEmpty(asset) !== true) {
        if (Config.UseWowza) {
            let file = asset.asset_file_path.split('/').pop();
            file = "delivery." + file.split(".").pop();
            var sourceURL = "<config><asset_id>/<file_name>/playlist.m3u8";
            sourceURL = sourceURL.replace("<config>", Config.DaAlpsWowzaServer);
            sourceURL = sourceURL.replace("<asset_id>", String(asset.asset_id));
            sourceURL = sourceURL.replace("<file_name>", file);
            return sourceURL;
        } else {
            return Config.DaAlpsWowzaServer + asset.asset_id + "/" + Utils.GetOnlyFileName(asset.asset_file_path);
        }
    } else {
        return "";
    }
}

export function GetFileWwwPath(asset) {
    if (Utils.isEmpty(asset) !== true) {
        return Config.DaAlpsWebServer + "/DaAlps_VOD/VOD/" + asset.asset_id + "/" + Utils.GetOnlyFileName(asset.asset_file_path);
    } else {
        return "";
    }
}

export function GetFrameRate(asset) {
    if (Utils.isEmpty(asset) !== true) {
        const frame_rate = asset.property.movie.frame_rate;
        return frame_rate.numerator / frame_rate.denominator;
    } else {
        return 30;
    }
}

export function GetSecFloatFromTC(asset, tc) {
    if (Utils.isEmpty(asset) !== true) {
        const frame_rate = asset.property.movie.frame_rate;
        return (tc / frame_rate.numerator) * frame_rate.denominator;
    } else {
        return 0.0;
    }
}

export function GetNumFrameFromSecFloat(asset, secFloat) {
    if (Utils.isEmpty(asset) !== true) {
        const frame_rate = asset.property.movie.frame_rate;

        const num_frame = Math.round(((secFloat) * frame_rate.numerator) / frame_rate.denominator);
        return num_frame;
    } else {
        return 0;
    }
}

export function GetHHMMSSFFFromTC(asset, tc) {
    let hour = 0;
    let minute = 0;
    let second = 0;
    let frame = 0;

    if (Utils.isEmpty(asset) !== true) {
        const frame_rate = asset.property.movie.frame_rate;
        const sec_float = (tc / frame_rate.numerator) * frame_rate.denominator;
        const total_sec = Math.floor(sec_float);

        hour = Math.floor(total_sec / 3600);
        minute = Math.floor(total_sec % 3600 / 60);
        second = total_sec % 60;
        frame = Math.round(((sec_float - total_sec) * frame_rate.numerator) / frame_rate.denominator);
    }

    return hour.toString().padStart(2, "0")
        + ":" + minute.toString().padStart(2, "0")
        + ":" + second.toString().padStart(2, "0")
        + ":" + frame.toString().padStart(2, "0")
}

export function GetTcFromHHMMSSFFF(asset, hhmmssfff) {
    if (Utils.isEmpty(asset) == true) return -1;

    const frame_rate = asset.property.movie.frame_rate;

    const splitted = hhmmssfff.split(/:|;/);
    if (splitted.length != 4) return -1;

    const hour = parseInt(splitted[0]);
    const minute = parseInt(splitted[1]);
    const second = parseInt(splitted[2]);
    const frame = parseInt(splitted[3]);
    if (!(hour >= 0 && minute >= 0 && second >= 0 && frame >= 0)) return -1;

    const total_sec = (hour * 60 * 60) + (minute * 60) + second;

    const num_frame = Math.round(((total_sec) * frame_rate.numerator) / frame_rate.denominator) + frame;

    return num_frame;
}

export function AsyncDoDelete(asset) {
    const promise = RestBase.POST(
        Config.DaAlpsRestServer + "assets/delete",
        {
            asset_id: asset.asset_id,
            action: "batch"
        }
    );

    return promise;
}

export function AsyncDoForceDelete(asset) {
    const promise = RestBase.POST(
        Config.DaAlpsRestServer + "assets/delete",
        {
            asset_id: asset.asset_id,
            action: "force"
        }
    );

    return promise;
}

export function AsyncDoRestore(asset) {
    const promise = RestBase.POST(
        Config.DaAlpsRestServer + "assets/delete",
        {
            asset_id: asset.asset_id,
            action: "restore"
        }
    );

    return promise;
}

export function AsyncDoSpeechAnalyze(requestAnalyzeSpeechSetting) {
    const promise = RestBase.POST(
        Config.DaAlpsRestServer + "assets/analyze_speech",
        requestAnalyzeSpeechSetting
    );

    return promise;
}

export function AsyncProgressSpeechAnalyze(asset) {
    const promise = RestBase.GET(
        Config.DaAlpsRestServer + `assets/analyze_speech/${asset.asset_id}`
    );

    return promise;
}

export function AsyncAbortSpeechAnalyze(asset) {
    const promise = RestBase.POST(
        Config.DaAlpsRestServer + "assets/" + asset.asset_id + "/action"
        , {
            action: "abort_analyze_speech",
            runtime_option: "",
            result: false,
            user_id: -1,
        }
    );

    return promise;
}

export function AsyncDoDelivery(asset) {
    const promise = RestBase.POST(
        Config.DaAlpsRestServer + "assets/delivery/" + asset.asset_id
        , {}
    );

    return promise;
}

export function AsyncDoStopDelivery(asset) {
    const promise = RestBase.DELETE(
        Config.DaAlpsRestServer + "assets/delivery/" + asset.asset_id,
        {}
    );

    return promise;
}

export function GetSpeechAnalyzeArray(asset, sep = "") {
    const results = [];

    asset.ai_analyze.map((item) => {
        if (item.ai_type == Defines.ASSET_AI_TYPE.SPEECH) {
            const line_no = item.line_no;
            let result = results.find((_item) => _item.line_no === line_no);

            if (Utils.isEmpty(result) == true) {
                result = {
                    line_no: line_no,
                    datas: [item]
                }
                results.push(result);
            } else {
                result.datas.push(item);
            }
        }
    })

    results.sort((a, b) => a.line_no > b.line_no);
    results.map((item) => {
        item.datas.sort((a, b) => a.frame > b.frame);
    })

    const lines = results.map((item) => {
        return ({
            line_no: item.line_no,
            tc: GetHHMMSSFFFromTC(asset, item.datas[0].frame),
            secFloat: GetSecFloatFromTC(asset, item.datas[0].frame),
            speaker: item.datas[0].speaker,
            text: item.datas.map((_item) => _item.tag).join(sep),
            reliability: ((item.datas.map((_item) => _item.confidence).reduce((acc, cur) => acc + cur)) / item.datas.length) * 100,
            isChange: item.datas.find(data => data.corrected) !== undefined,
            datas: item.datas,
        })
    })

    return lines;
}

export function AsyncGetAssetsFromAssetIds(asset_ids) {
    const promise = RestBase.POST(
        Config.DaAlpsRestServer + "assets/search"
        , {
            asset_ids: asset_ids,
            // text: "",
            // category_ids: [],
            // datetime: [null, null],
            file_format_ids: [Defines.ASSET_FILE_FORMAT_ID.MOVIE],   // Movieのみ
            children_table: true,
            children_table_meta: true,
        }
    ).then((data) => {
        return data.assets;
    });

    return promise;
}

export function GetMetaInfo(asset, meta_id) {
    if (Utils.isEmpty(asset) == true) return null;

    const findMeta = asset.metas.find(item => item.meta_id == meta_id);
    return findMeta;
}

export function AsyncAddViewingHistory(asset, education_user_internal_id) {
    if (asset && asset.asset_id) {
        const promise = RestBase.POST(
            Config.DaAlpsRestServer + "assets/viewing/history"
            , {
                asset_id: asset.asset_id,
                user_id: education_user_internal_id,
            }
        ).then((response) => {

        }).catch((error) => {
            console.log(error);
            alert("視聴履歴を登録できませんでした。");
        });
        return promise;
    }
}

export function AsyncAddViewingPosition(asset, user_id, viewedTimeSec, viewedPositionMin) {
    if (asset && asset.asset_id) {
        const promise = RestBase.POST(
            Config.DaAlpsRestServer + "assets/viewing/position"
            , {
                asset_id: asset.asset_id,
                user_id: user_id,
                viewed_time_sec: viewedTimeSec,
                viewed_position_min: viewedPositionMin,
            }
        ).then((response) => {
        }).catch((error) => {
            console.log(error);
            alert("視聴位置を登録できませんでした。");
        });
        return promise;
    }
}

export function AsyncGetAnalyzeSettings() {
    const promise = RestBase.GET(
        Config.DaAlpsRestServer + "assets/analyze_speech/settings"
    );

    return promise;
}
