import React, { useContext, useEffect, useState, useMemo } from "react";
import "./compHistoryAsset.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as AssetUtils from "../utils/AssetUtils"
import * as UserInfo from "../common/userInfo"
import CompFileListItem from "./compFileListItem";

export default function (props) {
    const [favoriteAssetIds, setFavoriteAssetIds] = (Utils.isEmpty(props.stateFavoriteAssetIds) == true) ? [[], null] : props.stateFavoriteAssetIds;
    const [historyAssetIds, setHistoryAssetIds] = (Utils.isEmpty(props.stateHistoryAssetIds) == true) ? [[], null] : props.stateHistoryAssetIds;

    const [resultsValue, setResultsValue] = useState([]);
    useEffect(() => {
        AssetUtils.AsyncGetAssetsFromAssetIds(historyAssetIds)
            .then((data) => {
                var sorted = [];
                historyAssetIds.map((item) => {
                    const find = data.filter((_item) => _item.asset_id == item);
                    if (find.length == 1) sorted.push(find[0]);
                })

                setResultsValue(sorted);
            })
    }, [historyAssetIds])

    return (
        <div className="compHistoryAsset">
            {
                resultsValue.map((item, index) => {
                    return (
                        <CompFileListItem
                            key={index}
                            isFromComponent={Defines.SELECT_TAB.MOVIE_WATCH.FAVORITE_LIST.id}
                            asset={item}
                            condFileSearchAndFilter={[null, null]}
                            isCanDelete={false}
                            isCanFavorite={UserInfo.getUsetInfo().user_id == 0 ? false : true}
                            stateHistoryAssetIds={[historyAssetIds, setHistoryAssetIds]}
                            stateFavoriteAssetIds={[favoriteAssetIds, setFavoriteAssetIds]}
                        />
                    )
                })
            }
        </div>
    )
}