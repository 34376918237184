import { useContext, useState, useEffect } from "react";
import "./buttonSpeechAnalyze.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as AssetUtils from "../utils/AssetUtils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import React from "react"

export default function (props) {
    const asset = props[0];
    const setDataAsset = props[1];
    const analyzeSettings = props[2];

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (Utils.isEmpty(asset)) return;
        GetProgress(asset)
            .then((prog) => {
                if (prog < 100 && 1 <= prog) {
                    intervalProgressTimerId = setInterval(() => {
                        GetProgress(asset);
                    }, 1000);
                }
            });
    }, [])

    const resetAsset = ((_asset) => {
        RestBase.GET(
            Config.DaAlpsRestServer + "assets/" + _asset.asset_id
        )
            .then((data) => {
                setDataAsset(data.asset);
            });
    })

    const GetProgress = (async (_asset) => {
        return AssetUtils.AsyncProgressSpeechAnalyze(_asset)
            .then((data) => {
                const progress = data;
                setProgress(progress);
                if (progress >= 100) {
                    clearInterval(intervalProgressTimerId);
                    resetAsset(_asset);
                }
                return progress
            });
    })

    let intervalProgressTimerId;

    const [diarizationSpeakerCount, setDiarizationSpeakerCount] = useState(analyzeSettings.diarization_speaker_count);
    const [listDiarizationSpeakerCount, setListDiarizationSpeakerCount] = useState([1,2,3,4,5,6,7,8,9,10]);
    const [languageCode, setLanguageCode] = useState(analyzeSettings.language_code);
    const [dictionaryName, setDictionaryName]  = useState(analyzeSettings.dictionary_name);

    const handleStartSpeechAnalyzeClick = (item) => {
        const requestAnalyzeSpeechSetting = {
            asset_id: item.asset_id,
            diarization_speaker_count: diarizationSpeakerCount,
            language_code: languageCode,
            dictionary_name: dictionaryName
        };
        AssetUtils.AsyncDoSpeechAnalyze(requestAnalyzeSpeechSetting)
            .then((data) => {
                resetAsset(item);
            });

        intervalProgressTimerId = setInterval(() => {
            GetProgress(item);
        }, 1000);
    }

    const handleAbortSpeechAnalyzeClick = (item) => {
        AssetUtils.AsyncAbortSpeechAnalyze(item)
            .then((data) => {
                clearInterval(intervalProgressTimerId);
                resetAsset(item);
            });
    }

    const returnTag = () => {
        if (Utils.isEmpty(asset) == true) return <div />

        if (asset.status.ai_analyze_speech >= 1) {
            return (
                <button className="buttonSpeechAnalyze buttonColorProcessing" onClick={(e) => handleAbortSpeechAnalyzeClick(asset)}>音声解析 {progress}%</button>
            )
        } else if (asset.status.ai_analyze_speech == Defines.ASSET_STATUS.ASSET_STATUS_DONE) {
            return (
                <button className="buttonSpeechAnalyze buttonColorProcessed">音声解析 完了</button>
            )
        } else {
            if (!analyzeSettings.cinnamon_enabled) {
                return (
                    <button className="buttonSpeechAnalyze buttonColorNoProcessed"
                        onClick={(e) => handleStartSpeechAnalyzeClick(asset)}>音声解析 開始</button>
                )
            } else {
                return (
                    <span>
                        <button className="buttonSpeechAnalyze buttonColorNoProcessed"
                            onClick={(e) => handleStartSpeechAnalyzeClick(asset)}>音声解析 開始</button>
                        話者数
                        <select onChange={(e) => setDiarizationSpeakerCount(listDiarizationSpeakerCount[e.target.selectedIndex])}
                            value={diarizationSpeakerCount}>
                            {
                                listDiarizationSpeakerCount.map((item, index) => { return (<option key={index} value={item}>{item}</option>) })
                            }
                        </select>
                        &nbsp;言語
                        <select onChange={(e) => setLanguageCode(analyzeSettings.array_language_code[e.target.selectedIndex])}
                            value={languageCode}>
                            {
                                analyzeSettings.array_language_code.map((item, index) => { return (<option key={index} value={item}>{item}</option>) })
                            }
                        </select>
                        &nbsp;辞書
                        <select onChange={(e) => setDictionaryName(analyzeSettings.array_dictionary_name[e.target.selectedIndex])}
                            value={dictionaryName}>
                            {
                                analyzeSettings.array_dictionary_name.map((item, index) => { return (<option key={index} value={item}>{item}</option>) })
                            }
                        </select>
                    </span>
                )
            }
        }
    }

    return returnTag()
}
