import React, { useEffect, useState } from "react";
import "./compSettingsAutoTag.css"

import * as Defines from "../common/defines";
import * as UserInfo from "../common/userInfo"
import * as Teacher from "../utils/Teacher"
import * as AutoRegistTag from "../utils/AutoRegistTag"

export default function (props) {

    const MAX_TAG_NUM = 50;
    const INPUT_STYLE_VALID = "inputMetaValue widthFull";
    const INPUT_STYLE_INVALID = "inputMetaValueInvalid widthFull";
    const TAG_LENGTH_OVER_TEXT = `タグが長すぎます。最大${Defines.TAG_TEXT_MAX_LENGTH}文字です。`;

    const userInfo = UserInfo.getUsetInfo();
    const [optionsTeacher, setOptionsTeacher] = useState([]);
    const [selectedTeacherValue, setSelectedTeacherValue] = useState(0);

    const [autoRegistTags, setAutoRegistTags] = useState([]);
    const [autoRegistTagsInputStyles, setAutoRegistTagsInputStyles] = useState(() => {
        let styles = Array(MAX_TAG_NUM);
        styles.fill(INPUT_STYLE_VALID);
        return styles;
    })
    const [autoRegistTagsErrorTexts, setAutoRegistTagsErrorTexts] = useState([])
    useEffect(() => {
        let changedTexts = Array.from(autoRegistTagsErrorTexts);
        const TagLengthErrorTextIndex = autoRegistTagsErrorTexts.find(text => text === TAG_LENGTH_OVER_TEXT);
        if (autoRegistTagsInputStyles.find((style) => style === INPUT_STYLE_INVALID) !== undefined) {
            if (TagLengthErrorTextIndex === undefined) {
                changedTexts.push(TAG_LENGTH_OVER_TEXT);
            }
        } else {
            if (TagLengthErrorTextIndex !== undefined) {
                changedTexts.splice(TagLengthErrorTextIndex, 1);
            }
        }
        setAutoRegistTagsErrorTexts(changedTexts);
    }, [autoRegistTagsInputStyles])

    const GetTags = (user_id) => {
        (async () => {
            let tags = await AutoRegistTag.GetTags(user_id);
            // Add blank input fields
            let lineNo = (tags.length > 0 ? tags[tags.length - 1].line_no + 1 : 0);
            for (let i = tags.length; i < MAX_TAG_NUM; i++) {
                tags.push({ user_id: user_id, tag: "", line_no: lineNo++, display_priority: false });
            }
            setAutoRegistTags(tags);
        })();
    }

    const CheckTagsLength = (tags) => {
        let ret = true;
        let changedStyles = Array.from(autoRegistTagsInputStyles);
        tags.map((tag, index) => {
            if (tag) {
                if (tag.tag.length > Defines.TAG_TEXT_MAX_LENGTH) {
                    changedStyles[index] = INPUT_STYLE_INVALID;
                    ret = false;
                } else {
                    changedStyles[index] = INPUT_STYLE_VALID;
                }
            }
        })
        setAutoRegistTagsInputStyles(changedStyles);
        return ret;
    }

    const handleChange_SelectedTeacherValue = (e) => {
        const user_id = optionsTeacher[e.target.selectedIndex].user_id;
        setSelectedTeacherValue(user_id);
        GetTags(user_id);
    }

    const handleChange_AutoRegistTag = (index) => (e) => {
        const newTags = autoRegistTags.map((item, i) => {
            if (index === i) {
                if (e.target.name === "tag")
                    return { ...item, [e.target.name]: e.target.value };
                else if (e.target.name === "display_priority")
                    return { ...item, [e.target.name]: e.target.checked };
            }
            return item;
        });
        setAutoRegistTags(newTags);
        CheckTagsLength(newTags);
    }

    const handleClick_Regist = (e) => {
        const newTags = [];
        if (!CheckTagsLength(autoRegistTags)) return;
        let lineNo = 0;
        autoRegistTags.forEach(elem => {
            const strTag = elem.tag.trim();
            if (strTag.length > 0) {
                const tag = {
                    user_id: selectedTeacherValue,
                    tag: strTag,
                    line_no: lineNo++,
                    display_priority: elem.display_priority,
                };
                newTags.push(tag);
            }
        });
        if (newTags.length > 0) {
            AutoRegistTag.RegistTags(selectedTeacherValue, newTags);
        }
    }

    const handleClick_Save = (e) => {
        if (!CheckTagsLength(autoRegistTags))
          return;

        let strCsv = "TEXT,表示優先度";
        autoRegistTags.forEach(elem => {
            if (elem.tag.trim().length > 0) {
                strCsv += '\n' + elem.tag.trim() + ',' + elem.display_priority;
            }
        });
        let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        let blob = new Blob([bom, strCsv], { type: "text/csv" });
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "AutoRegistTags.csv";
        link.click();
    }

    const handleClick_Load = (e) => {
        let reader = new FileReader();
        reader.onload = function (event) {
            const aryCsv = event.target.result.split(/\r\n|\n/);
            let tags = [];
            aryCsv.forEach(rec => {
                const values = rec.split(/,/);
                if (values.length === 2 && values[0] !== "TEXT") {
                    let tag = values[0];
                    let priority = (values[1].trim().toLowerCase() === "true" ? true : false);
                    tags.push({ user_id: selectedTeacherValue, tag: tag, display_priority: priority });
                }
            });
            for (let i = tags.length; i < MAX_TAG_NUM; i++) {
                tags.push({ user_id: selectedTeacherValue, tag: "", display_priority: false });
            }
            setAutoRegistTags(tags);
        }
        reader.onerror = () => console.log(reader.error);
        reader.readAsText(e.target.files[0]);
        e.target.value = "";  // Clear the value so that onChange occurs even if the same file is selected.
    }

    const handleClick_Clear = (e) => {
        let tags = [];
        for (let i = 0; i < MAX_TAG_NUM; i++) {
            tags.push({ user_id: selectedTeacherValue, tag: "", display_priority: false });
        }
        setAutoRegistTags(tags);
    }

    useEffect(() => {
        (async () => {
            let options = [];
            if (userInfo.permission_id === Defines.USER_PERMISSION_ID.ADMIN)
                options = await Teacher.GetAllTeachers();
            else
                options.push(await Teacher.GetTeacher(userInfo.user_id));
            setOptionsTeacher(options);

            if (options.length > 0) {
                const userId = options[0].user_id;
                setSelectedTeacherValue(userId);
                GetTags(userId);
            } else {
                let tags = [];
                for (let i = 0; i < MAX_TAG_NUM; i++) {
                    tags.push({ user_id: selectedTeacherValue, tag: "", display_priority: false });
                }
                setAutoRegistTags(tags);
            }
        })();
    }, [])

    return (
        <div className="compSettingsAutoRegistTag">
            <div className="areaSelectUser">
                <div>ユーザ選択</div>
                <div>
                    <select onChange={(e) => handleChange_SelectedTeacherValue(e)}
                        value={selectedTeacherValue}>
                        {
                            optionsTeacher.map((item) => {
                                return (
                                    <option value={item.user_id} key={"User_" + item.name}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                    <button className="buttonAutoRegistTag" onClick={(e) => handleClick_Regist(e)}>上書き登録</button>
                </div>
            </div>
            <div className="areaAutoRegistTagsHeader">自動タグ設定(登録したTEXTが音声解析結果にあれば自動タグ付け)</div>
            <div className="areaAutoRegistTags">
                <table id="autoRegistTagTable" className="autoRegistTagTable">
                    <thead>
                        <tr>
                            <th scope="col" width="80%">TEXT</th>
                            <th scope="col">表示優先度</th>
                        </tr>
                    </thead>
                    <tbody>
                        {autoRegistTags.map((item, index) =>
                            <tr key={index}>
                                <td>
                                    <input className={autoRegistTagsInputStyles[index]} type="text" name="tag" key="{index}" id={`autotag_${index}`}
                                        value={item.tag} onChange={handleChange_AutoRegistTag(index)} />
                                </td>
                                <td>
                                    <input className="inputMetaValue" type="checkbox" name="display_priority" key="{index}"
                                        checked={item.display_priority} onChange={handleChange_AutoRegistTag(index)} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <br />
            </div>
            <div width="100%" className="areaAutoTagErrorMsg">
                {autoRegistTagsErrorTexts.map(text => {
                    console.log("errortextmap");
                    return (
                        <div className="AutoTagErrorMsg">
                            {text}
                        </div>
                    )
                })}
            </div>
            <div className="areaAutoRegistTagsFooter">
                <button className="buttonAutoRegistTag" onClick={(e) => handleClick_Save(e)}>ファイル保存</button>
                <div className="divAutoRegistTagLoadCsv">
                    <button>ファイル読込</button>
                    <input className="inputAutoRegistTagLoadCsv" type="file" accept="text/csv" onChange={handleClick_Load} />
                </div>
                <button className="buttonAutoRegistTag" onClick={(e) => handleClick_Clear(e)}>クリア</button>
            </div>
        </div>
    )
}