import React, { useContext, useEffect, useState, useMemo } from "react";
import "./compSettingsOther.css"
import * as OtherSettings from "../utils/OtherSettings"
import * as AssetUtil from "../utils/AssetUtils"

export default function (props) {
    const [analyzeSpeechThreshold, setAnalyzeSpeechThreshold] = useState("90.0");
    const [autoAnalyzeSpeech, setAutoAnalyzeSpeech] = useState(false);
    const [autoDelivery, setAutoDelivery] = useState(false);

    const [analyzeSettings, setAnalyzeSettings] = useState({});
    const [diarizationSpeakerCount, setDiarizationSpeakerCount] = useState(1);
    const [listDiarizationSpeakerCount, setListDiarizationSpeakerCount] = useState([]);
    const [languageCode, setLanguageCode] = useState("");
    const [dictionaryName, setDictionaryName]  = useState("");

    const INPUT_STYLE_VALID = "compSettingsOtherSpeechAnalyzeInput inputMetaValue";
    const INPUT_STYLE_INVALID = "compSettingsOtherSpeechAnalyzeInput inputMetaValueInvalid";
    const [styleAnalyzeSpeechThreshold, setStyleAnalyzeSpeechThreshold] = useState(INPUT_STYLE_VALID);
    const [errMsgAnalyzeSpeechThreshold, setErrMsgAnalyzeSpeechThreshold] = useState("");

    useEffect(() => {
        (async () => {
            const resultThreshold = await OtherSettings.GetSettingAnalyzeSpeechConfidenceThreshold();
            if (resultThreshold)
                setAnalyzeSpeechThreshold(resultThreshold.value);

            const resultSpeech = await OtherSettings.GetSettingAutoAnalyzeSpeech();
            if (resultSpeech)
                setAutoAnalyzeSpeech(resultSpeech.value === "true");
            
            const resultDelivery = await OtherSettings.GetSettingAutoDelivery();
            if (resultDelivery)
                setAutoDelivery(resultDelivery.value === "true");

            const resultSettings = await AssetUtil.AsyncGetAnalyzeSettings();
            setAnalyzeSettings(resultSettings);
            if (resultSettings.cinnamon_enabled) {
                const list = [];
                for (let i = 1; i <= 10; i++) list.push(i);
                setListDiarizationSpeakerCount(list);
                setDiarizationSpeakerCount(resultSettings.diarization_speaker_count);
                setLanguageCode(resultSettings.language_code);
                setDictionaryName(resultSettings.dictionary_name);
            }
        })();
    }, [])

    const checkAnalyzeSpeechThreshold = (value) => {
        if (/^[0-9]*[\.]?[0-9]*$/.test(value)) {
            let threshold = parseFloat(value);
            if (threshold !== NaN && threshold >= 1.0 && threshold <= 100.0) {
                setStyleAnalyzeSpeechThreshold(INPUT_STYLE_VALID);
                setErrMsgAnalyzeSpeechThreshold("");
                return true;
            }
        }
        setStyleAnalyzeSpeechThreshold(INPUT_STYLE_INVALID);
        setErrMsgAnalyzeSpeechThreshold("※1～100の少数で入力してください。");
        return false;
    }

    const handleChange_AnalyzeSpeechThreshold = (value) => {
        setAnalyzeSpeechThreshold(value);
        checkAnalyzeSpeechThreshold(value);
    }

    const handleClick_Regist = (e) => {
        if (!checkAnalyzeSpeechThreshold(analyzeSpeechThreshold))
            return;

        // DB登録
        const arySetting = [
            { name: OtherSettings.NAME_SETTINGS.AUTO_ANALYZE_SPEECH, value: autoAnalyzeSpeech },
            { name: OtherSettings.NAME_SETTINGS.ANALYZE_SPEECH_CONFIDENCE_THRESHOLD, value: analyzeSpeechThreshold },
            { name: OtherSettings.NAME_SETTINGS.AUTO_DELIVERY, value: autoDelivery },
        ];
        if (analyzeSettings.cinnamon_enabled && autoAnalyzeSpeech) {
            arySetting.push({ name: OtherSettings.NAME_SETTINGS.CINNAMON_DEFAULT_SETTING, value:
                '{' +
                `diarization_speaker_count: ${diarizationSpeakerCount}` +
                `,language_code: "${languageCode}"` +
                `,dictionary_name: "${dictionaryName}"` +
                '}'
            });
        }
        OtherSettings.SetSettings(arySetting)
        .then((response) =>  {
            if (response !== null)
                alert("設定しました。");
            else
                alert("設定できませんでした。");
        });
    }

    return (
        <div className="compSettingsOther">
            <div className="compSettingsArea">
                <div className="compSettingsOtherAreaLabelTitle">
                    <div className="compSettingsOtherLabelTitle"> 音声解析 </div>
                </div>
                <div className="compSettingsOtherBox">
                    <div>
                        <div className="compSettingsOtherSpeechAnalyzeAreaBodyL1">
                            <div className="compSettingsOtherBodyText">信頼度 閾値</div>
                            <input type="text" id="speechAnalyzeTextbox" className={styleAnalyzeSpeechThreshold}
                                value={analyzeSpeechThreshold}
                                onChange={(e) => {handleChange_AnalyzeSpeechThreshold(e.target.value)}}
                            />
                            <div className="compSettingsOtherErrorMsg">{errMsgAnalyzeSpeechThreshold}</div>
                        </div>
                        <div className="compSettingsOtherBodyTextPaddingL">
                                信頼度がこの値より小さい場合に音声解析結果の全文表示で強調表示(１～１００) 
                            </div>
                        <div className="compSettingsOtherBodyText">収録後に自動解析</div>
                        <input type="checkbox" id="autoAnalyzeSpeechCheckbox" className="compSettingsOtherCheckbox"
                            checked={autoAnalyzeSpeech} onChange={() => { setAutoAnalyzeSpeech(!autoAnalyzeSpeech) }} />
                        {(autoAnalyzeSpeech && analyzeSettings.cinnamon_enabled) &&
                            <div className="compSettingsCinnamonArea">
                                <div>自動解析時の設定</div>
                                <table className="compSettingsCinnamonTable">
                                    <tbody>
                                    <tr>
                                        <td className="compSettingsCinnamonTitleValue">話者数</td>
                                        <td className="compSettingsCinnamonTitleValue">
                                            <select onChange={(e) => setDiarizationSpeakerCount(listDiarizationSpeakerCount[e.target.selectedIndex])}
                                                value={diarizationSpeakerCount}>
                                                {
                                                    listDiarizationSpeakerCount.map((item, index) => { return (<option key={index} value={item}>{item}</option>) })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="compSettingsCinnamonTitleValue">言語選択</td>
                                        <td className="compSettingsCinnamonTitleValue">
                                            <select onChange={(e) => setLanguageCode(analyzeSettings.array_language_code[e.target.selectedIndex])}
                                                value={languageCode}>
                                                {
                                                    analyzeSettings.array_language_code.map((item, index) => { return (<option key={index} value={item}>{item}</option>) })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="compSettingsCinnamonTitleValue">辞書選択</td>
                                        <td className="compSettingsCinnamonTitleValue">
                                            <select onChange={(e) => setDictionaryName(analyzeSettings.array_dictionary_name[e.target.selectedIndex])}
                                                value={dictionaryName}>
                                                {
                                                    analyzeSettings.array_dictionary_name.map((item, index) => { return (<option key={index} value={item}>{item}</option>) })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="compSettingsArea">
                <div className="compSettingsOtherAreaLabelTitle">
                    <div className="compSettingsOtherLabelTitle"> 配信 </div>
                </div>
                <div className="compSettingsOtherBox">
                    <div>
                        <div className="compSettingsOtherBodyText">収録後に自動配信</div>
                        <input type="checkbox" id="autoDeliveryCheckbox" className="compSettingsOtherCheckbox"
                            checked={autoDelivery} onChange={() => { setAutoDelivery(!autoDelivery) }} />
                    </div>
                </div>
            </div>

            <button className="buttonRegistOtherSettings" onClick={(e) => handleClick_Regist(e)}>設定</button>
        </div>
    )
}