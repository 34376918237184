export const USER_PERMISSION_ID = {
    NONE: 0,
    ADMIN: 1,
    TEACHER: 2,
    STUDENT: 3,
}

export const SELECCT_LEFT = {
    MANAGEMENT: {
        id: "management",
        img: "../images/icon_folder.png",
        dispName: "配信／管理",
        isDispPermissions: [
            USER_PERMISSION_ID.ADMIN,
            USER_PERMISSION_ID.TEACHER,
        ],
    },
    MOVIE_WATCH: {
        id: "movie_watch",
        img: "../images/icon_headphone.png",
        dispName: "動画視聴",
        isDispPermissions: [
            USER_PERMISSION_ID.ADMIN,
            USER_PERMISSION_ID.TEACHER,
            USER_PERMISSION_ID.STUDENT,
        ],
    },
    STATISTIC: {
        id: "statistic",
        img: "../images/icon_graph.png",
        dispName: "統計データ",
        isDispPermissions: [
            USER_PERMISSION_ID.ADMIN,
            USER_PERMISSION_ID.TEACHER,
            USER_PERMISSION_ID.STUDENT,
        ],
    },
    SETTINGS: {
        id: "settings",
        img: "../images/icon_setting.png",
        dispName: "設定",
        isDispPermissions: [
            USER_PERMISSION_ID.ADMIN,
            USER_PERMISSION_ID.TEACHER,
        ],
    },
}

export const SELECT_TAB = {
    MANAGEMENT: {
        FILELSIT: {
            id: "fileList",
            dispName: "ファイルリスト",
        },
        SPEECH_ANALYZE: {
            id: "speechAnalyze",
            dispName: "音声解析",
        },
        TAG_INFO: {
            id: "tagInfo",
            dispName: "タグ情報",
        },
        REFERENCES_SETTING: {
            id: "referencesSetting",
            dispName: "関連動画設定",
        },
        DELETED_MOVIE: {
            id: "deletedMovie",
            dispName: "削除した動画"
        },
        WATCH_INFO: {
            id: "watchInfo",
            dispName: "視聴情報",
        },
        VIEW_LIMITATION: {
            id: "viewLimitation",
            dispName: "視聴制限"
        }
    },
    MOVIE_WATCH: {
        MOVIE_SEARCH: {
            id: "movieSearch",
            dispName: "動画検索",
        },
        FAVORITE_LIST: {
            id: "favoriteList",
            dispName: "お気に入りリスト",
        },
        HISTORY: {
            id: "history",
            dispName: "履歴",
        },
        RELATED_MOVIE: {
            id: "relatedmovie",
            dispName: "関連動画",
        },
        DISP_SPEECH_TO_TEXT: {
            id: "dispSpeechToText",
            dispName: "音声テキスト表示",
        },
        WATCH_INFO: {
            id: "watchInfo",
            dispName: "視聴情報",
        },
    },
    STATISTIC: {
        WHOLE: {
            id: "whole",
            dispName: "全体",
        },
        USER: {
            id: "user",
            dispName: "ユーザ",
        },
        MAINTENANCE: {
            id: "maintenance",
            dispName: "メンテナンス",
        },
    },
    SETTINGS: {
        LIBRARY: {
            id: "library",
            dispName: "ライブラリ",
        },
        AUTO_TAG: {
            id: "autoTag",
            dispName: "自動タグ設定",
        },
        OTHER: {
            id: "other",
            dispName: "その他",
        },
    },
}

export const ASSET_META_ID = {
    LECTURE_NAME: {
        id: 250,
        name: "lecture_name",
        dispName: "講義名",
    },
    YEAR: {
        id: 251,
        name: "year",
        dispName: "年度",
    },
    AFFILIATION: {
        id: 252,
        name: "affiliation",
        dispName: "所属",
    },
    TEACHER: {
        id: 253,
        name: "teacher",
        dispName: "教員名",
    },
    DAY_ATTENDED: {
        id: 254,
        name: "day_attended",
        dispName: "日付",
    },
    NUM_PERIOD: {
        id: 255,
        name: "num_period",
        dispName: "時限",
    },
    DEPARTMENT_ID: {
        id: 256,
        name: "department_id",
        dispName: "所属ID",
    },
    TEACHER_ID: {
        id: 257,
        name: "teacher_id",
        dispName: "講師ID",
    },
    ROOM_NAME: {
        id: 258,
        name: "room_name",
        dispName: "教室名",
    },
}

export const ASSET_TAG_KIND = {
    AI_AUTO: {
        id: 0,
        dispName: "AI (自動)",
    },
    MANUAL: {
        id: 1,
        dispName: "手動",
    },
}

export const idCompVideoViewer = "idCompVideoViewer";

export const NewLine = "\r\n";

export const ASSET_STATUS = {
    ASSET_STATUS_NONE: -1,
    ASSET_STATUS_ERROR: -2,
    ASSET_STATUS_WAIT: -3,
    ASSET_STATUS_STOP: -4,
    ASSET_STATUS_PROCESSING: 1, // WindowsのプロセスID ( >= 1)
    ASSET_STATUS_DONE: 0,
}

export const ASSET_UPDATE_MODE = {
    UPDATE_META: 3,
    UPDATE_TAG: 4,
    UPDATE_REFERENCE: 7,
}

export const ASSET_FILE_FORMAT_ID = {
    MOVIE: 1,
    PICTURE: 2,
    AUDIO: 3,
    TEXT: 4,
    PDF: 5,
    MSOFFICE: 6,
    RESERVE_DELETE: 99,
}
export const DEFAULT_USER_ID = 1;
export const DEFAULT_EUCATION_USER_INTERNAL_ID = 0;
export const DEFAULT_EDUCATION_LOGIN_NAME = "default_user";

export const ASSET_AI_TYPE = {
    SPEECH: 0,
    TELOP: 1,
}

export const NAME_COMPONENT = {
    MANAGEMENT: "Management",
    MOVIE_WATCH: "MovieWatch",
}

export const SYSTEM_INFO_TYPE = {
    GOOGLE_SPEECH_TEXT_UPLOADED_SECOND: {
        id: 0,
    },
    GOOGLE_VISION_AI_UPLOADED_NUMBER: {
        id: 1,
    },
    MASTER_SETTINGS_SELECT: {
        id: 2,
    },
    MASTER_SETTINGS_REGIST: {
        id: 3,
    },
}

export const ENABLE_HANDLE_KEYUP_TAB_MOVE = true;

export const TAG_TEXT_MAX_LENGTH = 20;