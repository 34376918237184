import React from "react";
import "./compPlayAreaTable.css";
import { ConvHHMMSSFromSec } from "../../utils/Utils";

export default function (props) {
	if (!props.data) return (<div />);
	const c1TextClass = props.c1TextClass ? props.c1TextClass : "";
	const makePlayArea = (statusCount, viewedStatusList, parentIndex) => {
		const indexPerStatus = statusCount / 100
		let sum = 0;
		let viewedStatusFractionArray = [];
		let tc = "00:00:00";
		let sec = 0;
		let min = 0;
		let hour = 0;
		if (indexPerStatus <= 1) {
			// 100分以下のアセット
			for (let i = 0; i < 100; i++) {
				// tcの更新
				// sec, min, hourをそれぞれ計算
				sec = Math.floor(Math.min(sum * 60, (statusCount - 1) * 60));
				min = Math.floor(sec / 60);
				hour = Math.floor(min / 60);
				// sec, minは60を超えた分引く
				sec = sec - min * 60;
				min = min - hour * 60;
				// tcに反映, 2桁になるようにする
				tc = `${('00' + hour).slice(-2)}:${('00' + min).slice(-2)}:${('00' + sec).slice(-2)}`
				// 配列に格納
				viewedStatusFractionArray.push({ viewed: viewedStatusList[Math.floor(sum)], tc: tc });
				sum += indexPerStatus;
			}
		} else if (indexPerStatus > 1) {
			// 100分より長いアセット
			for (let i = 0; i < 100; i++) {
				// tcの更新
				// sec, min, hourをそれぞれ計算
				sec = Math.min((sum - Math.floor(sum)) * 60, (statusCount - 1) * 60);
				min = Math.floor(Math.min(sum, statusCount - 1));
				hour = Math.floor(min / 60);
				// minは60を超えた分引く
				min = min - hour * 60;
				// tcに反映、2桁になるようにする
				tc = `${('00' + hour).slice(-2)}:${('00' + min).slice(-2)}:${('00' + sec).slice(-2)}`
				let viewed = true;
				for (let j = indexPerStatus; j >= 0; j--) {
					if (!viewedStatusList[Math.floor(j + sum)]) {
						viewed = false;
					}
				}
				viewedStatusFractionArray.push({ viewed: viewed, tc: tc });
				sum += indexPerStatus;
			}
		}
		return viewedStatusFractionArray.map((v, index) => {
			return <td key={parentIndex + "-" + index} title={v.tc}
				style={{ backgroundColor: (v.viewed ? "rgba(18, 119, 158, 100)" : "#DDDDDD"), border: "0px", height: "8px", width: "0px" }}></td>
		})
	}

	const [searchText, setSearchText] = React.useState("");
	const handleSearch_PressEnter = (e) => {
		if (e.key == 'Enter') {
			setSearchText(e.target.value);
			if (props.setSearchText) {
				props.setSearchText(e.target.value);
			}
		}
	}

	const getViewingTime = (data) => {
		const hhmmss = ConvHHMMSSFromSec(data.total_viewing_time_sec);
		return `${('00' + hhmmss[0]).slice(-2)}:${('00' + hhmmss[1]).slice(-2)}:${('00' + hhmmss[2]).slice(-2)}`
	}

	const getTotalTime = (data, text) => {
		const totalsec = data.reduce((sum, current) => {
			if (current.name.indexOf(text) == -1) return sum;
			return sum + current.total_viewing_time_sec;
		}, 0)
		const hhmmss = ConvHHMMSSFromSec(totalsec);
		return `${('00' + hhmmss[0]).slice(-2)}:${('00' + hhmmss[1]).slice(-2)}:${('00' + hhmmss[2]).slice(-2)}`
	}

	return (
		<div>
			<div className="areaLabelTablePlayArea">
				<label className="labelTablePlayAreaTitle">視聴履歴／再生箇所</label>
			</div>
			{props.showSearchText &&
				<div className="areaTextboxTablePlayArea">
					<input className="textboxTablePlayArea" type="text" onKeyDown={handleSearch_PressEnter}></input>
				</div>
			}
			<table className="tablePlayArea" width="90%">
				<thead>
					<tr className="tablePlayAreaHeader" width="100%">
						<th width="150px">{props.h1text ? props.h1text : "名称"}</th>
						<th width="75px">{props.h2text ? props.h2text : "状況"}</th>
						<th width="154px">{props.h3text ? props.h3text : "再生箇所"}</th>
						<th width="90px">{props.h4text ? props.h4text : "総再生時間"}</th>
					</tr>
				</thead>
				<tbody>
					{props.data.map((data, index) => {
						if (data.name.indexOf(searchText) == -1) return;
						return (
							<tr key={index} className="tablePlayAreaBody">
								<td className={c1TextClass} width="100px" title={data.name}>{data.name}</td>
								<td>{parseInt(data.viewed_ratio) == 0 ? "未視聴" : parseInt(data.viewed_ratio) == 100 ? "視聴済" : "視聴途中"}</td>
								<td>
									<table className="tableViewedStatus">
										<tbody>
											<tr width="100px">{makePlayArea(data.status_count, data.viewed_status, index)}</tr>
										</tbody>
									</table>
								</td>
								<td>{getViewingTime(data)}</td>
							</tr>
						)
					})}
					{props.data.length > 0 && props.dispTotal &&
						<tr key="totalViewLine" className="tablePlayAreaBody">
							<td colSpan={2} style={{ backgroundColor: "gray" }}></td>
							<td style={{ fontWeight: "bold" }}>合計</td>
							<td>{getTotalTime(props.data, searchText)}</td>
						</tr>
					}
				</tbody>
			</table>
		</div>
	)
}