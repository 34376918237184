import { useState } from "react"
import React from 'react';
import { useNavigate } from "react-router-dom"
import "./signin.css";

import * as UserAuthentication from "../utils/UserAuthentication"
import * as UserInfo from "../common/userInfo"
import { _UserAuthentication } from "../utils/UserAuthentication";

export function Signin() {
    const initialValue = { userId: "", password: "" }
    const [formValues, setFormValues] = useState(initialValue);
    const [formErrors, setFormErrors] = useState([]);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validate(formValues)
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const userInfo = await UserAuthentication._UserAuthentication(formValues.userId, formValues.password);

            if (userInfo != null) {
                UserInfo.setUsetInfo(userInfo);
                const d = UserInfo.getUsetInfo();

                navigate("/home/");
            } else {
                setFormErrors(["ユーザIDまたはパスワードが異なります"])
            }
        }

        const _handleSubmit = (e) => {
            e.preventDefault();

            const errors = validate(formValues)
            setFormErrors(errors);

            if (Object.keys(errors).length === 0) {
                UserAuthentication._UserAuthentication(formValues.userId, formValues.password)
                    .then((userInfo) => {
                        if (userInfo != null) {
                            UserInfo.setUsetInfo(userInfo);
                            navigate("/home/");
                        }
                    })
            }
        }
    };

    const validate = (values) => {
        const errors = [];

        if (!values.userId) {
            errors.push("ユーザIDを入力してください");
        }
        if (!values.password) {
            errors.push("パスワードを入力してください");
        }

        return errors;
    };

    return (
        <div className="formContainer">
            <table width="40%">
                <tbody>
                    <tr>
                        <td>
                            <img className="titleIcon" src="../images/title.png" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="uiForm">
                                <div className="formTitle">
                                    DaAlps for Education サインイン
                                </div>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="formField">
                                        <label>ユーザID</label>
                                        <input type="text" placeholder="User ID" name="userId" onInput={(e) => handleChange(e)} />
                                    </div>
                                    <div className="formField">
                                        <label>パスワード</label>
                                        <input type="password" placeholder="Password" name="password" onInput={(e) => handleChange(e)} />
                                    </div>
                                    <div className="areaErrorMsg">
                                        {
                                            formErrors.map((item, index) => {
                                                return (
                                                    <div key={index} className="errorMsg">{item}</div>
                                                )
                                            })
                                        }
                                    </div>
                                    <button className="buttonSignin">サインイン</button>
                                </form>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
