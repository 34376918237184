import { useContext, useEffect, useState, useMemo } from "react";
import "./compVideoViewer.css";

import * as Defines from "../common/defines";
import * as AssetUtils from "../utils/AssetUtils";
import * as Utils from "../utils/Utils"
import * as UserInfo from "../common/userInfo";
import VideoJS from '../components/compVideoJSForVideoViewer';
import videoJsLangJaJson from 'video.js/dist/lang/ja.json';

export default function (props) {
  const selectAsset = props.asset;
  const userInfo = UserInfo.getUsetInfo();
  const [videoSrc, setVideoSrc] = useState("");

  const langJA = {...videoJsLangJaJson};
  langJA['subtitles off'] = '字幕オフ';

  // videojsのイベント処理では、素のJavaScript変数を使う
  let g_isFirstPlay = false;
  let g_viewStartSec = -1;
  let g_viewCurrentSec = -1;
  let g_viewPositionLastMin = -1;

  useMemo(() => {
    setVideoSrc(props.src);
  }, [props.src]);

  useEffect(() => {
    g_isFirstPlay = true;
    g_viewStartSec = -1;
    g_viewCurrentSec = -1;
    g_viewPositionLastMin = -1;

    const player = Utils.GetVideo();
    if (player && videoSrc) {
      player.on("play", handleVideoPlayed);
      player.on("pause", handleVideoPaused);
      player.on("seeking", handleVideoSeeking);
      player.on("timeupdate", handleVideoTimeUpdated);
      player.on("fullscreenchange", handleFullscreenchanged);

      player.src(videoSrc);
      player.play();
    }
  }, [videoSrc]);

  useEffect(() => {
    if (selectAsset) {
      addSubtitle();
    }
  }, [selectAsset]);

  const addSubtitle = () => {
    const player = Utils.GetVideo();
    // 既存を削除
    const tracks = player.textTracks();
    let txtTrackJa = null;
    let bShowed = false;
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      if (track.kind === 'subtitles' && track.language === 'ja') {
        bShowed = (track.mode == 'showing');
        track.mode = 'hidden';
        for (let j = track.cues.length - 1; j >= 0; j--) {
          track.removeCue(track.cues[j]);
        }
        txtTrackJa = track;
        break;
      }
    }
    // 追加
    if (selectAsset && userInfo) {
      const resultSpeechAnalyze = AssetUtils.GetSpeechAnalyzeArray(selectAsset, "");
      if (resultSpeechAnalyze && resultSpeechAnalyze.length > 0) {
        if (txtTrackJa == null) {
          txtTrackJa = player.addTextTrack("subtitles", "字幕 オン", "ja");
          if (!txtTrackJa)
            return;
          txtTrackJa.mode = "hidden";
          player.textTrackSettings.setValues({
            fontPercent: 0.75,
            textOpacity: "1.0",       // 1 or 0.5
            backgroundOpacity: "1.0"  // 1 or 0.5
          });
        }
        resultSpeechAnalyze.map((item) => {
          const secEnd = AssetUtils.GetSecFloatFromTC(selectAsset, item.datas[item.datas.length - 1].frame_end);
          const cue = new window.VTTCue(item.secFloat, secEnd, item.text);
          txtTrackJa.addCue(cue);
          txtTrackJa.cues[txtTrackJa.cues.length - 1].size = 98;
          return null;
        });
      }
      if (bShowed) {
        setTimeout(() => { txtTrackJa.mode = 'showing'; }, 300);
      }
    }
  };

  const handleFullscreenchanged = () => {
    addSubtitle();
  };
  const handleVideoPlayed = () => {
    const curSec = Utils.GetVideoCurrentTime();
    g_viewStartSec = curSec;
    g_viewCurrentSec = curSec;

    if (g_isFirstPlay && selectAsset && userInfo) {
      // 字幕設定
      addSubtitle();
      // 視聴履歴登録
      AssetUtils.AsyncAddViewingHistory(selectAsset, userInfo.user_id);
    }

    g_isFirstPlay = false;
  };
  const handleVideoPaused = () => {
    const startSec = g_viewStartSec;
    const endSec = g_viewCurrentSec;
    g_viewStartSec = -1;
    g_viewCurrentSec = -1;
    if (isNeedUpdateViewingTimeByEnded(startSec, endSec) || isNeedUpdateViewingTime(startSec, endSec)) {
      addViewingPosition(startSec, endSec);
    }
  };
  const handleVideoSeeking = () => {
    const startSec = g_viewStartSec;
    const endSec = g_viewCurrentSec;
    if (Utils.GetVideoPaused()) {
      g_viewStartSec = -1;
      g_viewCurrentSec = -1;
    } else {
      const curSec = Utils.GetVideoCurrentTime();
      g_viewStartSec = curSec;
      g_viewCurrentSec = curSec;
      if (isNeedUpdateViewingTime(startSec, endSec)) {
        addViewingPosition(startSec, endSec);
      }
    }
  };
  const handleVideoTimeUpdated = () => {
    const startSec = g_viewStartSec;
    const endSec = g_viewCurrentSec;
    const curSec = Utils.GetVideoCurrentTime();
    if (Utils.GetVideoPaused()) {
      if (isNeedUpdateViewingTimeByEnded(startSec, endSec)) {
        addViewingPosition(startSec, endSec);
      }
      g_viewStartSec = -1;
      g_viewCurrentSec = -1;
    } else {
      if (endSec <= curSec && (curSec - endSec <= 1)) {
        g_viewCurrentSec = curSec;
        if (isNeedUpdateViewingTime(startSec, curSec)) {
          g_viewStartSec = curSec;
          addViewingPosition(startSec, curSec);
        }
      } else {
        g_viewStartSec = curSec;
        g_viewCurrentSec = curSec;
      }
    }
  };
  function isNeedUpdateViewingTimeByEnded(startSec, endSec) {
    //console.log("Videoend=" + Utils.GetVideoEnded());
    return (Utils.GetVideoEnded() && startSec >= 0 && startSec <= endSec);
  };
  function isNeedUpdateViewingTime(startSec, endSec) {
    // Update if played for more than 3 seconds
    return (startSec >= 0 && endSec >= 0 && (endSec - startSec >= 3));
  };
  function addViewingPosition(startSec, endSec) {
    const viewedTimeSec = Math.trunc(endSec - startSec);
    let viewedPositionMin = Math.trunc(endSec / 60);
    if (g_viewPositionLastMin == viewedPositionMin) {
      viewedPositionMin = -1;
    } else {
      //setViewPositionLastMin(viewedPositionMin);
      g_viewPositionLastMin = viewedPositionMin;
    }
    AssetUtils.AsyncAddViewingPosition(selectAsset, userInfo.user_id, viewedTimeSec, viewedPositionMin);
  };

  return (
    <div className="compVideoViewer">
      <VideoJS
        id={Defines.idCompVideoViewer}
        options={{
          controls: true,
          languages: {'ja': langJA},
          language: 'ja',
          playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2.0],
          preload: 'auto',
          sources: [
            {
              src: videoSrc,
              type: 'video/mp4',
            },
          ],
        }}
        hotkeys={{}}
      />
    </div>
  );
}
