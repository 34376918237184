import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ja from 'date-fns/locale/ja';
import "react-datepicker/dist/react-datepicker.css";

import * as Home from "../pages/home"
import * as Utils from "../utils/Utils"
import * as Teacher from "../utils/Teacher"
import * as Department from "../utils/Department"

import "./compSearchAndFilter.css";

registerLocale('ja', ja)

export default function (props) {
    const [condFileSearchAndFilter, setCondFileSearchAndFilter] = props.condFileSearchAndFilter;

    const labelFilterOnOff = ["詳細条件", "詳細条件"];
    const [isFilterChecked, setIsFilterChecked] = useState(false);
    const handleChange_FilterOnOff = (e) => {
        setIsFilterChecked(e.target.checked);
    };

    const [searchText, setSearchText] = useState("");
    const handleChange_SearchText = (e) => {
        setSearchText(e.target.value);
    };
    const [includeSpeechAnalysis, setIncludeSpeechAnalysis] = useState(false);

    const [allDepartment, setAllDepartment] = useState([]);
    const [selectedDepartmentValue, setSelectedDepartmentValue] = useState();
    const [allTeacher, setAllTeacher] = useState([]);
    useEffect(() => {
        (async () => {
            setAllDepartment(["", ...await Department.GetAllDepartment()]);
            setAllTeacher(["", ...await Teacher.GetAllTeachers()]);
        })();
    }, []);
    useEffect(() => {
        if (Utils.isEmpty(allDepartment) == true) return;
        setSelectedDepartmentValue(allDepartment[0].department_id)
    }, [allDepartment]);
    useEffect(() => {
        if (Utils.isEmpty(allTeacher)) return;
        setSelectedTeacherValue(allTeacher[0].user_id)
    }, [allTeacher])
    const handleChange_SelectedDepartmentValue = (e) => {
        setSelectedDepartmentValue(allDepartment[e.target.selectedIndex].department_id);
    };

    const [selectedTeacherValue, setSelectedTeacherValue] = useState([]);
    const handleChange_SelectedTeacherValue = (e) => {
        setSelectedTeacherValue(allTeacher[e.target.selectedIndex].user_id);
        //console.log(allTeacher[e.target.selectedIndex].user_id);
    };

    const [selectedStartDateValue, setSelectedStartDateValue] = useState(null);
    const handleChange_SelectedStartDateValue = (e) => {
        setSelectedStartDateValue(e);
    };
    const [selectedFinishDateValue, setSelectedFinishDateValue] = useState(null);
    const handleChange_SelectedFinishDateValue = (e) => {
        setSelectedFinishDateValue(e);
    };

    const handleClick_Search = (e) => {
        const _condFileSearchAndFilter = {
            text: "",
            include_speech_analysis: includeSpeechAnalysis,
            department_id: 0,
            teacher_id: 0,
            datetime: []
        };
        _condFileSearchAndFilter.text = searchText;
        if (isFilterChecked == true) {
            _condFileSearchAndFilter.department_id = !Utils.isEmpty(selectedDepartmentValue) ? selectedDepartmentValue : 0;
            //console.log(_condFileSearchAndFilter.department_id);
            _condFileSearchAndFilter.teacher_id = !Utils.isEmpty(selectedTeacherValue) ? selectedTeacherValue : 0;
            _condFileSearchAndFilter.datetime = selectedStartDateValue != null ? [selectedStartDateValue.toLocaleString()] : [""];
            selectedFinishDateValue != null ? _condFileSearchAndFilter.datetime.push(selectedFinishDateValue.toLocaleString()) : _condFileSearchAndFilter.datetime.push("");
        }
        setCondFileSearchAndFilter(_condFileSearchAndFilter)
    }

    const handleClick_Clear = (e) => {
        setSelectedDepartmentValue(allDepartment[0]);
        setSelectedTeacherValue(allTeacher[0]);
        setSelectedStartDateValue(null);
        setSelectedFinishDateValue(null);
    }

    const handleClick_SearchTextClear = (e) => {
        const elm = document.getElementById(props.componentParent + "inputSearchText");
        elm.value = "";
        elm.focus();
        setSearchText(elm.value);
    }

    const handleKeyDown_SearchText = (e) => {
        switch (e.key) {
            case "Enter":
                handleClick_Search(e);
                break;
            default:
                break;
        }
    }

    return (
        <div className="compSearchAndFilter">
            <div className="areaUpper">
                <div className="areaInputSearchText">
                    <div className="buttonInputSearchText_Search" onClick={(e) => handleClick_Search(e)} />
                    <input className="inputSearchText" type="text" id={props.componentParent + "inputSearchText"} placeholder="キーワード・タグ検索" value={searchText}
                        onInput={(e) => handleChange_SearchText(e)}
                        onKeyDown={(e) => handleKeyDown_SearchText(e)}
                    />
                    <div className="buttonInputSearchText_Clear" onClick={(e) => handleClick_SearchTextClear(e)} />
                </div>
                <div className="areaIncludeSpeechAnalysis">
                    <input type="checkbox" name={props.componentParent + "includeSpeechAnalyzeForSearch"} id={props.componentParent +"includeSpeechAnalyzeForSearch"}
                        checked={includeSpeechAnalysis} onChange={() => {setIncludeSpeechAnalysis(!includeSpeechAnalysis)}} />
                    <label htmlFor={props.componentParent + "includeSpeechAnalyzeForSearch"}>検索対象に音声解析全文を含む</label>
                </div>

                <div className="areaDetailSearch">
                    <input className="checkboxFilterOnOff" type="checkbox" id={props.componentParent + "checkboxFilterOnOff"} checked={isFilterChecked} onChange={(e) => handleChange_FilterOnOff(e)} />
                    <label className="labelFilterOnOff backgroundGrayGradiation" htmlFor={props.componentParent + "checkboxFilterOnOff"}>
                        <img className="sizeIconSmall" src="../images/slider.png" />
                        {(isFilterChecked == true) ? labelFilterOnOff[0] : labelFilterOnOff[1]}
                    </label>
                </div>
            </div>

            {isFilterChecked &&
                <div className="areaLower">
                    <table width="100%">
                        <tbody>
                        <tr width="100%">
                            <td width="25%">学部選択</td>
                            <td width="25%">教員名選択</td>
                            <td width="25%">日付絞り込み</td>
                            <td width="25%"></td>
                        </tr>

                        <tr>
                            <td className="areaSearchItem areaNoWrap">
                                <select onChange={(e) => handleChange_SelectedDepartmentValue(e)}
                                    value={selectedDepartmentValue}>
                                    {
                                        allDepartment.map((item) => {
                                            return (
                                                <option value={item.department_id} key={props.componentParent + "Department" + item.department_id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </td>

                            <td className="areaSearchItem areaNoWrap">
                                <select onChange={(e) => handleChange_SelectedTeacherValue(e)}
                                    value={selectedTeacherValue}>
                                    {
                                        allTeacher.map((item) => {
                                            return (
                                                <option value={item.user_id} key={props.componentParent + "Teacher" + item.user_id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </td>

                            <td className="areaSearchItem areaNoWrap">
                                <DatePicker className="datePicker"
                                    selected={selectedStartDateValue}
                                    onChange={(e) => handleChange_SelectedStartDateValue(e)}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    placeholderText="Start Date"
                                />
                                <span style={{ margin: "0px 5px 0px 5px" }}>～</span>
                                <DatePicker className="datePicker"
                                    selected={selectedFinishDateValue}
                                    onChange={(e) => handleChange_SelectedFinishDateValue(e)}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    placeholderText="End Date"
                                />
                            </td>
                            <td className="areaSearchItem areaNoWrap">
                                <button className="buttonClear backgroundGrayGradiation" onClick={(e) => handleClick_Clear(e)}>クリア</button>
                                <button className="buttonSearch backgroundGrayGradiation" id={props.componentParent + "buttonSearch"} onClick={(e) => handleClick_Search(e)}>
                                    <label className="cursorUnset" htmlFor={props.componentParent + "buttonSearch"}>
                                        <img className="sizeIconSmall" src="../images/search.png" />
                                        この条件で検索する
                                    </label>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}