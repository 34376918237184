import Config from "../config"
import * as RestBase from '../utils/RestBase.js'

// TODO: GetAllTeachersを使うように変更してください。
export function GetAllTeacher() {
    return (
        [
            {
                education_user_internal_id: 0,
                education_user_login_name: "",
                ladepartment_internal_idbel: 0,
                department_name: "",
                flag_teacher: true,
            },
            {
                education_user_internal_id: 1,
                education_user_login_name: "教員１",
                ladepartment_internal_idbel: 1,
                department_name: "部署１",
                flag_teacher: true,
            },
            {
                education_user_internal_id: 2,
                education_user_login_name: "教員２",
                ladepartment_internal_idbel: 2,
                department_name: "部署２",
                flag_teacher: true,
            },
        ]
    )
}

export async function GetAllTeachers() {
    const resp = await RestBase.GET(
        Config.DaAlpsRestServer + "user?is_teacher=true"
    );
    return resp;
}

export async function GetTeacher(user_id) {
    const resp = await RestBase.GET(
        Config.DaAlpsRestServer + "user/" + user_id
    );
    return resp;
}
