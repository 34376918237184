import React from "react";
import "./compRanking.css";
import crown from "../../resources/crown_sliver.png"
import { Tooltip } from "chart.js";

export default function (props) {
	const titleClassName = props.titleClass ? props.titleClass : "labelRankingTitle"
	const areaTitleClassName = props.titleAreaClass ? props.titleAreaClass : "areaLabelRankingTitle"
	if (!props.datas) return (<></>)

	const createRankingLine = (index, text, count) => {
		return (
			<div key={index} className={index < 3 ? "RankingLineTopThree" : "RankingLine"}>
				<div className={index < 3 ? "RankingLineBoxTopThree" : "RankingLineBox"}>
					{index == 0 && !props.noCrown &&
						<div className={"RankingLineTopCrown"}>
							<img src={crown} />
						</div>
					}
					<span title={text}>{index < 9 ? "　" : ""}{index + 1}位 {text}</span>
				</div>
				<div className={index < 3 ? "RankingRightArrowTopThree" : ""}></div>
				{count >= 0 &&
					<div className={index < 3 ? "RankingLineCountBoxTopThree" : "RankingLineCountBox"}> <span>{count}回</span> </div>
				}
			</div>
		)
	}
	return (
		<div className="Ranking">
			<div className={areaTitleClassName}><label className={titleClassName}>{props.titleText ? props.titleText : ""}</label></div>
			{props.datas.map((data, index) => {
				if (data.contents) {
					const text = data.contents.map(content => content + " ")
					return (
						createRankingLine(index, text, data.count)
					)
				}
			})}
		</div>
	)
}