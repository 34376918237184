import { useContext, useEffect, useState } from "react";

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as Home from "../pages/home"
import * as AssetUtils from "../utils/AssetUtils"
import * as UserInfo from "../common/userInfo"
import * as OtherSettings from "../utils/OtherSettings";

import "./compSpeechAnalyze.css"

export default function (props) {
    const screenUpdateFps = 30;
    const userInfo = UserInfo.getUsetInfo();
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
    const [resultSpeechAnalyze, setResultSpeechAnalyze] = useState([]);
    const [speakerCount, setSpeakerCount] = useState(1);
    const [prevSelectTextFrame, setPrevSelectTextFrame] = useState(null);
    const [videoCurrentTime, setVideoCurrentTime] = useState();
    const [analyzeSpeechThreshold, setAnalyzeSpeechThreshold] = useState("");
    useEffect(() => {
        const timeoutId = setInterval(() => {
            setVideoCurrentTime(Utils.GetVideoCurrentTime());
        }, (1000 / screenUpdateFps));
        // 音声解析 信頼度閾値の取得
        OtherSettings.GetSettingAnalyzeSpeechConfidenceThreshold()
            .then((threshold) => {
                setAnalyzeSpeechThreshold(threshold.value);
            });
        return () => clearInterval(timeoutId);
    }, []);

    const [valueKeyword, setValueKeyword] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [aryCurSearchedSpanElem, setAryCurSearchedSpanElem] = useState([]);

    const [limitSpeechAnalyzeSec, setLimitSpeechAnalyzeSec] = useState(0);
    const [usedSpeechAnalyzeSec, setUsedSpeechAnalyzeSec] = useState(0);

    const idScrollArea = "idAreaTableAllText"
    const idLineText = "idLineText_";
    const [aryCurPlayingSpanElem, setAryCurPlayingSpanElem] = useState([]);
    const [isMouseDownOnAllTextArea, setIsMouseDownOnAllTextArea] = useState(false);

    useEffect(() => {
        const elm = document.getElementById(idScrollArea);
        elm.scrollTop = 0;
        setIsShowEditArea(false);

        if (Utils.isEmpty(selectAsset) === true)
            setResultSpeechAnalyze([]);
        else {
            const speechAnalyze = AssetUtils.GetSpeechAnalyzeArray(selectAsset, "");
            setResultSpeechAnalyze(speechAnalyze);
            // 話者数をセット
            const speakerSet = new Set();
            speechAnalyze.forEach(elem => {speakerSet.add(elem.speaker);});
            setSpeakerCount(speakerSet.size);
        }
        handleClick_SearchTextClear(null);

        (async () => {
            const resultSettings = await AssetUtils.AsyncGetAnalyzeSettings();
            setLimitSpeechAnalyzeSec(resultSettings.cinnamon_enabled ? 0 : resultSettings.googleSpeechTextLimitSec);
            setUsedSpeechAnalyzeSec(resultSettings.googleSpeechTextUsedSec);
        })();
    }, [selectAsset])

    useEffect(() => {
        const timeout = setTimeout(() => setSearchKeyword(valueKeyword), 500);
        return () => clearTimeout(timeout)
    }, [valueKeyword])

    // 検索文字列の色付け
    useEffect(() => {
        // 直前の検索結果表示をクリア
        for (let span of aryCurSearchedSpanElem) {
            Utils.DeleteAttributeClass(span, "highlightSpeech");
        }
        if (!resultSpeechAnalyze || resultSpeechAnalyze.length === 0) {
            setAryCurSearchedSpanElem([]);
            return;
        }

        // 検索実施
        const arySpanElem = [];
        const keywords = searchKeyword.split(/\s/);
        const elms = document.querySelectorAll("tr[id*='" + idLineText + "']");
        if (keywords && elms) {
            const textIndex = (speakerCount == 1) ? 1 : 2
            keywords.forEach((word) => {
                if (word.length === 0)
                    return;
                //for (let i = 0; i < elms.length; i++) {
                elms.forEach((elm) => {
                    if (!elm.dataset.alltext || elm.dataset.alltext.length === 0)
                        return;
                    let startIdx = 0;
                    while (startIdx >= 0 && startIdx < elm.dataset.alltext.length) {
                        const matchIndex = elm.dataset.alltext.indexOf(word, startIdx);
                        if (matchIndex < 0)
                            break;
                        for (let idx = 0; idx < word.length; idx++) {
                            const span = elm.cells[textIndex].children[0].children[matchIndex + idx];
                            arySpanElem.push(span);
                        }
                        startIdx = matchIndex + word.length;
                    }
                });
            });
        }
        setAryCurSearchedSpanElem(arySpanElem);
        // 色付け
        arySpanElem.forEach((span) => {
            Utils.AddAttributeClass(span, "highlightSpeech");
        });
    }, [searchKeyword])

    useEffect(() => {
        // 再生中の行を取得
        const elms = document.querySelectorAll("tr[id*='" + idLineText + "']");
        var prevIndex = elms.length - 1;

        for (var i = 0; i < elms.length; i++) {
            const elm = elms[i];
            const id = elm.id;
            const tc = AssetUtils.GetTcFromHHMMSSFFF(selectAsset, id.replace(idLineText, ""));
            const secFloat = AssetUtils.GetSecFloatFromTC(selectAsset, tc);

            if (secFloat > videoCurrentTime) {
                prevIndex = Math.max(0, i - 1);
                break;
            }
        }
        // 再生位置の色付け
        for (let span of aryCurPlayingSpanElem) {
            Utils.DeleteAttributeClass(span, "speech_text_playing");
        }
        if (!Utils.GetVideoPaused() && prevIndex >= 0) {
            const assetFps = AssetUtils.GetFrameRate(selectAsset);
            const curFrameS = Math.floor(videoCurrentTime * assetFps);
            const curFrameE = curFrameS + screenUpdateFps - 1;
            const arySpanElem = [];
            const TextIndex = (speakerCount == 1) ? 1 : 2
            for (let span of elms[prevIndex].cells[TextIndex].children[0].children) {
                const startFrame = Number(span.dataset.startframe);
                const endFrame = Number(span.dataset.endframe);
                if (startFrame <= curFrameE && endFrame >= curFrameS) {
                    Utils.AddAttributeClass(span, "speech_text_playing");
                    arySpanElem.push(span);
                }
            }
            setAryCurPlayingSpanElem(arySpanElem);
        }

        // check selected text play.
        if (prevSelectTextFrame != null) {
            const secFloat = AssetUtils.GetSecFloatFromTC(selectAsset, prevSelectTextFrame);
            if (videoCurrentTime > secFloat) {
                setPrevSelectTextFrame(null);
            }
        }
    }, [videoCurrentTime]);

    // auto scroll
    useEffect(() => {
        if (isMouseDownOnAllTextArea || aryCurPlayingSpanElem.length == 0) return;

        const elm = aryCurPlayingSpanElem[0];
        if (Utils.isEmpty(elm)) return;
        let speechArea = elm.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

        // no scrolling
        if (speechArea.clientHeight == speechArea.scrollHeight) return;

        let offset = elm.offsetTop + elm.parentElement.parentElement.offsetTop;
        if (offset < speechArea.scrollTop || offset + elm.offsetHeight > speechArea.scrollTop + speechArea.clientHeight) {
            offset = Math.max(0, elm.offsetTop + elm.parentElement.parentElement.offsetTop - speechArea.clientHeight * 0.4);
            speechArea.scrollTop = offset;
        }
    }, [aryCurPlayingSpanElem])

    const headers = ["TC", "話者", "TEXT", "信頼度", "修正"];
    const idSsearchText = "idSsearchText";

    const handleTextBodyClick = (frame) => {
        setIsShowEditArea(false);
        const secFloat = AssetUtils.GetSecFloatFromTC(selectAsset, frame);
        setPrevSelectTextFrame(frame);
        Utils.SetVideoCurrentTime(secFloat);
    }

    const handleSaveClick = (e) => {
        let all_array = [];

        all_array.push(headers);

        resultSpeechAnalyze.forEach((item) => {
            let row = [item.tc, item.speaker, item.text, item.reliability, (item.isChange == true) ? "済" : ""];
            all_array.push(row);
        })

        const csv_text = Utils.ConvCsvFromArrayInArray(all_array);

        Utils.DownloadText(
            Utils.GetNowDateTime_NoSep() + "_" + selectAsset.asset_name + "_SpeechAnalyze.csv"
            , csv_text
        );
    }

    const handleCopyClipbordClick = (e) => {
        let all_text = "";

        resultSpeechAnalyze.forEach((item) => {
            all_text += item.text + Defines.NewLine;
        })

        Utils.CopyToClipboard(all_text);
    }

    const handleClick_SearchTextClear = (e) => {
        const elm = document.getElementById(idSsearchText);
        elm.value = "";
        setValueKeyword(elm.value);
    }

    const handleClick_InsertTag = (e) => {
        const selectedText = window.getSelection().toString();
        // check null or empty
        if (!selectedText) {
            window.alert("テキストを選択してください")
            return;
        }

        const frame = window.getSelection().baseNode.parentElement.dataset.startframe;
        if (frame < 0) return;
        // check duplicate
        if (selectAsset.tags.findIndex(tag => (tag.tag == selectedText && tag.frame == frame)) > -1) {
            window.alert("登録済みのタグです。");
            return;
        }

        RestBase.POST(
            Config.DaAlpsRestServer + "assets/tag"
            , {
                asset_id: selectAsset.asset_id,
                user_id: userInfo.user_id,
                tag: selectedText,
                frame: frame,
                flag_auto: false
            }
        ).then((data) => {
            RestBase.GET(
                Config.DaAlpsRestServer + "assets/" + selectAsset.asset_id
            )
                .then((data) => {
                    setSelectAsset(data.asset);
                });
        });
    }

    function GetStringHHMMFromSec(_sec) {
        const [hour, minute, sec] = Utils.ConvHHMMSSFromSec(_sec);

        return (hour) + "時間" + (minute) + "分"
    }

    const [isShowEditArea, setIsShowEditArea] = useState(false);
    const [editSpeechAnalyze, setEditSpeechAnalyze] = useState([]);
    const [editedReliability, setEditedReliability] = useState(0.0);
    const [editedSpeaker, setEditedSpeaker] = useState(1);
    const handleClick_showEditArea = (e, item) => {
        const editSA = item.datas.map((data, index) => {
            return {
                tag: data.tag.replace(/\r?\n/g , ''),
                frame: data.frame,
                frame_end: data.frame_end,
                frequency: data.frequency,
                line_no: data.line_no,
                edit_join_prev_tag: false,
                edit_line_break: data.tag.endsWith('\n'),
                edit_change_sentence: (index == 0),
            }
        });
        setEditSpeechAnalyze(editSA);
        setEditedReliability(item.reliability.toFixed(2));
        setEditedSpeaker(item.speaker);
        setIsShowEditArea(true);
    }

    const handle_editSpeechAnalyze_checkChanged = (index) => (e) => {
        const newEditSA = editSpeechAnalyze.map((item, i) => {
            if (index === i) {
                if (e.target.name === "tag")
                    return { ...item, [e.target.name]: e.target.value };
                if (e.target.name === "edit_join_prev_tag" || "edit_line_break" || "edit_change_sentence")
                    return { ...item, [e.target.name]: e.target.checked };
            }
            return item;
        });
        setEditSpeechAnalyze(newEditSA);
    }

    const handleClick_ApplyEdit = (e) => {
        // Validate reliability
        let threshold = -1.0;
        if (/^[0-9]*[\.]?[0-9]*$/.test(editedReliability)) {
            threshold = parseFloat(editedReliability);
            if (threshold === NaN || threshold < 1.0 || threshold > 100.0) {
                threshold = -1.0;
            }
        }
        if (threshold < 0.0) {
            alert("信頼度は、1～100の少数で入力してください。");
            return false;
        }
        // Validate speaker
        let updateSpeaker = 0;
        if (/^[0-9]*$/.test(editedSpeaker)) {
            updateSpeaker = parseInt(editedSpeaker);
            if (updateSpeaker === NaN || updateSpeaker < 1) {
                updateSpeaker = 0;
            }
        }
        if (updateSpeaker < 1) {
            alert("話者は、1以上の整数で入力してください。");
            return false;
        }

        // update values
        RestBase.POST(
            Config.DaAlpsRestServer + "assets/analyze_speech/update_tags",
            {
                asset_id: selectAsset.asset_id,
                line_no: editSpeechAnalyze[0].line_no,
                confidence: editedReliability / 100.0,
                speaker: editedSpeaker,
                tags: editSpeechAnalyze,
            }
        ).then((asset_ai_analyze) => {
            // Reloaded asset_ai_analyze data
            setIsShowEditArea(false);
            const newAsset = { ...selectAsset, [`ai_analyze`]: asset_ai_analyze };
            setSelectAsset(newAsset);
        }).catch((error) => {
            console.log("音声解析情報を修正できませんでした。 " + error.toString());
            alert("音声解析情報を修正できませんでした。\n" + error.toString());
        });
    }

    return (
        <div className="compSpeechAnalyze gridSpeechAnalyze">
            {props.isDispRemainTime == true &&
                <span className="areaSpeechAnalyzeLimit">
                    {(() => {
                        if (limitSpeechAnalyzeSec === 0) {
                            return (<label>音声解析は上限時間なくご使用いただけます。</label>);
                        } else if (usedSpeechAnalyzeSec < limitSpeechAnalyzeSec) {
                            return (
                                <label>
                                ご契約の音声解析時間は一月、{GetStringHHMMFromSec(limitSpeechAnalyzeSec)}です。
                                &nbsp;残り、{GetStringHHMMFromSec(limitSpeechAnalyzeSec - usedSpeechAnalyzeSec)}ご使用いただけます。
                                </label>
                            );
                        } else {
                            return (<label>今月の音声解析は規定時間の{GetStringHHMMFromSec(limitSpeechAnalyzeSec)}分に達しました。</label>);
                        }
                    })()}
                </span>
            }
            <div className="areaActionApeechAnalyze">
                <table width="100%">
                    <tbody>
                    <tr>
                        <td>
                            <table width="100%">
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="areaInputSearchText">
                                            <div className="buttonInputSearchText_Search" />
                                            <input className="inputSearchText" type="text" id={idSsearchText} placeholder="ワード検索" onChange={(e) => setValueKeyword(e.target.value)} />
                                            <div className="buttonInputSearchText_Clear" onClick={(e) => handleClick_SearchTextClear(e)} />
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className="areaAllText">
                <div className="areaTableAllTextTitle">
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td>
                                <div className="areaLabelTitle"><label className="labelTitle">全文表示</label></div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="areaTableAllText" id={idScrollArea}
                    onMouseDown={(e)=>{if (e.button===0||e.button===1)setIsMouseDownOnAllTextArea(true)}}
                    onMouseUp={()=>{setIsMouseDownOnAllTextArea(false)}}>
                    <table className="tableAllText">
                        <thead>
                        <tr className="tableAllTextHeader">
                            <th width="10%">{headers[0]}</th>
                            {resultSpeechAnalyze[0] && speakerCount > 1 &&
                                <th width="5%">{headers[1]}</th>
                            }
                            <th>{headers[2]}</th>
                            {props.isDispConfidence &&
                                <th width="10%">{headers[3]}</th>
                            }
                            {props.isDispFixed &&
                                <th width="10%">{headers[4]}</th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {resultSpeechAnalyze.map((item, index) => {
                            return (
                                <tr key={index} className="tableAllTextBody" id={idLineText + item.tc} data-alltext={item.text}>
                                    <td>{item.tc}</td>
                                    {speakerCount > 1 &&
                                        <td>&nbsp;{item.speaker}</td>
                                    }
                                    <td>
                                        <div className="normalSpeech">
                                            {item.datas.map((data, index) => {
                                                const datasIndex = index;
                                                return data.tag.split("").map((char, index) => {
                                                    return <span key={datasIndex + "-" + index} data-startframe={data.frame} data-endframe={data.frame_end}
                                                           onClick={(e) => handleTextBodyClick(data.frame)}>{char}</span>
                                                })
                                            })}
                                        </div>
                                    </td>
                                    {props.isDispConfidence &&
                                        <td style={Utils.isEmpty(analyzeSpeechThreshold) ? {} : item.reliability > Number(analyzeSpeechThreshold) ? {} : { color: "red" }}>{item.reliability.toFixed(2)}</td>
                                    }
                                    {props.isDispFixed &&
                                        <td>
                                            <div className="speechTextEditButtonArea">
                                                {(item.isChange == true) ? "済 " : "未"}
                                                <button className="buttonTextEdit backgroundGrayGradiation"
                                                    onClick={(e) => handleClick_showEditArea(e, item)}>
                                                    <label className="cursorUnset">修正</label>
                                                </button>
                                            </div>
                                        </td>
                                    }
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            {isShowEditArea &&
                <div className="editTagArea">
                    <table className="tableEditArea">
                        <thead>
                        <tr className="tableEditAreaTextHeader">
                            <th>TC</th>
                            <th width="35%">TEXT</th>
                            <th>上と結合</th>
                            <th>末尾で改行</th>
                            <th>文を分ける</th>
                        </tr>
                        </thead>
                        <tbody>
                        {editSpeechAnalyze.map((data, index) => {
                            return (
                                <tr className="tableEditAreaTextBody" key={index}>
                                    <td>
                                        {AssetUtils.GetHHMMSSFFFromTC(selectAsset, data.frame)}～{AssetUtils.GetHHMMSSFFFromTC(selectAsset, data.frame_end)}
                                    </td>
                                    <td><input type="text" className="widthFull" name="tag" key="{index}" value={data.tag}
                                        onChange={handle_editSpeechAnalyze_checkChanged(index)}
                                    /></td>
                                    <td><input type="checkbox" name="edit_join_prev_tag" key="{index}" checked={data.edit_join_prev_tag}
                                        disabled={index == 0}
                                        onChange={handle_editSpeechAnalyze_checkChanged(index)}
                                    /></td>
                                    <td><input type="checkbox" name="edit_line_break" key="{index}" checked={data.edit_line_break}
                                        onChange={handle_editSpeechAnalyze_checkChanged(index)}
                                    /></td>
                                    <td><input type="checkbox" name="edit_change_sentence" key="{index}" checked={data.edit_change_sentence}
                                        onChange={handle_editSpeechAnalyze_checkChanged(index)}
                                    /></td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            }

            {!isShowEditArea &&
                <div className="areaSaveAndCopy">
                    {props.isDispButtonInsertTag == true &&
                        <button className="buttonInsertTag backgroundGrayGradiation" id="buttonInsertTag" disabled={Utils.isEmpty(selectAsset)} onClick={(e) => handleClick_InsertTag(e)}>
                            <label className="cursorUnset">タグ登録</label>
                        </button>
                    }

                    <button className="buttonSave backgroundGrayGradiation" id="buttonSave" disabled={Utils.isEmpty(selectAsset)} onClick={(e) => handleSaveClick(e)}>
                        <span className="spanButtonSave" htmlFor="buttonSave">
                            <label className="cursorUnset">
                                <img src="../images/save.png" />
                            </label>
                            <label className="cursorUnset">名前を付けて保存</label>
                        </span>
                    </button>

                    <button className="buttonCopyClipbord backgroundGrayGradiation" id="buttonCopyClipbord" disabled={Utils.isEmpty(selectAsset)} onClick={(e) => handleCopyClipbordClick(e)}>
                        <span className="spanButtonCopyClipbord" htmlFor="buttonCopyClipbord">
                            <label className="cursorUnset">
                                <img src="../images/copy.png" />
                            </label>
                            <label className="cursorUnset">全文をコピー</label>
                        </span>
                    </button>
                </div>
            }
            {isShowEditArea &&
                <div className="areaSaveAndCopy">
                    <div className="reliabilityTextArea">
                        信頼度&nbsp;
                        <input type="text" id="reliabilityTextbox" className="reliabilityTextbox"
                                value={editedReliability} onChange={(e) => {setEditedReliability(e.target.value)}}
                            />
                    </div>
                    <div className="speakerTextArea">
                        話者&nbsp;
                        <input type="text" id="speakerTextbox" className="speakerTextbox"
                                value={editedSpeaker} onChange={(e) => {setEditedSpeaker(e.target.value)}}
                            />
                    </div>
                    <button className="buttonApplyEdit backgroundGrayGradiation" id="buttonApplyEdit"
                        onClick={(e) => handleClick_ApplyEdit(e)}>
                        <label className="cursorUnset">設定</label>
                    </button>
                    <button className="buttonCancelEdit backgroundGrayGradiation" id="buttonCancelEdit"
                        onClick={() => setIsShowEditArea(false)}>
                        <label className="cursorUnset">キャンセル</label>
                    </button>
                </div>
            }
        </div>
    )
}