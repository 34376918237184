import * as Defines from "../common/defines"
import * as RestBase from "./RestBase"
import Config from "../config"

export function UserAuthentication(user_id, password) {
    let userInfo = {
        user_id: user_id,
        fomsrec_user_id: '',
        name: '',
        permission_id: Defines.USER_PERMISSION_ID.NONE,
        favorite_asset_ids: [],
        history_asset_ids: [],
    }

    switch (user_id) {
        case "admin":
            userInfo.permission_id = Defines.USER_PERMISSION_ID.ADMIN;
            break;
        case "teacher":
            userInfo.permission_id = Defines.USER_PERMISSION_ID.TEACHER;
            break;
        case "student":
            userInfo.permission_id = Defines.USER_PERMISSION_ID.STUDENT;
            break;
        default:
            userInfo = null;
            break;
    }

    return userInfo;
}

// ひとまず処理のみ実装
export function _UserAuthentication(user_id, password) {
    return RestBase.POST(
        Config.DaAlpsRestServer + "signin",
        {
            login_user_id: user_id,
            password: password
        }
    ).then((data) => {
        if (data) {
            // TODO: department_id -> permission_idへの変換
            const get_permission_id = (data) => {
                if (data.is_admin) {
                    return Defines.USER_PERMISSION_ID.ADMIN
                } else if (data.is_teacher) {
                    return Defines.USER_PERMISSION_ID.TEACHER
                } else {
                    return Defines.USER_PERMISSION_ID.STUDENT
                }
            }
            let userInfo = {
                user_id: data.user_id,
                fomsrec_user_id: data.fomsrec_user_id,
                name: data.name,
                department_id: data.department_id,
                permission_id: get_permission_id(data),
                favorite_asset_ids: [],
                history_asset_ids: [],
            }
            return userInfo;
        } else {
            return null;
        }
    })
}
