import { useContext, useEffect, useState, useMemo } from "react";
import "./compReferencesSetting.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as AssetUtil from "../utils/AssetUtils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as Home from "../pages/home"
import * as AssetUtils from "../utils/AssetUtils"
import CompSearchAndFilter from "./compSearchAndFilter"
import CompFileListItem from "./compFileListItem";

export default function () {
    const [condFileSearchAndFilter, setCondFileSearchAndFilter] = useState({});
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
    const [analyzeSettings, setAnalyzeSettings] = useState({ cinnamon_enabled: false });
    const [isWordCloud, setIsWordCloud] = useState(false);

    const condRestSearch = useMemo(() => {
        // var category_ids = null;
        // if(Utils.isEmpty(condFileSearchAndFilter) != true) {
        //     category_ids = (condFileSearchAndFilter.faculties.length > 0) ? condFileSearchAndFilter.faculties : null;
        // }

        return (
            {
                asset_ids: null,
                children_table: true,
                children_table_meta: true,
                text: condFileSearchAndFilter.text,
                include_speech_analysis: condFileSearchAndFilter.include_speech_analysis,
                datetime: condFileSearchAndFilter.datetime,
                file_format_ids: [Defines.ASSET_FILE_FORMAT_ID.MOVIE],   // Movieのみ
                department_id: condFileSearchAndFilter.department_id,
                teacher_id: condFileSearchAndFilter.teacher_id,
            }
        )
    }, [condFileSearchAndFilter]);

    const [resultsValue, setResultsValue] = useState([]);

    useEffect(() => {
        (async () => {
            const resultSettings = await AssetUtil.AsyncGetAnalyzeSettings();
            setAnalyzeSettings(resultSettings);
        })();
    }, []);

    useEffect(() => {
        setResultsValue([]);

        const respRest = RestBase.POST(
            Config.DaAlpsRestServer + "assets/search"
            , condRestSearch
        ).then((data) => {
            var assets = data.assets;

            if (Utils.isEmpty(condFileSearchAndFilter.departments) != true) {
                assets = assets.filter((item) => {
                    const metaInfo = AssetUtil.GetMetaInfo(item, Defines.ASSET_META_ID.DEPARTMENT_ID.id);
                    if (condFileSearchAndFilter.departments.includes(metaInfo.value_text)) {
                        return item;
                    }
                })
            }

            setResultsValue(assets);
        });
    }, [condRestSearch]);

    const returnBodyCurrent = ((asset) => {
        if (Utils.isEmpty(asset) == true) return <div />;

        return (
            <CompFileListItem
                isFromComponent={Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING.id}
                isCanDelete={false}
                asset={asset}
                referenceBaseAsset={selectAsset}
                condFileSearchAndFilter={[condFileSearchAndFilter, setCondFileSearchAndFilter]}
                isWordCloud={isWordCloud}
            />
        )
    });

    const returnBodySearchedAsset = ((asset) => {
        if (Utils.isEmpty(asset) == true) return <div />;

        const ignoreCurrentAssets = resultsValue.filter((item) => {
            if (item.asset_id != asset.asset_id) return item;
        })

        return (
            ignoreCurrentAssets.map((item, index) => {
                return (
                    <CompFileListItem
                        key={index}
                        isFromComponent={Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING.id}
                        isCanDelete={false}
                        asset={item}
                        referenceBaseAsset={selectAsset}
                        condFileSearchAndFilter={[condFileSearchAndFilter, setCondFileSearchAndFilter]}
                        analyzeSettings={analyzeSettings}
                        isWordCloud={isWordCloud}
                    />
                )
            })
        )
    });

    return (
        <div className="compReferencesSetting gridReferencesSetting">
            <CompSearchAndFilter
                condFileSearchAndFilter={[condFileSearchAndFilter, setCondFileSearchAndFilter]}
                componentParent="fromReferencesSetting"
            />

            <div className="areaBody">
                <div className="areaBodyCurrent">
                    {returnBodyCurrent(selectAsset)}
                </div>
                <div className="areaBodySearched">
                    {returnBodySearchedAsset(selectAsset)}
                </div>
            </div>

            <div className="areaNewIngest widthFull">
                <table width="100%">
                    <tbody>
                    <tr>
                        {analyzeSettings.cinnamon_enabled &&
                            <td align="left">
								<input type="checkbox" name="thumbnailFromWordCloudOnOff_References" id="thumbnailFromWordCloudOnOff_References"
									checked={isWordCloud} onChange={() => { setIsWordCloud(!isWordCloud) }} />
								<label htmlFor="thumbnailFromWordCloudOnOff_References">サムネイルをワードクラウド表示に切替</label>
							</td>
						}
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
