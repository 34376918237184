import Config from "../config"
import * as Defines from "../common/defines"
import * as RestBase from '../utils/RestBase.js'
import * as UserInfo from "../common/userInfo"

export async function GetAllDepartment() {
    const userInfo = UserInfo.getUsetInfo();
    const resp = await RestBase.GET(
        Config.DaAlpsRestServer + "department/"
    );


    let department = await resp;

    return department;
}