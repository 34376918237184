import React, { useContext, useEffect, useState, useMemo } from "react";
import * as Defines from "../common/defines"
import * as RestBase from "../utils/RestBase";
import * as Utils from "../utils/Utils";
import * as UserInfo from "../common/userInfo"
import Ranking from "./graphComponents/compRanking";
import Config from "../config";
import "./compStatisticWhole.css";

export default function (props) {
    const userInfo = UserInfo.getUsetInfo();
    const [searchDurationCombo, setSearchDurationCombo] = useState([]);
    const [searchDurationComboValue, setSearchDurationComboValue] = useState(null)
    const handleChange_SearchDurationComboValue = (e) => {
        setSearchDurationComboValue(searchDurationCombo[e.target.selectedIndex]);
    };

    const [facultySelectCombo, setFacultySelectaCombo] = useState([]);
    const [facultySelectComboValue, setFacultySelectComboValue] = useState(null)
    const handleChange_facultySelectComboValue = (e) => {
        setFacultySelectComboValue(facultySelectCombo[e.target.selectedIndex]);
    };
    useEffect(() => {
        GetSearchDurationCombo();
        GetFacultySelectCombo();
    }, [])
    useEffect(() => {
        if (Utils.isEmpty(searchDurationComboValue)) return;
        AccessCount();
        ViewStudentRanking();
        ViewTeacherRanking();
    }, [searchDurationComboValue])
    useEffect(() => {
        if (Utils.isEmpty(searchDurationComboValue) || Utils.isEmpty(facultySelectComboValue)) return;
        FacultyAccessCount();
        FacultyViewRanking();
        FacultySearchRanking();
    }, [searchDurationComboValue, facultySelectComboValue])

    const GetSearchDurationCombo = () => {
        RestBase.GET(
            Config.DaAlpsRestServer + "statistics/search_period"
        ).then((periods) => {
            let formatPeriods = periods.map(period => {
                return ({
                    academic_year: period.academic_year,
                    end_date: new Date(period.end_date.slice(0, period.end_date.indexOf('T')).split('-')),
                    start_date: new Date(period.start_date.slice(0, period.start_date.indexOf('T')).split('-')),
                    title: period.title
                })
            })
            const today = new Date();
            const yesterday = new Date(new Date().setDate(today.getDate() - 1));
            let periodArr = [];
            let id = 1;
            periodArr.push({
                id: id++,
                dispName: "昨日",
                startDate: new Date(yesterday),
                endDate: new Date(yesterday)
            });
            periodArr.push({
                id: id++,
                dispName: "過去一週間",
                startDate: new Date(new Date().setDate(today.getDate() - 7)),
                endDate: new Date(yesterday)
            });
            periodArr.push({
                id: id++,
                dispName: "過去30日間",
                startDate: new Date(new Date().setDate(today.getDate() - 30)),
                endDate: new Date(yesterday)
            });
            formatPeriods.map(formatPeriod => {
                periodArr.push({
                    id: id++,
                    dispName: formatPeriod.title,
                    startDate: new Date(formatPeriod.start_date),
                    endDate: new Date(formatPeriod.end_date)
                })
            })
            setSearchDurationCombo(periodArr);
            setSearchDurationComboValue(periodArr[0]);
        })
    }

    const searchDuration = () => {
        return (
            <div className="areaSearchDurationCombo">
                <div className="areaLabelStatisticsTitle"><div className="labelStatisticsTitle"> 検索期間 </div></div>
                <select
                    onChange={(e) => handleChange_SearchDurationComboValue(e)}
                    value={searchDurationComboValue ? searchDurationComboValue.id : ""}>
                    {
                        searchDurationCombo.map((item) => {
                            return (
                                <option value={item.id} key={item.id}>{item.dispName}</option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }

    const [accessCountData, setAccessCountData] = useState({});
    const [accessCount, setAccessCount] = useState(<div />)
    const AccessCount = () => {
        if (!searchDurationComboValue) return <div />
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/all_department/login_access/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}`
        ).then((data) => {
            setAccessCountData(data);
            setAccessCount(
                <div className="AccessCount">
                    <div className="areaLabelStatisticsTitle"><div className="labelStatisticsTitle">ログインアクセス総数</div></div>
                    <div className="AccessCountLine">
                        <span>{data.access_count}件</span>
                    </div>
                    <div className="AccessCountLine">
                        <span>{data.user_count}人</span>
                    </div>
                </div>
            )
        })
    }

    const [viewStudentRanking, setViewStudentRanking] = useState(<div />)
    const ViewStudentRanking = () => {
        if (!searchDurationComboValue) return <div />
        RestBase.GET(
            Config.DaAlpsRestServer + `department`
        ).then((departments) => {
            RestBase.GET(
                Config.DaAlpsRestServer + `statistics/all_department/view_student/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}/10`
            ).then(datas => {
                const rankData = datas.map(data => {
                    return {
                        contents: [
                            data.fomsrec_user_id,
                            departments.find(department => department.department_id == data.department_id).name,
                            data.user_name
                        ],
                        count: data.count
                    }
                })
                setViewStudentRanking(
                    <Ranking datas={rankData} titleText="よく見ている学生ランキング" />
                )
            })
        })
    }
    const [viewTeacherRanking, setViewTeacherRanking] = useState(<div />)
    const ViewTeacherRanking = () => {
        if (!searchDurationComboValue) return <div />
        RestBase.GET(
            Config.DaAlpsRestServer + `department`
        ).then((departments) => {
            RestBase.GET(
                Config.DaAlpsRestServer + `statistics/all_department/viewed_teacher/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}/10`
            ).then(datas => {
                const rankData = datas.map(data => {
                    return {
                        contents: [
                            data.fomsrec_user_id,
                            departments.find(department => department.department_id == data.department_id).name,
                            data.user_name
                        ],
                        count: data.count
                    }
                })
                setViewTeacherRanking(
                    <Ranking datas={rankData} titleText="よく見られている教員ランキング" />
                )
            })
        })
    }

    const GetFacultySelectCombo = () => {
        RestBase.GET(
            Config.DaAlpsRestServer + `department`
        ).then(departments => {
            const depArr = departments.map((department) => {
                return ({
                    id: department.department_id,
                    dispName: department.name
                })
            })
            setFacultySelectaCombo(depArr);
            setFacultySelectComboValue(depArr[0])
        })
    }

    const FacultySelect = () => {
        return (
            <div className="areaFacultySelectCombo">
                <div className="areaLabelStatisticsTitle"><div className="labelStatisticsTitle"> 学部選択 </div></div>
                <select
                    onChange={(e) => handleChange_facultySelectComboValue(e)}
                    value={facultySelectComboValue ? facultySelectComboValue.id : ""}>
                    {
                        facultySelectCombo.map((item) => {
                            return (
                                <option value={item.id} key={item.id}>{item.dispName}</option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }

    const [facultyAccessCountData, setFacultyAccessCountData] = useState({});
    const [facultyAccessCount, setFacultyAccessCount] = useState(<div />)
    const FacultyAccessCount = () => {
        if (!facultySelectComboValue || !searchDurationComboValue) return <div />
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/department/login_access/${facultySelectComboValue.id}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}`
        ).then((data) => {
            setFacultyAccessCountData(data);
            setFacultyAccessCount(
                <div className="AccessCount">
                    <div className="areaLabelStatisticsTitle"><div className="labelStatisticsTitle">ログインアクセス総数</div></div>
                    <div className="AccessCountLine">
                        <span>{data.access_count}件</span>
                    </div>
                    <div className="AccessCountLine">
                        <span>{data.user_count}人</span>
                    </div>
                </div>
            )
        })
    }

    const [facultyViewRanking, setFacultyViewRanking] = useState(<div />)
    const FacultyViewRanking = () => {
        if (!facultySelectComboValue || !searchDurationComboValue) setFacultyViewRanking(<div />)
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/department/viewed_movie/${facultySelectComboValue.id}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}/10`
        ).then((datas) => {
            const rankData = datas.map(data => {
                return {
                    contents: [data.asset_name],
                    count: data.view_count
                }
            })
            setFacultyViewRanking(
                <Ranking datas={rankData} titleText={"動画視聴ランキング"} />
            )
        })
    }

    const [facultySearchRanking, setFacultySearchRanking] = useState(<div />)
    const FacultySearchRanking = () => {
        if (!facultySelectComboValue || !searchDurationComboValue) setFacultySearchRanking(<div />)
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/department/search_word/${facultySelectComboValue.id}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}/10`
        ).then((datas) => {
            const rankData = datas.map(data => {
                return {
                    contents: [data.search_word],
                }
            })
            setFacultySearchRanking(
                <Ranking datas={rankData} titleText="タグ検索ランキング" />
            )
        })
    }

    const [isExporting, setIsExporting] = useState(false);
    const handleClick_ExportWhole = async(e) => {
        setIsExporting(true);
        try
        {
            const exportDate = new Date();
            let strCsv = "作成日時," + Utils.ConvStrDateTime2Date(exportDate, 'YYYY/MM/DD hh:mm:ss') + "\n" +
                "検索期間," + searchDurationComboValue.dispName +
                "," + Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYY/MM/DD') +
                "," + Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYY/MM/DD') + "\n" +
                "\n" +
                "ログインアクセス総数（件数）," + accessCountData.access_count + "\n" +
                "ログインアクセス総数（人数）," + accessCountData.user_count + "\n";

            {
                strCsv += "\nよく見ている学生ランキング\n順位,ID,学部,氏名,回数\n";
                await RestBase.GET(
                    Config.DaAlpsRestServer + `statistics/all_department/view_student/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}/0`
                ).then(datas => {
                    datas.forEach((data, index) => {
                        strCsv += `${index + 1},${data.fomsrec_user_id},${data.department_name},${data.user_name},${data.count}\n`;
                    });
                }).catch((error) => {
                    throw error;
                });
            }

            if (userInfo.permission_id == Defines.USER_PERMISSION_ID.ADMIN) {
                strCsv += "\nよく見られている教員ランキング\n順位,ID,学部,氏名,回数\n";
                await RestBase.GET(
                    Config.DaAlpsRestServer + `statistics/all_department/viewed_teacher/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}/0`
                ).then(datas => {
                    datas.forEach((data, index) => {
                        strCsv += `${index + 1},${data.fomsrec_user_id},${data.department_name},${data.user_name},${data.count}\n`;
                    });
                }).catch((error) => {
                    throw error;
                });
            }
            
            let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            let blob = new Blob([bom, strCsv], { type: "text/csv" });
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "全体統計_" + Utils.ConvStrDateTime2Date(exportDate, 'YYYYMMDDhhmmss') + ".csv";
            link.click();

        } catch (error) {
            console.log(error);
            alert("エクスポートできませんでした。");
        }
        setIsExporting(false);
    }

    const [isFacultyExporting, setIsFacultyExporting] = useState(false);
    const handleClick_ExportFaculty = async(e) => {
        setIsFacultyExporting(true);
        try
        {
            const exportDate = new Date();
            let strCsv = "作成日時," + Utils.ConvStrDateTime2Date(exportDate, 'YYYY/MM/DD hh:mm:ss') + "\n" +
                "検索期間," + searchDurationComboValue.dispName +
                "," + Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYY/MM/DD') +
                "," + Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYY/MM/DD') + "\n" +
                "学部," + facultySelectComboValue.dispName + "\n" +
                "\n" +
                "ログインアクセス総数（件数）," + facultyAccessCountData.access_count + "\n" +
                "ログインアクセス総数（人数）," + facultyAccessCountData.user_count + "\n";

            strCsv += "\n動画視聴ランキング\n順位,講義名,回数\n";
            await RestBase.GET(
                Config.DaAlpsRestServer + `statistics/department/viewed_movie/${facultySelectComboValue.id}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}/0`
            ).then((datas) => {
                datas.forEach((data, index) => {
                    strCsv += `${index + 1},${data.asset_name},${data.view_count}\n`;
                });
            }).catch((error) => {
                throw error;
            });
 
            strCsv += "\nタグ検索ランキング\n順位,検索キー\n";
            await RestBase.GET(
                Config.DaAlpsRestServer + `statistics/department/search_word/${facultySelectComboValue.id}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD')}/${Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD')}/0`
            ).then((datas) => {
                datas.forEach((data, index) => {
                    strCsv += `${index + 1},${data.search_word}\n`;
                });
            }).catch((error) => {
                throw error;
            });

            let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
            let blob = new Blob([bom, strCsv], { type: "text/csv" });
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "学部毎統計_" + facultySelectComboValue.dispName + "_" + Utils.ConvStrDateTime2Date(exportDate, 'YYYYMMDDhhmmss') + ".csv";
            link.click();

        } catch (error) {
            console.log(error);
            alert("エクスポートできませんでした。");
        }
        setIsFacultyExporting(false);
    }

    return (
        <div className="compStatisticWhole">
            <div className="compStatisticWholeTop">
                <div className="compStatisticWholeTopLeft">
                    <div className="areaSearchDuration">{searchDuration()}</div>
                    <div className="areaAccessCount">{accessCount}</div>
                    <button className="buttonExportWhole" disabled={isExporting} onClick={(e) => handleClick_ExportWhole(e)}>エクスポート</button>
                </div>
                <div className="compStatisticWholeTopCenter">
                    <div className="areaRanking">{viewStudentRanking}</div>
                </div>
                {userInfo.user_id == 0 &&
                    <div className="compStatisticWholeTopRight">
                        <div className="areaRanking">{viewTeacherRanking}</div>
                    </div>
                }
            </div>
            <div className="areaLabelStatisticsTitle"><div className="labelStatisticsTitle">学部毎データ</div></div>
            <div className="compStatisticWholeBottom">
                <div className="compStatisticWholeBottomLeft">
                    <div className="areaFacultySelect">{FacultySelect()}</div>
                    <div className="areaFacultyAccessCount">{facultyAccessCount}</div>
                    <button className="buttonExportFaculty" disabled={isFacultyExporting} onClick={(e) => handleClick_ExportFaculty(e)}>エクスポート</button>
                </div>
                <div className="compStatisticWholeBottomCenter">
                    <div className="areaRanking">{facultyViewRanking}</div>
                </div>
                <div className="compStatisticWholeBottomRight">
                    <div className="areaRanking">{facultySearchRanking}</div>
                </div>
            </div>
        </div>
    )
}