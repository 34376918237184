import * as Encryptor from "../utils/Encryptor"
import * as Utils from "../utils/Utils"
import * as RestBase from "../utils/RestBase"
import * as Defines from "../common/defines"
import Config from "../config"

const keySignin = "DaAlps_Signin"

export function setUsetInfo(_userInfo) {
    const obj = JSON.stringify(_userInfo)
    sessionStorage.setItem(keySignin, Encryptor.encrypt(obj));
}

export function getUsetInfo() {
    const obj = sessionStorage.getItem(keySignin);
    const userInfo = JSON.parse(Encryptor.decrypt(obj));
    return userInfo;
}

export function isFavoriteAsset(asset) {
    const userInfo = getUsetInfo();

    // How get asset in user?

    const isFavorite = getFavoriteAssetIds().includes(asset.asset_id);
    return isFavorite;
}

export function getFavoriteAssetIds() {
    const userInfo = getUsetInfo();
    const condRestSearch = {
        favorite_user_id: userInfo.user_id,
        file_format_ids: [
            Defines.ASSET_FILE_FORMAT_ID.MOVIE
        ],
        is_delivered: true,
        children_table: true,
        viewable_user_id: userInfo.permission_id == Defines.USER_PERMISSION_ID.STUDENT ? userInfo.user_id : 0,
    }
    return RestBase.POST(
        Config.DaAlpsRestServer + "assets/search",
        condRestSearch
    ).then(datas => {
        return (Utils.isEmpty(datas) ? [] : datas.assets.map(asset => asset.asset_id));
    })
}

export function setFavoriteAssetIds(favorite_asset_ids) {
    const userInfo = getUsetInfo();
    userInfo.favorite_asset_ids = favorite_asset_ids;
    setUsetInfo(userInfo);
}

export async function getHistoryAssetIds() {
    const userInfo = getUsetInfo();
    const condRestSearch = {
        viewed_user_id: userInfo.user_id,
        file_format_ids: [
            Defines.ASSET_FILE_FORMAT_ID.MOVIE
        ],
        is_delivered: true,
        children_table: true,
        viewable_user_id: userInfo.user_id,
    }
    return RestBase.POST(
        Config.DaAlpsRestServer + "assets/search",
        condRestSearch
    ).then(datas => {
        return (Utils.isEmpty(datas) ? [] : datas.assets.map(asset => asset.asset_id));
    })
}

export function setHistoryAssetIds(history_asset_ids) {
    const userInfo = getUsetInfo();
    userInfo.history_asset_ids = history_asset_ids;
    setUsetInfo(userInfo);
}
