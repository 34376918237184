import React, { useContext, useEffect, useState, useMemo } from "react";
import "./compMovieWatch.css";

import * as Defines from "../common/defines"
import * as UserInfo from "../common/userInfo"
import * as RestBase from "../utils/RestBase"
import Config from "../config"
import CompFileList from "./compFileList"
import CompFavoriteAsset from "./compFavoriteAsset"
import CompHistoryAsset from "./compHistoryAsset"
import CompRelatedAsset from "./compRelatedAsset"
import CompSpeechAnalyze from "./compSpeechAnalyze"
import CompWatchInfo from "./compWatchInfo"

export const SelectMovieWatchTabContext = React.createContext();

export default function () {
    const [favoriteAssetIds, setFavoriteAssetIds] = useState([]);
    const [historyAssetIds, setHistoryAssetIds] = useState([]);

    const selectTabItems = [
        Defines.SELECT_TAB.MOVIE_WATCH.MOVIE_SEARCH,
        Defines.SELECT_TAB.MOVIE_WATCH.FAVORITE_LIST,
        Defines.SELECT_TAB.MOVIE_WATCH.HISTORY,
        Defines.SELECT_TAB.MOVIE_WATCH.RELATED_MOVIE,
        Defines.SELECT_TAB.MOVIE_WATCH.DISP_SPEECH_TO_TEXT,
        Defines.SELECT_TAB.MOVIE_WATCH.WATCH_INFO,
    ];

    var selectedTabIndex = 0;
    const [selectedTabItemsValue, setSelectedTabItemsValue] = useState(selectTabItems[selectedTabIndex].id);
    const handleChange_SelectedTabItems = (e) => {
        changeSelectedTabItem(e.target.value);
    };
    const changeSelectedTabItem = ((tab_id) => {
        setSelectedTabItemsValue(tab_id);
        selectedTabIndex = selectTabItems.findIndex((item) => item.id == tab_id);
    })

    useEffect(() => {
        if (UserInfo.getUsetInfo().user_id == 0) return;
        UserInfo.setFavoriteAssetIds(favoriteAssetIds);
    }, [favoriteAssetIds]);

    useEffect(() => {
        if (UserInfo.getUsetInfo().user_id == 0) return;
        UserInfo.setHistoryAssetIds(historyAssetIds);
    }, [historyAssetIds]);

    var prevKeyupTimestamp = 0;
    function handleKeyup(e) {
        { // 重複発火に対応（かっこ悪い）
            const limitDurationKeyUpMsec = 10;
            if ((prevKeyupTimestamp + limitDurationKeyUpMsec) > e.timeStamp) return;
            prevKeyupTimestamp = e.timeStamp
        }

        if (e.target.tagName != 'BODY') return;

        if (e.key == "ArrowLeft") {
            var nextTabIndex = selectedTabIndex - 1;
            if (nextTabIndex < 0) nextTabIndex = selectTabItems.length - 1;
            if (nextTabIndex >= selectTabItems.length) nextTabIndex = 0;

            if (0 <= nextTabIndex && nextTabIndex < selectTabItems.length) {
                changeSelectedTabItem(selectTabItems[nextTabIndex].id);
            }
        } else if (e.key == "ArrowUp") {
            // nop
        } else if (e.key == "ArrowRight") {
            var nextTabIndex = selectedTabIndex + 1;
            if (nextTabIndex < 0) nextTabIndex = selectTabItems.length - 1;
            if (nextTabIndex >= selectTabItems.length) nextTabIndex = 0;

            if (0 <= nextTabIndex && nextTabIndex < selectTabItems.length) {
                changeSelectedTabItem(selectTabItems[nextTabIndex].id);
            }
        } else if (e.key == "ArrowDown") {
            // nop
        }
    }
    useEffect(() => {
        if (Defines.ENABLE_HANDLE_KEYUP_TAB_MOVE == true) {
            document.removeEventListener('keyup', handleKeyup);
            document.addEventListener('keyup', handleKeyup);
        } else {
            document.removeEventListener('keyup', handleKeyup);
        }
        (async () => {
            await GetHistoryAssets();
            await GetFavoriteAssets();
        })();
    }, []);

    const styleShow = { display: "contents" }
    const styleHidden = { display: "none" }

    const isOnlyDeliverd = true;

    const GetHistoryAssets = async () => {
        const condRestSearch = {
            viewed_user_id: UserInfo.getUsetInfo().user_id,
            file_format_ids: [
                Defines.ASSET_FILE_FORMAT_ID.MOVIE
            ],
            is_delivered: true,
            children_table: true,
            viewable_user_id: UserInfo.getUsetInfo().user_id,
        }
        RestBase.POST(
            Config.DaAlpsRestServer + "assets/search",
            condRestSearch
        ).then(datas => {
            UserInfo.setHistoryAssetIds(datas.assets.map(data => data.asset_id));
            UserInfo.getHistoryAssetIds()
                .then(asset_ids => {
                    setHistoryAssetIds(asset_ids);
                })
        })
    }

    const GetFavoriteAssets = async () => {
        if (UserInfo.getUsetInfo().user_id == 0) return;
        const condRestSearch = {
            favorite_user_id: UserInfo.getUsetInfo().user_id,
            file_format_ids: [
                Defines.ASSET_FILE_FORMAT_ID.MOVIE
            ],
            is_delivered: true,
            children_table: true,
            viewable_user_id: UserInfo.getUsetInfo().user_id,
        }
        RestBase.POST(
            Config.DaAlpsRestServer + "assets/search",
            condRestSearch
        ).then(datas => {
            UserInfo.setFavoriteAssetIds(datas.assets.map(data => data.asset_id));
            UserInfo.getFavoriteAssetIds()
                .then(asset_ids => {
                    setFavoriteAssetIds(asset_ids);
                })
        })
    }

    return (
        <div className="compMovieWatch widthFull fromTop">
            <SelectMovieWatchTabContext.Provider value={[selectedTabItemsValue, setSelectedTabItemsValue]}>

                <div className="areaTab">
                    {selectTabItems.map((item) => {
                        return (
                            <span key={item.id}>
                                <input className="radioSelectTab" type="radio" name="selectedTab" value={item.id} id={item.id} onChange={(e) => handleChange_SelectedTabItems(e)} checked={item.id === selectedTabItemsValue} />
                                <label className="labelTab" htmlFor={item.id}>{item.dispName}</label>
                            </span>
                        )
                    })}
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MOVIE_WATCH.MOVIE_SEARCH.id) ? styleShow : styleHidden}>
                    <span className="areaFileList">
                        <CompFileList
                            isFromComponent={Defines.NAME_COMPONENT.MOVIE_WATCH}
                            isOnlyDeliverd={isOnlyDeliverd}
                            stateFileRestored={null}
                            stateHistoryAssetIds={[historyAssetIds, setHistoryAssetIds]}
                            stateFavoriteAssetIds={[favoriteAssetIds, setFavoriteAssetIds]}
                        />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MOVIE_WATCH.FAVORITE_LIST.id) ? styleShow : styleHidden}>
                    <span className="areaFavoriteList">
                        <CompFavoriteAsset
                            isOnlyDeliverd={isOnlyDeliverd}
                            stateFavoriteAssetIds={[favoriteAssetIds, setFavoriteAssetIds]}
                        />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MOVIE_WATCH.HISTORY.id) ? styleShow : styleHidden}>
                    <span className="areaFavoriteList">
                        <CompHistoryAsset
                            isOnlyDeliverd={isOnlyDeliverd}
                            stateHistoryAssetIds={[historyAssetIds, setHistoryAssetIds]}
                            stateFavoriteAssetIds={[favoriteAssetIds, setFavoriteAssetIds]}
                        />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MOVIE_WATCH.RELATED_MOVIE.id) ? styleShow : styleHidden}>
                    <span className="areaRelatedList">
                        <CompRelatedAsset
                            isOnlyDeliverd={isOnlyDeliverd}
                            stateHistoryAssetIds={[historyAssetIds, setHistoryAssetIds]}
                            stateFavoriteAssetIds={[favoriteAssetIds, setFavoriteAssetIds]}
                        />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MOVIE_WATCH.DISP_SPEECH_TO_TEXT.id) ? styleShow : styleHidden}>
                    <span className="areaSpeechAnalyze">
                        <CompSpeechAnalyze isDispButtonAnalyze={false} isDispButtonInsertTag={false} isDispRemainTime={false} isDispConfidence={false} isDispFixed={false} />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MOVIE_WATCH.WATCH_INFO.id) ? styleShow : styleHidden}>
                    <span className="areaWatchInfo">
                        <CompWatchInfo admin={false} />
                    </span>
                </div>

            </SelectMovieWatchTabContext.Provider>
        </div>
    )
}