import React, { useContext, useEffect, useState, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import "./compFileList.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as AssetUtil from "../utils/AssetUtils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as Home from "../pages/home"
import * as UserInfo from "../common/userInfo"
import CompSearchAndFilter from "./compSearchAndFilter"
import CompFileListItem from "./compFileListItem";

export default function (props) {
    const navigate = useNavigate();
    const userInfo = UserInfo.getUsetInfo();
    const [condFileSearchAndFilter, setCondFileSearchAndFilter] = useState({});
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
    const [fileRestored, setFileRestored] = (Utils.isEmpty(props.stateFileRestored) ? [[], null] : props.stateFileRestored)
    const FileDeleteCallback = (Utils.isEmpty(props.onFileDeletedCallback) ? () => { } : props.onFileDeletedCallback);
    const [analyzeSettings, setAnalyzeSettings] = useState({ cinnamon_enabled: false });
    const [isWordCloud, setIsWordCloud] = useState(false);

    const condRestSearch = useMemo(() => {
        // var category_ids = null;
        // if(Utils.isEmpty(condFileSearchAndFilter) != true) {
        //     category_ids = (condFileSearchAndFilter.faculties.length > 0) ? condFileSearchAndFilter.faculties : null;
        // }

        return (
            {
                asset_ids: null,
                children_table: true,
                children_table_meta: true,
                text: condFileSearchAndFilter.text,
                include_speech_analysis: condFileSearchAndFilter.include_speech_analysis,
                datetime: condFileSearchAndFilter.datetime,
                file_format_ids: [Defines.ASSET_FILE_FORMAT_ID.MOVIE],   // Movieのみ
                department_id: condFileSearchAndFilter.department_id,
                teacher_id: condFileSearchAndFilter.teacher_id,
                search_execute_user_id: userInfo.user_id,
                viewable_user_id: userInfo.permission_id == Defines.USER_PERMISSION_ID.STUDENT ? userInfo.user_id : 0,
            }
        )
    }, [condFileSearchAndFilter]);

    const [resultsValue, setResultsValue] = useState([]);

    useEffect(() => {
        (async () => {
            const resultSettings = await AssetUtil.AsyncGetAnalyzeSettings();
            setAnalyzeSettings(resultSettings);
        })();
    }, []);

    useEffect(() => {
        setResultsValue([]);

        const respRest = RestBase.POST(
            Config.DaAlpsRestServer + "assets/search"
            , condRestSearch
        ).then((data) => {
            var assets = data.assets.filter((item) => {
                if (!(props.isOnlyDeliverd == true && item.status.delivery != Defines.ASSET_STATUS.ASSET_STATUS_DONE)) {
                    return item;
                }
            })

            if (Utils.isEmpty(condFileSearchAndFilter.departments) != true) {
                assets = assets.filter((item) => {
                    const metaInfo = AssetUtil.GetMetaInfo(item, Defines.ASSET_META_ID.DEPARTMENT_ID.id);
                    if (condFileSearchAndFilter.departments.includes(metaInfo.value_text)) {
                        return item;
                    }
                })
            }

            setResultsValue(assets);
        });
    }, [condRestSearch]);

    useEffect(() => {
        if (Utils.isEmpty(setFileRestored)) return;
        RestBase.POST(
            Config.DaAlpsRestServer + "assets/search"
            , condRestSearch
        ).then((data) => {
            var assets = data.assets;

            setResultsValue(assets);
        });
    }, [fileRestored])

    const handleNewIngestClick = async (e) => {
        e.preventDefault();
        var popup = window.open("/upload_video/", "_blank"/*, "noopener noreferrer"*/); // for New tab
        // navigate("/upload_video/");
    }

    useEffect(() => {
        const index = resultsValue.findIndex(item => item.asset_id == selectAsset.asset_id);
        if (index >= 0) {
            resultsValue[index] = selectAsset;
        }
    }, [selectAsset])

    const isFromComponent = useMemo(() => {
        if (props.isFromComponent == Defines.NAME_COMPONENT.MANAGEMENT) return Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id;
        else if (props.isFromComponent == Defines.NAME_COMPONENT.MOVIE_WATCH) return Defines.SELECT_TAB.MOVIE_WATCH.MOVIE_SEARCH.id;
    }, []);

    const isCanDelete = useMemo(() => {
        if (props.isFromComponent == Defines.NAME_COMPONENT.MANAGEMENT) {
            return userInfo.permission_id == Defines.USER_PERMISSION_ID.ADMIN ? true : false;
        } else if (props.isFromComponent == Defines.NAME_COMPONENT.MOVIE_WATCH) return false;
    }, []);

    const isCanFavorite = useMemo(() => {
        if (userInfo.permission_id == Defines.USER_PERMISSION_ID.ADMIN) return false;
        if (props.isFromComponent == Defines.NAME_COMPONENT.MANAGEMENT) return false;
        else return true;
    }, []);

    return (
        <div className="compFileList gridFileList">
            <CompSearchAndFilter
                condFileSearchAndFilter={[condFileSearchAndFilter, setCondFileSearchAndFilter]}
                componentParent="fromFileList"
            />

            <div className="bodyFileListItem">
                {
                    resultsValue.map((item, index) => {
                        return (
                            <CompFileListItem
                                key={index}
                                isFromComponent={isFromComponent}
                                asset={item}
                                condFileSearchAndFilter={[condFileSearchAndFilter, setCondFileSearchAndFilter]}
                                isCanDelete={isCanDelete}
                                onFileDeletedCallback={FileDeleteCallback}
                                isCanFavorite={isCanFavorite}
                                isCanForceDelete={false}
                                stateHistoryAssetIds={props.stateHistoryAssetIds}
                                stateFavoriteAssetIds={props.stateFavoriteAssetIds}
                                analyzeSettings={analyzeSettings}
                                isWordCloud={isWordCloud}
                            />
                        )
                    })
                }
            </div>

            <div className="areaNewIngest widthFull">
                <table width="100%">
                    <tbody>
                        <tr>
                            {analyzeSettings.cinnamon_enabled &&
                                <td align="left">
                                    <input type="checkbox" name="thumbnailFromWordCloudOnOff" id="thumbnailFromWordCloudOnOff"
                                        checked={isWordCloud} onChange={() => { setIsWordCloud(!isWordCloud) }} />
                                    <label htmlFor="thumbnailFromWordCloudOnOff">サムネイルをワードクラウド表示に切替</label>
                                </td>
                            }
                            <td align="right">
                                {isFromComponent == Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id && userInfo.user_id == 0 &&
                                    <button className="buttonNewIngestFromFileList backgroundGrayGradiation" id="buttonNewIngest" onClick={(e) => handleNewIngestClick(e)}>
                                        <label className="cursorUnset" htmlFor="buttonNewIngest">
                                            <img className="sizeIconSmall" src="../images/upload.png" />
                                            新規取り込み
                                        </label>
                                    </button>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}