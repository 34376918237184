import React, { useContext, useEffect, useState, useMemo } from "react";
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from "chart.js"
import { Line, Pie } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { speechAnalyzeTimeOptions } from "../common/graphOptions";
import DatePicker from "react-datepicker";
import "./compStatisticMaintenance.css"
import Ranking from "./graphComponents/compRanking"
import Config from "../config"
import * as OtherSettings from "../utils/OtherSettings";
import * as RestBase from "../utils/RestBase";
import * as Utils from "../utils/Utils";


Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    ChartDataLabels,
    Legend
)

export default function (props) {
    const [listMoviePlayCountWorst, setListMoviePlayCountWorst] = useState([]);
    const [analyzeRatioSearchStartDate, setAnalyzeRatioSearchStartDate] = useState(new Date());
    const [analyzeRatioSearchEndDate, setAnalyzeRatioSearchEndDate] = useState(new Date());
    const [listSpeechAnalyzeRatioTeacher, setListSpeechAnalyzeRatioTeacher] = useState([]);
    const [listSpeechAnalyzeRatioRoom, setListSpeechAnalyzeRatioRoom] = useState([]);
    const [analyzeSpeechThreshold, setAnalyzeSpeechThreshold] = useState("");

    useEffect(() => {
        (async () => {
            // 年間再生数が少ない動画
            const listMPCW = await RestBase.GET(Config.DaAlpsRestServer + 'statistics/maintenance/assets_low_annual_view/10');
            if (listMPCW !== null) {
                setListMoviePlayCountWorst(listMPCW);
            }

            // 検索期間のデフォルト値の取得
            const listAcademicYear = await RestBase.GET(Config.DaAlpsRestServer + 'statistics/search_period');
            let startDate = new Date();
            let endDate = new Date();
            if (listAcademicYear !== null) {
                startDate = new Date(Date.parse(listAcademicYear[0].start_date));
                endDate = new Date(Date.parse(listAcademicYear[0].end_date));
                setAnalyzeRatioSearchStartDate(startDate);
                setAnalyzeRatioSearchEndDate(endDate);
            }

            // 音声解析 信頼度閾値の取得
            OtherSettings.GetSettingAnalyzeSpeechConfidenceThreshold()
                .then((threshold) => {
                    setAnalyzeSpeechThreshold(threshold.value);
                });
        })();
        SpeechAnalyzeTimeChart();
    }, [])

    useEffect(() => {
        (async () => {
            // urlの日付部分
            const urlDate = '/' + Utils.GetMetaDate(analyzeRatioSearchStartDate) + '/' + Utils.GetMetaDate(analyzeRatioSearchEndDate);

            // 教員毎の音声解析率
            const listSART = await RestBase.GET(Config.DaAlpsRestServer + 'statistics/maintenance/teacher/' + urlDate);
            if (listSART !== null) {
                setListSpeechAnalyzeRatioTeacher(listSART);
            }
            // 教室ごとの音声解析率
            const listSARR = await RestBase.GET(Config.DaAlpsRestServer + 'statistics/maintenance/room/' + urlDate);
            if (listSART !== null) {
                setListSpeechAnalyzeRatioRoom(listSARR);
            }
        })();
    }, [analyzeRatioSearchStartDate, analyzeRatioSearchEndDate]);

    const [speechAnalyzeTimeChart, setSpeechAnalyzeTimeChart] = useState(<div />);
    const SpeechAnalyzeTimeChart = () => {
        RestBase.GET(
            Config.DaAlpsRestServer + "statistics/maintenance/ai_analyze_required_time"
        ).then(datas => {
            const options = { ...speechAnalyzeTimeOptions }
            const labels = datas.map(d => d.asset_duration);
            const data = datas.map(d => d.required_time);
            const SpeechAnalyzeTimeData = {
                labels: labels,
                datasets: [{
                    data: data,
                    fill: true,
                    borderColor: 'rgb(38, 119, 158)',
                    backgroundColor: 'rgba(38, 119, 158, 0.18)',
                    tension: 0,
                    order: true,
                    datalabels: {
                        font: {
                            size: 28,
                        },
                        align: "top",
                    }
                }]
            }
            setSpeechAnalyzeTimeChart(
                <div className="chartSpeechAnalyzeTime">
                    <Line data={SpeechAnalyzeTimeData} options={{ ...options, scales: { ...options.scales, y: { ...options.scales.y, max: Math.max(...data) + + (Math.max(Math.max(...data) / 10, 5)), min: 0 } } }} />
                </div>
            )
        })
    }

    const moviePlayCountWorst = () => {
        const rankData = listMoviePlayCountWorst.map((data, index) => {
            return {
                contents: [data.asset_name],
                count: data.view_count
            }
        })
        return (
            <Ranking datas={rankData} titleText="年間再生数が少ない動画" noCrown />
        )
    }

    const speechAnalyzeRatio_Teacher = () => {
        return (
            <div>
                <div className="areaTableSpeechAnalyzeRatio">
                    <table className="tableSpeechAnalyzeRatio">
                        <thead>
                            <tr className="tableSpeechAnalyzeRatioHeader">
                                <th width="100px">教員</th>
                                <th width="70px">音声解析率(信頼度)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSpeechAnalyzeRatioTeacher.map((data, index) => {
                                const conf = Number(data.confidence * 100);
                                return (
                                    <tr key={index} className="tableSpeechAnalyzeRatioBody" style={{ borderCollapse: "collapse" }}>
                                        <td>{data.name}</td>
                                        <td style={Utils.isEmpty(analyzeSpeechThreshold) ? {} : conf > Number(analyzeSpeechThreshold) ? {} : { color: "red" }}>
                                            {conf.toFixed(2)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    const speechAnalyzeRatio_Room = () => {
        return (
            <div>
                <div className="areaTableSpeechAnalyzeRatio">
                    <table className="tableSpeechAnalyzeRatio" width="50%">
                        <thead>
                            <tr className="tableSpeechAnalyzeRatioHeader" width="100%">
                                <th width="100px">教室</th>
                                <th width="70px">音声解析率(信頼度)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSpeechAnalyzeRatioRoom.map((data, index) => {
                                const conf = Number(data.confidence * 100);
                                return (
                                    <tr key={index} className="tableSpeechAnalyzeRatioBody" style={{ borderCollapse: "collapse" }}>
                                        <td>{data.name}</td>
                                        <td style={Utils.isEmpty(analyzeSpeechThreshold) ? {} : conf > Number(analyzeSpeechThreshold) ? {} : { color: "red" }}>
                                            {conf.toFixed(2)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    const handleChange_SelectedStartDateValue = (e) => {
        setAnalyzeRatioSearchStartDate(e);
    };
    const handleChange_SelectedFinishDateValue = (e) => {
        setAnalyzeRatioSearchEndDate(e);
    }

    return (
        <div className="compStatisticMaintenance">
            <div className="compStatisticMaintenanceTop">
                <div className="areaCompStatisticMaintenanceSpeechAnalyzeTimeChart">
                    {speechAnalyzeTimeChart}
                </div>
                <div className="areaCompStatisticMaintenanceMoviePlayCountWorst">
                    {moviePlayCountWorst()}
                </div>
            </div>
            <div className="compStatisticMaintenanceBottom">
                <div className="compStatisticMaintenanceSearchDate">
                    <span>音声解析率の検索期間： </span>
                    <DatePicker className="datePicker"
                        selected={analyzeRatioSearchStartDate}
                        onChange={(e) => handleChange_SelectedStartDateValue(e)}
                        dateFormat="yyyy/MM/dd"
                        locale="ja"
                        placeholderText="Start Date"
                    />
                    <span style={{ margin: "0px 5px 0px 5px" }}>～</span>
                    <DatePicker className="datePicker"
                        selected={analyzeRatioSearchEndDate}
                        onChange={(e) => handleChange_SelectedFinishDateValue(e)}
                        dateFormat="yyyy/MM/dd"
                        locale="ja"
                        placeholderText="End Date"
                    />
                </div>
                <div className="areaSpeechAnalyzeRatioLabelTitle" width="50%"><label className="labelStatisticsTitle">教員ごとの音声解析率</label></div>
                <div className="areaSpeechAnalyzeRatioLabelTitle" width="50%"><label className="labelStatisticsTitle">教室ごとの音声解析率</label></div>
                <div className="speechAnalyzeRatio_Teacher">
                    {speechAnalyzeRatio_Teacher()}
                </div>
                <div className="speechAnalyzeRatio_Room">
                    {speechAnalyzeRatio_Room()}
                </div>
            </div>
        </div>
    )
}