import React, { useContext, useEffect, useState, useMemo } from "react";
import "./compAssetMeta.css";

import * as Defines from "../common/defines"
import * as UserInfo from "../common/userInfo.js"
import * as Utils from "../utils/Utils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as AssetUtils from "../utils/AssetUtils"
import * as Home from "../pages/home"

export default function (props) {
    // const selectAsset = props.asset;
    const userInfo = UserInfo.getUsetInfo();
    const isAdmin = userInfo.permission_id == Defines.USER_PERMISSION_ID.ADMIN;
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
    const isCanChange = (Utils.isEmpty(props.isCanChange) == true) ? false : props.isCanChange;

    const [affiliationList, setAffiliationList] = useState([]);
    const [teacherList, setTeacherList] = useState([]);

    const [referencesAssets, setReferencesAssets] = useState([]);
    useEffect(() => {
        setReferencesAssets([]);

        if (Utils.isEmpty(selectAsset) != true) {
            const references_asset_ids = selectAsset.references.map(item => item["reference_asset_id"]);
            RestBase.POST(
                Config.DaAlpsRestServer + "assets/search"
                , {
                    children_table: true,
                    asset_ids: references_asset_ids,
                }
            ).then((data) => {
                const filterd = data.assets.filter((item) => {
                    if (
                        item.status.delivery == Defines.ASSET_STATUS.ASSET_STATUS_DONE ||
                        (isAdmin && item.status.delivery == Defines.ASSET_STATUS.ASSET_STATUS_NONE)
                    ) {
                        return item;
                    }
                })
                setReferencesAssets(filterd);
            });
        }
    }, [selectAsset, props.isOnlyDeliverd]);

    const classNameTableMetaBody_NotChanged = "tableMetaBody tableMetaBody_NotChanged";
    const classNameTableMetaBody = "tableMetaBody";

    const GetMetaValue = ((_asset, meta_id) => {
        const findMeta = AssetUtils.GetMetaInfo(_asset, meta_id);
        if (Utils.isEmpty(findMeta) == true) return "";

        return (findMeta.value_text != null) ? findMeta.value_text : (findMeta.value_date != null) ? Utils.ConvStrDateTime2Date(findMeta.value_date, "YYYY/MM/DD") : "";
    })

    const [metaLectureName, setMetaLectureName] = useState("");
    const [metaYear, setMetaYear] = useState("");
    const [metaAffiliation, setMetaAffiliation] = useState("");
    const [metaTeacher, setMetaTeacher] = useState("");
    const [metaDayAttend, setMetaDayAttend] = useState("");
    const [metaNumPeriod, setMetaNumPeriod] = useState("");
    const [metaDepartment, setMetaDepartment] = useState("");
    const [metaTeacherId, setMetaTeacherId] = useState("");
    const [metaRoomName, setMetaRoomName] = useState("");

    useEffect(() => {
        const affiliationName = GetMetaValue(selectAsset, Defines.ASSET_META_ID.AFFILIATION.id);
        const departmentId = GetMetaValue(selectAsset, Defines.ASSET_META_ID.DEPARTMENT_ID.id);
        const teacherName = GetMetaValue(selectAsset, Defines.ASSET_META_ID.TEACHER.id);
        const teacherId = GetMetaValue(selectAsset, Defines.ASSET_META_ID.TEACHER_ID.id);

        setMetaLectureName(GetMetaValue(selectAsset, Defines.ASSET_META_ID.LECTURE_NAME.id));
        setMetaYear(GetMetaValue(selectAsset, Defines.ASSET_META_ID.YEAR.id));
        setMetaAffiliation(affiliationName);
        setMetaTeacher(teacherName);
        setMetaDayAttend(GetMetaValue(selectAsset, Defines.ASSET_META_ID.DAY_ATTENDED.id));
        setMetaNumPeriod(GetMetaValue(selectAsset, Defines.ASSET_META_ID.NUM_PERIOD.id));
        setMetaDepartment(departmentId);
        setMetaTeacherId(teacherId);
        setMetaRoomName(GetMetaValue(selectAsset, Defines.ASSET_META_ID.ROOM_NAME.id));

        // affiliationlist
        setAffiliationList([]);
        RestBase.GET(
            Config.DaAlpsRestServer + "Department"
        ).then((datas) => {
            const tmpAffiliationList = [{ fomsrec_department_id: departmentId, name: affiliationName }];
            const index = datas.findIndex(item => (item.fomsrec_department_id == tmpAffiliationList[0].fomsrec_department_id && item.name == tmpAffiliationList[0].name));
            if (index >= 0) {
                datas.splice(index, 1);
            }
            datas.map(data => { tmpAffiliationList.push({ fomsrec_department_id: data.fomsrec_department_id, name: data.name }) });
            setAffiliationList(tmpAffiliationList);
        });

        // teacherList
        setTeacherList([]);
        RestBase.GET(
            Config.DaAlpsRestServer + "user?is_teacher=true"
        ).then((datas) => {
            const tmpTeacherList = [{ fomsrec_user_id: teacherId, name: teacherName }];
            const index = datas.findIndex(item => (item.fomsrec_user_id == tmpTeacherList[0].fomsrec_user_id && item.name == tmpTeacherList[0].name));
            if (index >= 0) {
                datas.splice(index, 1);
            }
            datas.map(data => { tmpTeacherList.push({ fomsrec_user_id: data.fomsrec_user_id, name: data.name }) });
            setTeacherList(tmpTeacherList);
        });
    }, [selectAsset]);

    const GetMetaTagValue = ((asset, meta_dict, [value, setValue]) => {
        const _GetTagMetaData = ((id, value) => {
            if (isCanChange == true) {
                return (
                    <input className="inputMetaValue widthFull" type="text" id={id} name={id} value={value} onChange={(e) => setValue(e.target.value)} />
                )
            } else {
                return value;
            }
        })

        return _GetTagMetaData(meta_dict.id, value);
    })

    const GetMetaSelectAffiliationTagValue = (asset) => {
        if (Utils.isEmpty(asset)) return;

        if (isCanChange == true) {
            return (
                <select className="selectMetaValue widthFull" onChange={(e) => {
                    setMetaAffiliation(affiliationList[e.target.selectedIndex].name);
                    setMetaDepartment(affiliationList[e.target.selectedIndex].fomsrec_department_id);
                }}>
                    {affiliationList.map((val, index) => {
                        return (
                            <option key={index} id={Defines.ASSET_META_ID.AFFILIATION} name={Defines.ASSET_META_ID.AFFILIATION} value={val.name}>
                                {val.name}
                            </option>
                        )
                    })}
                </select>
            );
        } else {
            return affiliationList[0] ? affiliationList[0].name : "";
        }
    }

    const GetMetaSelectTeacherTagValue = (asset) => {
        if (Utils.isEmpty(asset)) return;

        if (isCanChange == true) {
            return (
                <select className="selectMetaValue widthFull" onChange={(e) => {
                    setMetaTeacher(teacherList[e.target.selectedIndex].name);
                    setMetaTeacherId(teacherList[e.target.selectedIndex].fomsrec_user_id);
                }}>
                    {teacherList.map((val, index) => {
                        return (
                            <option key={index} id={Defines.ASSET_META_ID.TEACHER} name={Defines.ASSET_META_ID.TEACHER} value={val.name}>
                                {val.name}
                            </option>
                        )
                    })}
                </select>
            );
        } else {
            return teacherList[0] ? teacherList[0].name : "";
        }
    }

    const handleClick_MetaSave = (e) => {
        const _findMeta = ((_array, target_id) => {
            return _array.find(item => item.meta_id == target_id);
        })

        function _PushModifyMeta(target_array, meta_id, value_text = null, value_date = null) {
            // if (Utils.isEmpty(value_text) == true && Utils.isEmpty(value_date) == true) return;
            target_array.push(
                {
                    asset_id: selectAsset.asset_id,
                    meta_id: meta_id,
                    value_text: value_text,
                    value_date: value_date,
                    update_datetime: selectAsset.update_datetime,
                }
            )

            return target_array;
        }

        const deleteMeta = () => {
            const ret = Object.keys(Defines.ASSET_META_ID).map(meta_name => {
                return Defines.ASSET_META_ID[meta_name].id;
            })
            return ret;
        }

        var modifyMeta = [];
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.LECTURE_NAME.id, metaLectureName, null);
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.YEAR.id, metaYear, null);
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.AFFILIATION.id, metaAffiliation, null);
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.TEACHER.id, metaTeacher, null);
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.DAY_ATTENDED.id, null, metaDayAttend);
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.NUM_PERIOD.id, metaNumPeriod, null);
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.DEPARTMENT_ID.id, metaDepartment, null);
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.TEACHER_ID.id, metaTeacherId, null);
        _PushModifyMeta(modifyMeta, Defines.ASSET_META_ID.ROOM_NAME.id, metaRoomName, null);
        RestBase.PUT(
            Config.DaAlpsRestServer + "assets/meta"
            , modifyMeta
        ).then((data) => {
            RestBase.GET(
                Config.DaAlpsRestServer + "assets/" + selectAsset.asset_id
            )
                .then((data) => {
                    setSelectAsset(data.asset);
                });
        });
    };

    const handleClick_ReferenceAsset = (asset) => {
        if (Utils.isEmpty(asset)) return;

        RestBase.GET(
            Config.DaAlpsRestServer + "assets/" + asset.asset_id
        )
            .then((data) => {
                setSelectAsset(data.asset);
            });
    }

    const handleClick_UploadReference = async () => {
        if (Utils.isEmpty(selectAsset)) return;
        const fh_list = await window.showOpenFilePicker();

        const file = await fh_list[0].getFile();

        const reader = new FileReader();
        reader.onload = (event) => {
            // アップロード
            RestBase.POST(
                Config.DaAlpsRestServer + "assets/related_document",
                {
                    asset_id: selectAsset.asset_id,
                    filename: file.name,
                    data: event.target.result
                }
            )
                .then((data) => {
                    // アセットの再表示
                    RestBase.GET(Config.DaAlpsRestServer + "assets/" + selectAsset.asset_id)
                        .then((data) => { setSelectAsset(data.asset); });
                });
        }
        reader.readAsDataURL(file)
    }

    const handleClick_DeleteReference = (asset, referenceAsset) => {
        if (!window.confirm("関連資料から 「" + referenceAsset.asset_name + "」 の削除を行いますか？")) {
            return;
        }
        if (Utils.isEmpty(asset) || Utils.isEmpty(referenceAsset)) return;
        const body = {
            asset_id: asset.asset_id,
            reference_asset_id: referenceAsset.asset_id
        }
        RestBase.DELETE(
            Config.DaAlpsRestServer + "assets/reference",
            body
        ).then((result) => {
            const filterd = referencesAssets.filter((item) => {
                if (item.asset_id == referenceAsset.asset_id) return;
                if (!(props.isOnlyDeliverd == true && item.status.delivery != Defines.ASSET_STATUS.ASSET_STATUS_DONE)) {
                    return item;
                }
            })
            setReferencesAssets(filterd);
        });
    }


    const [bodyReferences, setBodyReferences] = useState();
    useMemo(() => {
        setBodyReferences(
            referencesAssets.map((item) => {
                switch (item.file_format_id) {
                    case Defines.ASSET_FILE_FORMAT_ID.MOVIE:
                        return (
                            <div key={item.asset_id}>
                                {props.isUploadEnabled && isAdmin &&
                                    <button className="buttonDeleteReference backgroundGrayGradiation" onClick={() => handleClick_DeleteReference(selectAsset, item)}>x</button>
                                }
                                <a href="#" onClick={(e) => handleClick_ReferenceAsset(item)}>{Utils.GetOnlyFileName(item.asset_name)}</a>
                            </div>
                        )
                        break;
                    default:
                        return (
                            <div key={item.asset_id}>
                                {props.isUploadEnabled && isAdmin &&
                                    <button className="buttonDeleteReference backgroundGrayGradiation" onClick={() => handleClick_DeleteReference(selectAsset, item)}>x</button>
                                }
                                <a href={AssetUtils.GetFileWwwPath(item)} target="_blank">{Utils.GetOnlyFileName(item.asset_name)}</a>
                            </div>
                        )
                        break;
                }
            })
        )
    }, [referencesAssets])

    return (
        <div className="compAssetMeta">
            <div className="areaMetaLabelTitle"><label className="labelTitle">メタ情報</label></div>
            <table width="100%">
                <tbody>
                    <tr className={classNameTableMetaBody_NotChanged}>
                        <td className="labelKey">名前</td>
                        <td colSpan={2} className="labelValue">{
                            (Utils.isEmpty(selectAsset)) ? "" : selectAsset.asset_name
                        }</td>
                    </tr>
                    <tr className={classNameTableMetaBody_NotChanged}>
                        <td className="labelKey">サイズ</td>
                        <td colSpan={2} className="labelValue">{
                            (Utils.isEmpty(selectAsset)) ? "" : Utils.fileSizeUnit(selectAsset.asset_file_byte)
                        }</td>
                    </tr>
                    <tr className={classNameTableMetaBody_NotChanged}>
                        <td className="labelKey">登録日時</td>
                        <td colSpan={2} className="labelValue">{
                            (Utils.isEmpty(selectAsset)) ? "" : selectAsset.update_datetime
                        }</td>
                    </tr>
                    <tr className={classNameTableMetaBody_NotChanged}>
                        <td className="labelKey">デュレーション</td>
                        <td colSpan={2} className="labelValue">{
                            (Utils.isEmpty(selectAsset)) ? "" : selectAsset.property.movie.duration
                        }</td>
                    </tr>
                    <tr className={classNameTableMetaBody}>
                        <td className="labelKey">{Defines.ASSET_META_ID.LECTURE_NAME.dispName}</td>
                        <td colSpan={2} className="labelValue">
                            {
                                GetMetaTagValue(selectAsset
                                    , Defines.ASSET_META_ID.LECTURE_NAME
                                    , [metaLectureName, setMetaLectureName]
                                )
                            }
                        </td>
                    </tr>
                    <tr className={classNameTableMetaBody}>
                        <td className="labelKey">{Defines.ASSET_META_ID.YEAR.dispName}</td>
                        <td colSpan={2} className="labelValue">
                            {
                                GetMetaTagValue(
                                    selectAsset
                                    , Defines.ASSET_META_ID.YEAR
                                    , [metaYear, setMetaYear]
                                )
                            }
                        </td>
                    </tr>
                    <tr className={classNameTableMetaBody}>
                        <td className="labelKey">{Defines.ASSET_META_ID.AFFILIATION.dispName}</td>
                        <td colSpan={2} className="labelValue">
                            {GetMetaSelectAffiliationTagValue(selectAsset)}
                        </td>
                    </tr>
                    <tr className={classNameTableMetaBody}>
                        <td className="labelKey">{Defines.ASSET_META_ID.TEACHER.dispName}</td>
                        <td className="labelValue">
                            {GetMetaSelectTeacherTagValue(selectAsset)}
                        </td>
                    </tr>
                    <tr className={classNameTableMetaBody}>
                        <td className="labelKey">{Defines.ASSET_META_ID.DAY_ATTENDED.dispName}</td>
                        <td colSpan={2} className="labelValue">
                            {
                                GetMetaTagValue(
                                    selectAsset
                                    , Defines.ASSET_META_ID.DAY_ATTENDED
                                    , [metaDayAttend, setMetaDayAttend]
                                )
                            }
                        </td>
                    </tr>
                    <tr className={classNameTableMetaBody}>
                        <td className="labelKey">{Defines.ASSET_META_ID.NUM_PERIOD.dispName}</td>
                        <td colSpan={2} className="labelValue">
                            {
                                GetMetaTagValue(
                                    selectAsset
                                    , Defines.ASSET_META_ID.NUM_PERIOD
                                    , [metaNumPeriod, setMetaNumPeriod]
                                )
                            }
                        </td>
                    </tr>
                    {isAdmin &&
                        <tr className={classNameTableMetaBody_NotChanged}>
                            <td className="labelKey">{Defines.ASSET_META_ID.DEPARTMENT_ID.dispName}</td>
                            <td colSpan={2} className="labelValue">
                                {metaDepartment ? metaDepartment : ""}
                            </td>
                        </tr>
                    }
                    {isAdmin &&
                        <tr className={classNameTableMetaBody_NotChanged}>
                            <td className="labelKey">{Defines.ASSET_META_ID.TEACHER_ID.dispName}</td>
                            <td colSpan={2} className="labelValue">
                                {metaTeacherId ? metaTeacherId : ""}
                            </td>
                        </tr>
                    }
                    <tr className={classNameTableMetaBody}>
                        <td className="labelKey">{Defines.ASSET_META_ID.ROOM_NAME.dispName}</td>
                        <td colSpan={2} className="labelValue">
                            {
                                GetMetaTagValue(
                                    selectAsset
                                    , Defines.ASSET_META_ID.ROOM_NAME
                                    , [metaRoomName, setMetaRoomName]
                                )
                            }
                        </td>
                    </tr>
                    <tr className={classNameTableMetaBody_NotChanged} style={{ borderStyle: "none" }}>
                        <td className="labelKey">関連資料</td>
                        <td className="labelValue" style={{ border: "none" }}>{
                            bodyReferences
                        }</td>
                        {props.isUploadEnabled && isAdmin &&
                            <td className="labelValue" style={{ textAlign: "right", border: "none", width: "86px" }}><button className="buttonUploadReference backgroundGrayGradiation" onClick={(e) => handleClick_UploadReference(e)}><img className="sizeIconSmall" src="../images/upload.png" />取り込み</button></td>
                        }
                    </tr>
                </tbody>
            </table>
            {
                (isCanChange == true) &&
                <button className="buttonMetaSave backgroundGrayGradiation buttonPadding" onClick={(e) => handleClick_MetaSave(e)}>保存</button>
            }
        </div>
    )
}
