import Config from "../config"
import * as RestBase from '../utils/RestBase.js'

export async function GetTags(user_id) {
    const promise = await RestBase.GET(
        Config.DaAlpsRestServer + "ai_analyze_auto_regist_tags/" + user_id
    );
    return promise;
}

export async function RegistTags(user_id, aryTags) {
    const promise = await RestBase.POST(
            Config.DaAlpsRestServer + "ai_analyze_auto_regist_tags/" + user_id,
            aryTags
        ).then((response) =>  {
            if (response != null)
                alert("自動タグ設定を登録しました。");
            else
                alert("自動タグ設定を登録できませんでした。");
        });
    return promise;
}
