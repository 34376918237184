import React, { useContext, useEffect, useState, useMemo } from "react";
import "./compAssetComment.css";

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as AssetUtils from "../utils/AssetUtils"
import * as Home from "../pages/home"
import * as UserInfo from "../common/userInfo"

export default function (props) {
    const [selectLeft, setSelectLeft] = useContext(Home.SelectLeftContext);
    const userInfo = UserInfo.getUsetInfo();
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);

    const idAlreadyComment = "idAlreadyComment";
    const idTextNewComment = "idTextNewComment";

    useMemo(() => {
        const elm = document.getElementById(idTextNewComment)
        if (Utils.isEmpty(elm) != true) elm.value = "";
    }, [selectAsset]);

    const handleTextBodyClick = (e) => {
        const secFloat = AssetUtils.GetSecFloatFromTC(selectAsset, e.frame);
        Utils.SetVideoCurrentTime(secFloat);
    }

    async function GetAssetComments(asset) {
        if (Utils.isEmpty(asset) == true) return [];
        return RestBase.GET(
            Config.DaAlpsRestServer + `assets/comment/${asset.asset_id}`
        ).then(comments => {
            const aryComment = [];
            comments.map(cmt => {
                const cmtDisp = { ...cmt };
                if (cmt.parent_comment_id < 0) {
                    cmtDisp.tc = AssetUtils.GetHHMMSSFFFromTC(selectAsset, cmt.frame)
                } else {
                    cmtDisp.comment = "┗ " + cmtDisp.comment;
                    cmtDisp.tc = "-----";
                }
                aryComment.push(cmtDisp);
            });
            return aryComment;
        })
    }

    const [assetComments, setAssetComments] = useState([]);
    useEffect(() => {
        (async () => {
            setAssetComments(await GetAssetComments(selectAsset));
        })();
    }, [selectAsset]);

    const handleClick_NewComment = ((e) => {
        if (Utils.isEmpty(selectAsset)) return;

        // 返信の確認
        let comment = document.getElementById(idTextNewComment).value;
        let parentCommentId = -1;
        const replyCmt = /^>>([0-9]+)\s*(.*)/.exec(comment);
        if (replyCmt) {
            const parentCmt = assetComments.find(cmt => cmt.comment_id == replyCmt[1]);
            if (parentCmt) {
                if (parentCmt.parent_comment_id < 0) {
                    parentCommentId = replyCmt[1];
                    comment = replyCmt[2];
                } else {
                    alert(`返信コメントに、さらに返信することはできません。(${replyCmt[1]})`);
                    return;
                }
            } else {
                alert(`返信先のIDが不正です。(${replyCmt[1]})`);
                return;
            }
        }

        // 登録
        const body = {
            asset_id: selectAsset.asset_id,
            parent_comment_id: parentCommentId,
            comment: comment,
            frame: (parentCommentId >= 0 ? 0 : AssetUtils.GetNumFrameFromSecFloat(selectAsset, Utils.GetVideoCurrentTime())),
            user_id: userInfo.user_id,
        };
        RestBase.POST(
            Config.DaAlpsRestServer + "assets/comment"
            , body
        ).then((data) => {
            RestBase.GET(
                Config.DaAlpsRestServer + "assets/" + selectAsset.asset_id
            )
                .then((data) => {
                    setSelectAsset(data.asset);
                });
        }).catch((error) => {
            console.log(error);
            alert("コメントを登録できませんでした。");
        });
    })

    const handleClick_replyButton = (e, comment) => {
        let elm = document.getElementById(idTextNewComment);
        elm.value = `>>${comment.comment_id} `;
    }

    const replyButton = (comment) => {
        return (
            <button className="buttonReply backgroundGrayGradiation" disabled={comment.parent_comment_id >= 0}
                onClick={(e) => handleClick_replyButton(e, comment)}>💭</button>
        )
    };

    const handleClick_deleteButton = (e, comment) => {
        const isOk = window.confirm("コメント(" + comment.comment + ")の削除を行いますか？");
        if (isOk) {
            RestBase.DELETE(
                Config.DaAlpsRestServer + "assets/comment",
                {
                    comment_id: comment.comment_id,
                    asset_id: comment.asset_id
                }
            ).then(async () => {
                setAssetComments(await GetAssetComments(selectAsset));
            })
        }
    }
    const deleteButton = (comment) => {
        return (
            <button className="buttonReply backgroundGrayGradiation"
                onClick={(e) => handleClick_deleteButton(e, comment)}>×</button>
        )
    }

    return (
        <div className="compAssetComment">
            <div className="areaComment">
                <div className="areaCommentLabelTitle"><label className="labelTitle">コメント一覧</label></div>

                <div className="areaTableAllComment">
                    <table className="tableAllComment" width="100%">
                        <thead>
                            <tr className="tableAllCommentHeader">
                                <th width="7%">番号</th>
                                <th width="20%">学籍番号</th>
                                <th>コメント</th>
                                <th width="10%">TC</th>
                                <th width="10%">{selectLeft == Defines.SELECCT_LEFT.MANAGEMENT.id ? "削除" : "返信"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {assetComments.map((item, index) => {
                                return (
                                    <tr key={index} className="tableAllCommentBody" id={idAlreadyComment + item.frame}
                                        onClick={(e) => handleTextBodyClick(item)} comment={item}>
                                        <td>{item.comment_id}</td>
                                        <td>{item.gakuseki_no}</td>
                                        <td>{item.comment}</td>
                                        <td>{item.tc}</td>
                                        <td>{selectLeft == Defines.SELECCT_LEFT.MANAGEMENT.id ? deleteButton(item) : replyButton(item)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="areaNewComment">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <input className="inputNewComment" id={idTextNewComment} />
                            </td>
                            <td className="areaNewCommentButton">
                                <button className="buttonNewComment backgroundGrayGradiation buttonPadding"
                                    onClick={(e) => handleClick_NewComment(e)}>コメント投稿</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}