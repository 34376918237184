import { useContext, useEffect, useState } from "react";
import "./compTagInfo.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as Home from "../pages/home"
import * as AssetUtils from "../utils/AssetUtils"
import CompAssetTag from "../components/compAssetTag"
import * as UserInfo from "../common/userInfo"

export default function () {
    const userinfo = UserInfo.getUsetInfo();
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);

    const headers = ["TC", "種別", "TEXT", "削除"];

    const [tagTc, setTagTc] = useState("");
    const [tagText, setTagText] = useState("");
    const [tagErrorText, setTagErrorText] = useState("");

    useEffect(() => {
        setTagTc("");
        setTagText("");
    }, [selectAsset]);

    const handleTextBodyDoubleClick = (e) => {
        Utils.SetVideoCurrentTime(e.secFloat);
    }

    const handleClick_InsertNewTag = (e) => {
        const frame = AssetUtils.GetTcFromHHMMSSFFF(selectAsset, tagTc);
        if (frame < 0) return;

        const asset_all_frame = AssetUtils.GetTcFromHHMMSSFFF(selectAsset, selectAsset.property.movie.duration);
        if (frame > asset_all_frame) return;

        if (tagText.length > Defines.TAG_TEXT_MAX_LENGTH) {
            return;
        }

        const insertTag = [
            {
                tag: tagText,
                frame: frame,
                user_id: Defines.ASSET_TAG_KIND.MANUAL.id,
            }
        ]

        RestBase.POST(
            Config.DaAlpsRestServer + "assets/tag"
            , {
                asset_id: selectAsset.asset_id,
                tag: tagText,
                frame: frame,
                user_id: userinfo.user_id,
                flag_auto: false,
            }
        ).then((data) => {
            RestBase.GET(
                Config.DaAlpsRestServer + "assets/" + selectAsset.asset_id
            )
                .then((data) => {
                    setSelectAsset(data.asset);
                });
        });
    }

    const handleClick_TagDelete = (item) => {
        const isOk = window.confirm("タグ(" + item.tag + ")の削除を行いますか？");
        if (isOk) {
            RestBase.DELETE(
                Config.DaAlpsRestServer + "assets/tag",
                {
                    asset_id: item.asset_id,
                    tag: item.tag,
                    frame: item.frame
                }
            ).then(() => {
                RestBase.GET(
                    Config.DaAlpsRestServer + "assets/" + selectAsset.asset_id
                )
                    .then((data) => {
                        setSelectAsset(data.asset);
                    });
            })
        }
    }

    const handleClick_GetTCFromViewer = () => {
        const secFloat = Utils.GetVideoCurrentTime();
        const numframe = AssetUtils.GetNumFrameFromSecFloat(selectAsset, secFloat);
        const tc = AssetUtils.GetHHMMSSFFFromTC(selectAsset, numframe);
        setTagTc(tc);
    }

    const handleChange_TagTextChanged = (e) => {
        const text = e.target.value;
        if (text.length > Defines.TAG_TEXT_MAX_LENGTH) {
            setTagErrorText(`タグが長すぎます。最大${Defines.TAG_TEXT_MAX_LENGTH}文字です。`);
        } else {
            setTagErrorText("");
        }
        setTagText(text);
    }

    function GetTagKindName(tagInfo) {
        function _GetTagKind(tagInfo) {
            if (Utils.isEmpty(tagInfo) == true) return null;

            if (tagInfo.flag_auto) {
                return Defines.ASSET_TAG_KIND.AI_AUTO;
            } else {
                return Defines.ASSET_TAG_KIND.MANUAL;
            }

            return null;
        }

        const find = _GetTagKind(tagInfo);

        if (Utils.isEmpty(find) == true) return "";
        return find.dispName;
    }

    const resultAllTag = ((asset) => {
        if (Utils.isEmpty(asset) == true) return [];

        var results = [];

        Array.prototype.push.apply(results
            , asset.tags.map((item) => {
                const tagKindName = GetTagKindName(item);
                return {
                    asset_id: item.asset_id,
                    tc: AssetUtils.GetHHMMSSFFFromTC(asset, item.frame),
                    secFloat: AssetUtils.GetSecFloatFromTC(asset, item.frame),
                    frame: item.frame,
                    kind: tagKindName,
                    tag: item.tag,
                }
            })
        )

        return results;
    })(selectAsset);

    return (
        <div className="compTagInfo gridTagInfo">
            <div className="areaAllTag">
                <div className="areaLabelTitle" style={{ width: "100%" }}><label className="labelTitle">タグ情報</label></div>
                <div className="areaTableAllTag">
                    <table className="tableAllTag" width="100%">
                        <thead>
                            <tr className="tableAllTagHeader">
                                <th width="115px">{headers[0]}</th>
                                <th width="80px">{headers[1]}</th>
                                <th>{headers[2]}</th>
                                <th width="40px">{headers[3]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultAllTag.map((item, index) => {
                                return (
                                    <tr key={index} className="tableAllTagBody" onDoubleClick={(e) => handleTextBodyDoubleClick(item)}>
                                        <td>{item.tc}</td>
                                        <td>{item.kind}</td>
                                        <td>{item.tag}</td>
                                        <td style={{ textAlign: "center" }}><button className="backgroundGrayGradiation" onClick={() => handleClick_TagDelete(item)}>x</button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="areaTagSetting">
                <div className="areaTagSort">
                    <CompAssetTag asset={[selectAsset, setSelectAsset]} isDragEnabled={true} isDispAll={true} title="表示順変更" />
                </div>
                <div className="areaInsertNewTag">
                    <div className="areaLabelTitle"><label className="labelTitle">新規タグ登録</label></div>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td><label>TC</label></td>
                                <td>
                                    <input type="text" className="widthFull" id="inputTc" placeholder="hh:mm:ss:ff" value={tagTc} onChange={(e) => setTagTc(e.target.value)}></input>
                                </td>
                            </tr>
                            <tr>
                                <td><label>TEXT</label></td>
                                <td>
                                    <input type="text" className="widthFull" id="inputText" value={tagText} onChange={handleChange_TagTextChanged}></input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div width="100%" className="areaInsertTagErrorMsg">
                        <div className="TagErrorMsg">{tagErrorText}</div>
                    </div>
                    <button className="buttonInsertNewTag backgroundGrayGradiation buttonPadding" onClick={(e) => handleClick_InsertNewTag(e)}>登録</button>
                    <button className="buttonInsertNewTag backgroundGrayGradiation buttonPadding" onClick={(e) => handleClick_GetTCFromViewer(e)}>動画再生位置からTC反映</button>
                </div>
                <div />
            </div>
        </div>
    )
}