import React, { useContext, useEffect, useState, useMemo } from "react";
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
} from "chart.js"
import { Line, Pie } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { graphCommonOptions, viewStatusOptions } from "../common/graphOptions";
import * as UserInfo from "../common/userInfo"
import * as Defines from "../common/defines"
import * as RestBase from "../utils/RestBase"
import * as Utils from "../utils/Utils"
import * as AssetUtils from "../utils/AssetUtils"
import Ranking from "./graphComponents/compRanking"
import PlayAreaTable from "./graphComponents/compPlayAreaTable"
import Config from "../config"
import "./compStatisticUser.css"

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    ChartDataLabels,
    Legend
)

export default function (props) {
    const userInfo = UserInfo.getUsetInfo();
    const [userSelectCombo, setUserSelectCombo] = useState([])
    const [userSelectComboValue, setUserSelectComboValue] = useState(null)
    const handleChange_UserSelectComboValue = (e) => {
        setUserSelectComboValue(userSelectCombo[e.target.selectedIndex]);
    };
    useEffect(() => {
        GetUserSelectCombo();
        GetSearchDurationCombo();
    }, [])
    const GetUserSelectCombo = () => {
        let url = "user"
        RestBase.GET(
            Config.DaAlpsRestServer + url
        ).then(users => {
            let retVal = []
            if (userInfo.permission_id == Defines.USER_PERMISSION_ID.STUDENT) {
                retVal = users.filter(user => user.user_id == userInfo.user_id);
            } else if (userInfo.permission_id == Defines.USER_PERMISSION_ID.TEACHER) {
                retVal = users.filter(user => user.user_id == userInfo.user_id || !user.is_teacher);
            } else {
                retVal = users.map(user => user);
            }
            setUserSelectCombo(retVal);
            setUserSelectComboValue(retVal[0]);
        })
    }
    const UserSelect = () => {
        return (
            <div className="areaUserSelectCombo">
                <div className="areaLabelStatisticsTitle"><div className="labelStatisticsTitle"> ユーザー選択 </div></div>
                <select
                    onChange={(e) => handleChange_UserSelectComboValue(e)}
                    value={userSelectComboValue ? userSelectComboValue.user_id : ""}>
                    {
                        userSelectCombo.map((item) => {
                            return (
                                <option value={item.user_id} key={item.user_id}>{item.fomsrec_user_id} {item.name}</option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }
    useEffect(() => {
        if (Utils.isEmpty(userSelectComboValue)) return;
        RestBase.GET(
            Config.DaAlpsRestServer + `user/${userSelectComboValue.user_id}`
        ).then(user => {
            TableBasicInfo(user);
            CommentHistory(user);
        })
    }, [userSelectComboValue])

    const [selectedUserInfo, setSelectedUserInfo] = useState(null);  // エクスポート用
    const [tableBasicInfo, setTableBasicInfo] = useState(null);
    const TableBasicInfo = (user) => {
        if (Utils.isEmpty(user)) return;
        setSelectedUserInfo(user);  // エクスポート用にセット
        const datas = [
            {
                name: "分類",
                detail: user.is_teacher ? "教員" : "学生"
            },
            {
                name: "学部",
                detail: user.department_name
            },
            {
                name: "ID",
                detail: user.fomsrec_user_id
            }
        ]
        if (!user.is_teacher) {
            datas.push({
                name: "学籍番号",
                detail: user.gakuseki_no
            });
        }
        datas.push({
            name: "氏名",
            detail: user.name
        })
        setTableBasicInfo(
            <div>
                <div className="areaLabelStatisticsTitle"><label className="labelStatisticsTitle">基本情報</label></div>
                <table className="tableBasicInfo" width="50%">
                    <thead>
                        <tr className="tableBasicInfoHeader" width="100%">
                            <th width="50px">項目</th>
                            <th width="80px">内容</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas.map((data, index) => {
                            return (
                                <tr key={index} className="tableBasicInfoBody" style={{ borderCollapse: "collapse" }}>
                                    <td>{data.name}</td>
                                    <td>{data.detail}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    const [commentHistory, setCommentHistory] = useState(<div />)
    const CommentHistory = (user) => {
        const getComments = async () => {
            return await user.comments.map(async comment => {
                return RestBase.GET(
                    Config.DaAlpsRestServer + `assets/${comment.asset_id}`
                ).then(asset => {
                    return {
                        name: comment.asset_name,
                        comment: comment.comment,
                        tc: AssetUtils.GetHHMMSSFFFromTC(asset.asset, comment.frame)
                    }
                })
            })
        }
        getComments().then((comPromise) => {
            Promise.all(comPromise).then(comments => {
                setCommentHistory(
                    <div>
                        <div className="areaLabelStatisticsTitle"><label className="labelStatisticsTitle">コメント履歴</label></div>
                        <table className="tableCommentHistory" width="90%">
                            <thead>
                                <tr className="tableCommentHistoryHeader" width="100%">
                                    <th width="100px">講義名</th>
                                    <th width="160px">コメント</th>
                                    <th width="90px">TC</th>
                                </tr>
                            </thead>
                            <tbody>
                                {comments.map((comment, index) => {
                                    return (
                                        <tr key={index} className="tableCommentHistoryBody" style={{ borderCollapse: "collapse" }}>
                                            <td className="linkText">{comment.name}</td>
                                            <td>{comment.comment}</td>
                                            <td>{comment.tc}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                );
            })
        })
    }

    const [searchDurationCombo, setSearchDurationCombo] = useState([]);
    const [searchDurationComboValue, setSearchDurationComboValue] = useState(null)
    const handleChange_SearchDurationComboValue = (e) => {
        setSearchDurationComboValue(searchDurationCombo[e.target.selectedIndex]);
    };

    const GetSearchDurationCombo = () => {
        RestBase.GET(
            Config.DaAlpsRestServer + "statistics/search_period"
        ).then((periods) => {
            let formatPeriods = periods.map(period => {
                return ({
                    academic_year: period.academic_year,
                    end_date: new Date(period.end_date.slice(0, period.end_date.indexOf('T')).split('-')),
                    start_date: new Date(period.start_date.slice(0, period.start_date.indexOf('T')).split('-')),
                    title: period.title
                })
            })
            const today = new Date();
            const yesterday = new Date(new Date().setDate(today.getDate() - 1));
            let periodArr = [];
            let id = 1;
            periodArr.push({
                id: id++,
                dispName: "過去一週間",
                startDate: new Date(new Date().setDate(today.getDate() - 7)),
                endDate: new Date(yesterday)
            });
            periodArr.push({
                id: id++,
                dispName: "過去30日間",
                startDate: new Date(new Date().setDate(today.getDate() - 30)),
                endDate: new Date(yesterday)
            });
            formatPeriods.map(formatPeriod => {
                periodArr.push({
                    id: id++,
                    dispName: formatPeriod.title,
                    startDate: new Date(formatPeriod.start_date),
                    endDate: new Date(formatPeriod.end_date)
                })
            })
            setSearchDurationCombo(periodArr);
            setSearchDurationComboValue(periodArr[0]);
        })
    }

    const SearchDuration = () => {
        return (
            <div className="areaSearchDurationCombo">
                <div className="areaLabelStatisticsTitle"><div className="labelStatisticsTitle"> 検索期間 </div></div>
                <select
                    onChange={(e) => handleChange_SearchDurationComboValue(e)}
                    value={searchDurationComboValue ? searchDurationComboValue.id : ""}>
                    {
                        searchDurationCombo.map((item) => {
                            return (
                                <option value={item.id} key={item.id}>{item.dispName}</option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }
    useEffect(() => {
        if (Utils.isEmpty(userSelectComboValue)) return;
        if (userSelectComboValue.is_teacher) {
            TeacherViewCount();
            CompStatisticUserRightAreaTeacher();
        } else {
            makePlayAreaTable();
            PlayStatusPieChart();
            TagRanking();
        }
    }, [searchDurationComboValue, userSelectComboValue])

    const [searchedUserViewingData, setSearchedUserViewingData] = useState(null);  // エクスポート用
	const [searchText, setSearchText] = React.useState("");  // PlayAreaTable側でセットされる
    const [playAreaTable, setPlayAreaTable] = useState(<div />);
    const makePlayAreaTable = () => {
        if (Utils.isEmpty(searchDurationComboValue) || Utils.isEmpty(userSelectComboValue)) return;
        const strStartDate = Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD');
        const strEndDate = Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD');
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/student/part/${userSelectComboValue.user_id}/${strStartDate}/${strEndDate}/0`
        ).then((userViewingData) => {
            setSearchedUserViewingData(userViewingData);
            setPlayAreaTable(
                <PlayAreaTable data={userViewingData.map(data => { return { ...data, name: data.asset_name } })} h1text="講義名" c1TextClass="linkText"
                setSearchText={setSearchText} showSearchText dispTotal />
            );
        })
    }
    const [playStatusPieChart, setPlayStatusPieChart] = useState(<div />);
    const PlayStatusPieChart = () => {
        if (Utils.isEmpty(searchDurationComboValue) || Utils.isEmpty(userSelectComboValue)) return;
        const strStartDate = Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD');
        const strEndDate = Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD');
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/student/status/${userSelectComboValue.user_id}/${strStartDate}/${strEndDate}/0`
        ).then((data) => {
            const labels = ["視聴済みの動画", "視聴途中の動画", "未視聴の動画　"];
            const graphData = data.total == 0 ? [data.viewed, data.viewing, data.not_viewed].map(d => parseInt(d)) : [data.viewed, data.viewing, data.not_viewed].map(d => parseInt(d / data.total * 100));
            const options = { ...viewStatusOptions }
            const playStatusData = {
                labels: labels,
                datasets: [{
                    data: graphData,
                    backgroundColor: [
                        'rgba(38, 119, 158, 1)',
                        'rgba(38, 119, 158, 0.5)',
                        'rgb(222, 222, 222)'
                    ],
                    offset: 0,
                    datalabels: {
                        font: {
                            size: "18",
                        },
                        color: "rgba(0,0,0,1)",
                        padding: {
                            bottom: "10"
                        },
                        offset: 0,
                        formatter: function (value) {
                            return value + ' %';
                        }
                    }
                }]
            }
            setPlayStatusPieChart(
                <div className="chartPlayStatus">
                    <Pie data={playStatusData} options={options} />
                </div>
            )
        });
    }

    const [tagRanking, setTagRanking] = useState(<div />);
    const TagRanking = () => {
        if (Utils.isEmpty(userSelectComboValue) || Utils.isEmpty(searchDurationComboValue)) return;
        const strStartDate = Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYYMMDD');
        const strEndDate = Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYYMMDD');
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/student/search_word/${userSelectComboValue.user_id}/${strStartDate}/${strEndDate}/6`
        ).then((datas) => {
            const rankData = datas.map(data => {
                return {
                    contents: [data.search_word],
                }
            })
            setTagRanking(
                <Ranking datas={rankData} titleText="タグ検索ランキング" />
            )
        })
    }

	const getViewingTime = (total_viewing_time_sec) => {
		const hhmmss = Utils.ConvHHMMSSFromSec(total_viewing_time_sec);
		return `${('00' + hhmmss[0]).slice(-2)}:${('00' + hhmmss[1]).slice(-2)}:${('00' + hhmmss[2]).slice(-2)}`
	}

    const handleClick_Export = (e) => {
        if (Utils.isEmpty(selectedUserInfo)) return;

        const exportDate = new Date();
        let strCsv = "作成日時," + Utils.ConvStrDateTime2Date(exportDate, 'YYYY/MM/DD hh:mm:ss') + "\n" +
            "ユーザ名," + selectedUserInfo.name + "\n" +
            "ID," + selectedUserInfo.fomsrec_user_id+ "\n" +
            "学籍番号," + selectedUserInfo.gakuseki_no + "\n" +
            "学部," + selectedUserInfo.department_name+ "\n" +
            "検索期間," + searchDurationComboValue.dispName +
                 "," + Utils.ConvStrDateTime2Date(searchDurationComboValue.startDate, 'YYYY/MM/DD') +
                 "," + Utils.ConvStrDateTime2Date(searchDurationComboValue.endDate, 'YYYY/MM/DD') + "\n" +
            "検索条件," + searchText + "\n" +
            "\n";

        let totalSec = 0;
        strCsv += "視聴履歴一覧\n講義名,状況,総再生時間\n";
        searchedUserViewingData.forEach(data => {
            if (data.asset_name.indexOf(searchText) >= 0) {
                const viewedStatus = parseInt(data.viewed_ratio) == 0 ? "未視聴" : parseInt(data.viewed_ratio) == 100 ? "視聴済" : "視聴途中";
                strCsv += `${data.asset_name},${viewedStatus},${getViewingTime(data.total_viewing_time_sec)}\n`;
                totalSec += data.total_viewing_time_sec;
            }
        });
        strCsv += ",合計," + getViewingTime(totalSec) + "\n";

        let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
        let blob = new Blob([bom, strCsv], { type: "text/csv" });
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "視聴履歴_" + selectedUserInfo.fomsrec_user_id + "_" + Utils.ConvStrDateTime2Date(exportDate, 'YYYYMMDDhhmmss') + ".csv";
        link.click();
    }

    const [teacherViewCount, setTeacherViewVount] = useState(<div />)
    const TeacherViewCount = () => {
        if (Utils.isEmpty(searchDurationComboValue) || Utils.isEmpty(userSelectComboValue)) return;
        const strStartDate = Utils.ConvStrDateTime2Date(searchDurationCombo.slice(-1)[0].startDate, 'YYYYMMDD');
        const strEndDate = Utils.ConvStrDateTime2Date(new Date(), 'YYYYMMDD');
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/teacher/total_views/${userSelectComboValue.user_id}/${strStartDate}/${strEndDate}/0`
        ).then((data) => {
            setTeacherViewVount(
                <div className="areaTeacherViewCount">
                    <div className="teacherViewCount">
                        <div className="areaLabelStatisticsTitle"><div className="labelStatisticsTitle">全動画の総再生回数</div></div>
                        <div className="teacherViewCountLine">
                            <span>{data.viewed_count}回</span>
                        </div>
                        <div className="teacherViewCountLine">
                            <span>{data.user_count}人</span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const compStatisticUserRightAreaStudent = () => {
        return (
            <div className="compStatisticUserRightAreaStudent">
                <div className="areaLabelStatisticsUserTitle">
                    <div className="labelStatisticsTitle">視聴情報</div>
                    <div className="compStatisticUserRightAreaNotify">※視聴履歴/再生箇所の検索期間は講義日が対象</div>
                </div>
                <div className="compStatisticUserRightAreaStudent_Box">
                    <div className="compStatisticUserRightAreaStudent_Top">
                        <div className="compStatisticUserRightAreaStudent_Top_SearchDuration">
                            {SearchDuration()}
                            {!Utils.isEmpty(userSelectComboValue) && !Utils.isEmpty(searchDurationComboValue) &&
                                <button className="buttonExportUserViewedHistory" onClick={(e) => handleClick_Export(e)}>エクスポート</button>
                            }
                        </div>
                        <div className="compStatisticUserRightAreaStudent_Top_PlayAreaTable">
                            {playAreaTable}
                        </div>
                    </div>
                    <div className="compStatisticUserRightAreaStudent_Bottom">
                        <div className="compStatisticUserRightAreaStudent_Bottom_PlayStatus">
                            {playStatusPieChart}
                        </div>
                        <div className="compStatisticUserRightAreaStudent_Top_TagRanking">
                            {tagRanking}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const [compStatisticUserRightAreaTeacher, setCompStatisticUserRightAreaTeacher] = useState(<div />)
    const CompStatisticUserRightAreaTeacher = () => {
        if (Utils.isEmpty(searchDurationComboValue) || Utils.isEmpty(userSelectComboValue)) return;
        const strStartDate = Utils.ConvStrDateTime2Date(searchDurationCombo.slice(-1)[0].startDate, 'YYYYMMDD');
        const strEndDate = Utils.ConvStrDateTime2Date(new Date(), 'YYYYMMDD');
        RestBase.GET(
            Config.DaAlpsRestServer + `statistics/teacher/viewed_movie/${userSelectComboValue.user_id}/${strStartDate}/${strEndDate}/10`
        ).then((datas) => {
            const rankData = datas.map(data => {
                return {
                    contents: [data.asset_name],
                    count: data.view_count
                }
            })
            setCompStatisticUserRightAreaTeacher(
                <Ranking datas={rankData} titleText="担当講義の動画視聴ランキング" />
            )
        })
    }

    return (
        <div className="compStatisticUser">
            <div className="compStatisticUserLeftArea">
                <div className="compStatisticUserLeftArea_UserSelect">
                    {UserSelect()}
                </div>
                <div className="compStatisticUserLeftArea_BasicInfo">
                    {tableBasicInfo}
                </div>
                <div className="compStatisticUserLeftArea_Line3">
                    {userSelectComboValue ? userSelectComboValue.is_teacher ? teacherViewCount : commentHistory : <div />}
                </div>
            </div>
            <div className="compStatisticUserRightArea">
                {userSelectComboValue ? userSelectComboValue.is_teacher ? compStatisticUserRightAreaTeacher : compStatisticUserRightAreaStudent() : <div />}
            </div>
        </div>
    )
}