export default {
    // 必要に応じて、http -> https とする。

    // REST サーバー設定
    DaAlpsRestServer : "http://127.0.0.1/rest/asset_mgr/v1/",  // 本番環境、またはIISでの開発用。サブディレクトリ名(kycom_rest)が必要。
    //DaAlpsRestServer : "http://127.0.0.1/kycom_rest/asset_mgr/v1/",  // ローカル PC の IIS の場合
    //DaAlpsRestServer : "http://localhost:59671/asset_mgr/v1/",  // 開発環境用。(IIS の場合はサブディレクトリ名 kycom_rest が必要。)

    // 旧 Education 版のサーバー。
    // サムネイルを読む目的なので、ファイルへアクセスできれば良い。
    DaAlpsWebServer : "http://127.0.0.1",  // サムネイルが「http://<IPアドレス>/DaAlps_VOD/VOD/86/thumbnail.png」のような URL の場合。
    //DaAlpsWebServer : "http://127.0.0.1",    // サムネイルが「http://<IPアドレス>/DaAlps_VOD/VOD/86/thumbnail.png」のような URL の場合。
    //DaAlpsWebServer : "http://localhost:80/DaAlps_VOD/",  // 開発環境用？

    // Wowza または mp4 ファイルサーバー
    //UseWowza : true,  // Wowza を使う場合。
    //DaAlpsWowzaServer : "http://<IPアドレス>:1935/DaAlps/_definst_/",  // UseWowza = true 用
    DaAlpsWowzaServer : "http://127.0.0.1:1935/DaAlps/_definst_/",  // UseWowza = true 用
    UseWowza : true,  // Wowza を使わず、IIS などで直接 mp4 を公開する場合。
    //DaAlpsWowzaServer : "https://621ef36cf013a.streamlock.net:444/DaAlps/_definst_/",  // UseWowza = false 用。旧 Education 版の DaAlps_VOD フィルダー内に WowzaVOD へのシンボリックリンクを置く場合など。

    // 新規取り込み用の、ファイルアップロード用サーバー
    DaAlpsTusServer : "http://127.0.0.1/upload/files",  // ファイルアップロード用サーバー。本番環境、またはIISでの開発用。
    //DaAlpsTusServer : "http://localhost:5091/files",  // IISを使わず「#開発用サーバー上で実行.bat」でファイルサーバーを起動する場合の設定。
};
