import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import './App.css';
import * as Signin from "./pages/signin"
import * as Home from "./pages/home"
import * as UploadVideo from "./pages/upload_video"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Signin.Signin />} />
        <Route path="/signin/" element={<Signin.Signin />} />
        <Route path="/home/" element={<Home.Home />} />
        <Route path="/upload_video/" element={<UploadVideo.UploadVideo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
