// compVideoJS.js

import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {id, options, onReady, hotkeys } = props;

  React.useEffect(() => {
    // 映像ソースが変った場合は、videojsを再生成する
    if (playerRef.current) {
      const player = playerRef.current;
      if (player.currentSrc() !== options.sources[0].src) {
        player.dispose();
        playerRef.current = null;
      }
    }

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");
      videoElement.id = id;
      videoElement.classList.add('video-js');
      videoElement.classList.add('vjs-default-skin');
      videoElement.classList.add('videoPlayerFromVideoViewer');
      videoElement.crossOrigin = 'Anonymous';

      videoRef.current.appendChild(videoElement);

      const extendedOptions = { ...options };
      if (hotkeys) {
        extendedOptions.userActions = {
          hotkeys: hotkeys
        };
      }

      const player = playerRef.current = videojs(videoElement, extendedOptions, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });
    }

  }, [options, videoRef, hotkeys]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
}

export default VideoJS;
