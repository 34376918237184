import React, { useContext, useEffect, useState, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import "./compFileList.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as AssetUtil from "../utils/AssetUtils"
import Config from "../config"
import * as RestBase from '../utils/RestBase.js'
import * as Home from "../pages/home"
import CompSearchAndFilter from "./compSearchAndFilter"
import CompFileListItem from "./compFileListItem";

export default function (props) {
	const navigate = useNavigate();
	const [fileDeleted, setFileDeleted] = (Utils.isEmpty(props.stateFileDeleted) ? [[], null] : props.stateFileDeleted)
	const [condFileSearchAndFilter, setCondFileSearchAndFilter] = useState({});
	const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
	const FileRestoreCallback = (Utils.isEmpty(props.onFileRestoredCallback) ? () => { } : props.onFileRestoredCallback);
    const [analyzeSettings, setAnalyzeSettings] = useState({ cinnamon_enabled: false });
    const [isWordCloud, setIsWordCloud] = useState(false);

	const condRestSearch = useMemo(() => {
		// var category_ids = null;
		// if(Utils.isEmpty(condFileSearchAndFilter) != true) {
		//     category_ids = (condFileSearchAndFilter.faculties.length > 0) ? condFileSearchAndFilter.faculties : null;
		// }

		return (
			{
				asset_ids: null,
				children_table: true,
				children_table_meta: true,
				text: condFileSearchAndFilter.text,
                include_speech_analysis: condFileSearchAndFilter.include_speech_analysis,
                datetime: condFileSearchAndFilter.datetime,
				file_format_ids: [Defines.ASSET_FILE_FORMAT_ID.MOVIE, Defines.ASSET_FILE_FORMAT_ID.RESERVE_DELETE],   // Movieのみ
                department_id: condFileSearchAndFilter.department_id,
                teacher_id: condFileSearchAndFilter.teacher_id,
			}
		)
	}, [condFileSearchAndFilter]);

	const [resultsValue, setResultsValue] = useState([]);

    useEffect(() => {
        (async () => {
            const resultSettings = await AssetUtil.AsyncGetAnalyzeSettings();
            setAnalyzeSettings(resultSettings);
        })();
    }, []);

	useEffect(() => {
		setResultsValue([]);

		const respRest = RestBase.POST(
			Config.DaAlpsRestServer + "assets/search"
			, condRestSearch
		).then((data) => {
			var assets = data.assets;

			setResultsValue(assets);
		});
	}, [condRestSearch]);

	useEffect(() => {
		RestBase.POST(
			Config.DaAlpsRestServer + "assets/search"
			, condRestSearch
		).then((data) => {
			var assets = data.assets;

			setResultsValue(assets);
		});
	}, [fileDeleted])

	const handleNewIngestClick = async (e) => {
		e.preventDefault();
		var popup = window.open("/upload_video/", "_blank"/*, "noopener noreferrer"*/); // for New tab
		// navigate("/upload_video/");
	}

	useEffect(() => {
		const index = resultsValue.findIndex(item => item.asset_id == selectAsset.asset_id);
		if (index >= 0) {
			resultsValue[index] = selectAsset;
			//console.log(selectAsset);
		}
	}, [selectAsset])

	const isFromComponent = useMemo(() => {
		if (props.isFromComponent == Defines.NAME_COMPONENT.MANAGEMENT) return Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id;
		else if (props.isFromComponent == Defines.NAME_COMPONENT.MOVIE_WATCH) return Defines.SELECT_TAB.MOVIE_WATCH.MOVIE_SEARCH.id;
	}, []);

	const isCanDelete = useMemo(() => {
		return false;
	}, []);

	const isCanFavorite = useMemo(() => {
		if (props.isFromComponent == Defines.NAME_COMPONENT.MANAGEMENT) return false;
		else if (props.isFromComponent == Defines.NAME_COMPONENT.MOVIE_WATCH) return true;
	}, []);

	return (
		<div className="compFileList gridFileList">
			<CompSearchAndFilter
				condFileSearchAndFilter={[condFileSearchAndFilter, setCondFileSearchAndFilter]}
				componentParent="fromDeleteFileList"
			/>

			<div className="bodyFileListItem">
				{
					resultsValue.map((item, index) => {
						return (
							<CompFileListItem
								key={index}
								isFromComponent={Defines.SELECT_TAB.MANAGEMENT.DELETED_MOVIE.id}
								asset={item}
								condFileSearchAndFilter={[condFileSearchAndFilter, setCondFileSearchAndFilter]}
								isCanDelete={isCanDelete}
								onFileRestoredCallback={FileRestoreCallback}
								isCanFavorite={isCanFavorite}
								isCanForceDelete={true}
								stateHistoryAssetIds={props.stateHistoryAssetIds}
								stateFavoriteAssetIds={props.stateFavoriteAssetIds}
                                analyzeSettings={analyzeSettings}
                                isWordCloud={isWordCloud}
							/>
						)
					})
				}
			</div>

			<div className="areaNewIngest widthFull">
				<table width="100%">
					<tbody>
					<tr>
						{analyzeSettings.cinnamon_enabled &&
                            <td align="left">
								<input type="checkbox" name="thumbnailFromWordCloudOnOff_Delete" id="thumbnailFromWordCloudOnOff_Delete"
									checked={isWordCloud} onChange={() => { setIsWordCloud(!isWordCloud) }} />
								<label htmlFor="thumbnailFromWordCloudOnOff_Delete">サムネイルをワードクラウド表示に切替</label>
							</td>
						}
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}