import React, { useState, useEffect } from "react";
import "./compAssetTag.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as AssetUtils from "../utils/AssetUtils"
import * as RestBase from "../utils/RestBase"
import Config from "../config"

export default function (props) {
    const [selectAsset, setSelectAsset] = Utils.isEmpty(props.asset) ? [null, null] : props.asset;
    const title = props.title;
    const [isDispAll, setIsDispAll] = useState(props.isDispAll);
    const [showDispButton, setShowDispButton] = useState(!props.isDispAll);
    const [showMore, setShowMore] = useState(true);
    const [selectedTag, setSelectedTag] = useState({
        tag: null,
        index: null
    });
    const [dragIndex, setDragIndex] = useState(null);
    const [tagArray, setTagArray] = useState([]);
    const [sortTagArray, setSortTagArray] = useState([]);
    const [tagAreaWidth, setTagAreaWidth] = useState(0);
    useEffect(() => {
        const taglist = document.getElementById("tagDragList");
        if (!Utils.isEmpty(taglist)) {
            const setWidth = document.getElementById("tagDragList").clientWidth
            setTagAreaWidth(setWidth)
        }
    })

    const dispTagText = ["▼ more", "▲ hide"]
    const createTagArray = (asset) => {
        var tagArray = [];
        asset.tags.map(tag => {
            const index = tagArray.findIndex(item => item.tag == tag.tag);
            if (index >= 0) {
                tagArray[index].pages.push(tag.frame);
            } else {
                tagArray.push({
                    asset_id: tag.asset_id,
                    tag: tag.tag,
                    disp_order: tag.disp_order,
                    pages: [
                        tag.frame
                    ]
                })
            }
        })
        tagArray.sort((a, b) => a.disp_order - b.disp_order)
        return tagArray;
    }
    const resetTagViewState = () => {
        setIsDispAll(props.isDispAll);
        setShowDispButton(!props.isDispAll);
        setShowMore(true);
    }
    useEffect(() => {
        if (Object.keys(selectAsset).length != 0) {
            setTagArray(createTagArray(selectAsset));
        }
        resetTagViewState();
    }, [selectAsset])

    const handleTagClick = (item, tag) => {
        let frame;
        // if same tag clicked, set frame to next.
        let index = 0;
        if (selectedTag == null || selectedTag.tag != tag.tag) {
            frame = tag.pages[index];
        } else {
            index = (selectedTag.index + 1) % tag.pages.length;
            frame = tag.pages[index];
        }
        setSelectedTag({
            tag: tag.tag,
            index: index
        })
        const secFloat = AssetUtils.GetSecFloatFromTC(item, frame);

        Utils.SetVideoCurrentTime(secFloat);
        RestBase.POST(
            Config.DaAlpsRestServer + "assets/tag/press",
            {
                asset_id: selectAsset.asset_id,
                tag: tag.tag
            }
        )
    }

    const handleDispHideButtonClick = (e) => {
        setShowMore(!showMore);
        setIsDispAll(!isDispAll);
    }

    const returnTags = ((asset, isDragEnabled) => {
        const postTagOrder = async (tags) => {
            return tags.map(async (tag, index) => {
                await RestBase.POST(
                    Config.DaAlpsRestServer + "assets/tag/order",
                    {
                        asset_id: tag.asset_id,
                        tag: tag.tag,
                        disp_order_before: tagArray[index].disp_order,
                        disp_order_after: tag.disp_order
                    }
                )
            })
        }

        const cropTagArray = (array) => {
            let vis_width = 0;
            if (Utils.isEmpty(document.getElementById("tagDragList"))) return [];
            let area_width = tagAreaWidth;
            if (area_width <= 0) return []
            let crop_index = array.length - 1;
            let is_index_calced = false;
            const more_button_size = 80;
            array.map((item, index) => {
                vis_width += 15 + 9 + 13.4 * item.tag.length;
                if (!is_index_calced && vis_width >= area_width - more_button_size) {
                    crop_index = Math.max(index - 1, 0);
                    is_index_calced = true;
                }
            });
            if (!is_index_calced) setShowDispButton(false);
            return array.slice(0, crop_index);
        }
        if (!Utils.isEmpty(selectAsset)) {
            const mapArray = showDispButton && showMore ? cropTagArray(tagArray) : tagArray;
            const tags = mapArray.map((item, index) => {
                if (isDragEnabled) {
                    return (
                        <button key={index} id={index} className="buttonTag" onClick={(e) => handleTagClick(selectAsset, item)}
                            draggable="true"
                            onDragStart={(e) => { console.log(e); setDragIndex(index) }}
                            onDragEnter={(e) => {
                                console.log(e);
                                const targetIndex = e.target.id;
                                if (index != dragIndex) {
                                    let tagArray_sort = Object.assign([], tagArray);
                                    const temp = tagArray_sort[targetIndex];
                                    tagArray_sort[targetIndex] = tagArray_sort[dragIndex];
                                    tagArray_sort[dragIndex] = temp;
                                    setSortTagArray(tagArray_sort.map((tag, index) => { tag.disp_order = index; return tag; }));
                                }
                            }}
                            onDragEnd={() => {
                                setDragIndex(null)
                                postTagOrder(sortTagArray)
                                    .then(() => {
                                        setTagArray(sortTagArray);
                                        RestBase.GET(
                                            Config.DaAlpsRestServer + `assets/${selectAsset.asset_id}`
                                        ).then(data => {
                                            console.log(data.asset);
                                            setSelectAsset(data.asset);
                                        })
                                    });
                            }}
                            onDragOver={(e) => { e.preventDefault() }}
                        >
                            #{item.tag}
                        </button>
                    )
                } else {
                    return (
                        <button key={index} id={index} className="buttonTag" onClick={(e) => handleTagClick(selectAsset, item)}>
                            #{item.tag}
                        </button>
                    )
                }
            })

            return (
                <div id="areaTagDragList">
                    <ul className="drag-list" id="tagDragList">
                        {tags}
                        {showDispButton && <button className="buttonTagDispAll" onClick={(e) => handleDispHideButtonClick(e)}>{showMore ? dispTagText[0] : dispTagText[1]}</button>}
                    </ul>
                </div>
            )
        } else {
            return;
        }
    })
    const isDragEnabled = Utils.isEmpty(props.isDragEnabled) ? false : props.isDragEnabled;
    return (
        <div className="compAssetTag">
            <div className="areaLabelTitle" style={{ width: "100%", padding: "0px 0px 2px 0px" }}><label className="labelTitle">{title}</label><br /></div>
            {returnTags(selectAsset, isDragEnabled)}
        </div>
    )
}
