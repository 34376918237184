// upload_video.js

import React, { useState, useRef, useEffect } from "react";
import Select from 'react-select'
import "./upload_video.css";
import "video.js/dist/video-js.css";
import VideoJS from '../components/compVideoJS';
import { EditableComboboxMeta } from '../components/compEditableComboboxMeta';
import Config from "../config";
import { Upload } from "tus-js-client";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as RestBase from '../utils/RestBase.js'
import * as UserInfo from "../common/userInfo"

export function UploadVideo() {
  const userInfo = UserInfo.getUsetInfo();

	const headers = ["ID", "氏名", "視聴可能"];
  const [affiliationList, setAffiliationList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [studentList, setStudentList] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);

  const [lectureName, setLectureName] = useState("");
  const [year, setYear] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [department, setDepartment] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [roomName, setRoomName] = useState("");
	const [checkedStudents, setCheckedStudents] = useState([]);

  const [uploadProgressModalVisible, setUploadProgressModalVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [shouldUpdatePlayer, setShouldUpdatePlayer] = useState(true);

  const uploadRef = useRef(null);

  useEffect(() => {
    // aquire departments
    RestBase.GET(Config.DaAlpsRestServer + "Department")
    .then((datas) => {
      if (datas.length > 0) {
        const listOptions = datas.map((data) => ({
          value: data.fomsrec_department_id,
          label: data.name,
        }));
        setAffiliationList(listOptions);
      }
    });
    // aquire teachers
    RestBase.GET(Config.DaAlpsRestServer + "user?is_teacher=true")
    .then((datas) => {
      if (datas.length > 0) {
        const listOptions = datas.map((data) => ({
          value: data.fomsrec_user_id,
          label: data.name,
        }));
        setTeacherList(listOptions);
      }
    });
    // aquire students
    RestBase.GET(Config.DaAlpsRestServer + "user?is_student=true")
    .then((datas) => {
      if (datas.length > 0) {
        const checked = datas.map((d) => false);
        setCheckedStudents(checked);
        setStudentList(datas);

        // // Dummy data for adjust the screen.
        // const dmyStudents = [];
        // const dmyChecked = [];
        // for (let i = 0; i < 100; i++)
        // {
        //   dmyStudents.push({fomsrec_user_id: "id" + i, name: "ユーザ"+(i+1) });
        //   dmyChecked.push(false);
        // }
        // setCheckedStudents(dmyChecked);
        // setStudentList(dmyStudents);
      }
    });

    // Prevent the browser from opening the file when dropped on the window and open the video file in the video player
    const handleWindowDragOver = (e) => {
      e.preventDefault();
    };

    const handleWindowDrop = (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (file && file.type.startsWith("video/")) {
        handleFileInput(file);
      }
    };

    window.addEventListener("dragover", handleWindowDragOver);
    window.addEventListener("drop", handleWindowDrop);

    return () => {
      window.removeEventListener("dragover", handleWindowDragOver);
      window.removeEventListener("drop", handleWindowDrop);
    };
  }, []);

  const toggleUploadProgressModal = (visible) => {
    setUploadProgressModalVisible(visible);
    setShouldUpdatePlayer(!visible);
  };

  // Cancel page.
  const handleCancel = () => {
    window.open('', '_self').close(); // for New tab
    // navigate(-1);
  };

  const handleFileInput = (file) => {
    setSelectedFile(file);
    setVideoFile(URL.createObjectURL(file));
  };

  const handleVideoSelect = (e) => {
    handleFileInput(e.target.files[0]);
    e.target.value = null; // Reset the input value
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("video/")) {
      handleFileInput(file);
    }
  };

  const handleCancelUpload = () => {
    if (uploadRef.current) {
      uploadRef.current.abort();
    }
    toggleUploadProgressModal(false);
  };

  const handleRawChange = (e) => {
    const inputValue = e.target.value;  // Enable input by keyboard.
  };

  // Only to change height of DatePicker.
  const StyledInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="date-picker-style"
      type="text"
      value={value}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ));

  const hotkeys = function (event) {
    if (event.which === 32) { // Space bar
      event.preventDefault(); // Prevent default scrolling
      if (this.paused()) {
        this.play();
      } else {
        this.pause();
      }
    }
    if (event.which === 39) { // Right arrow
      event.preventDefault(); // Prevent default scrolling
      const newTime = this.currentTime() + 5;
      this.currentTime(newTime);
    }

    if (event.which === 37) { // Left arrow
      event.preventDefault(); // Prevent default scrolling
      const newTime = this.currentTime() - 5;
      this.currentTime(Math.max(newTime, 0)); // Ensure the new time is not negative
    }    
    // if (event.which === 88) {
    //   this.pause();
    // }
    // if (event.which === 89) {
    //   this.play();
    // }
  };

	const handleClick_Check = targetIndex => {
		setCheckedStudents(checkedStudents.map((item, index) => index == targetIndex ? !item : item))
	}

	const handleClick_CancelAll = () => {
		setCheckedStudents(checkedStudents.map(() => false));
	}

	const handleClick_SelectAll = () => {
		setCheckedStudents(checkedStudents.map(() => true));
	}

  const uploadVideoFile = (uploadedThumbnailFileNameOnly) => {
    // console.log("lectureName:", lectureName);
    // console.log("year:", year);
    // console.log("departmentId/department:", departmentId, department);
    // console.log("teacherId/teacherName:", teacherId, teacherName);
    // console.log("date:", date);
    // console.log("time:", time);
    // console.log("roomName:", roomName);

    // Input validation
    if (!selectedFile || !selectedFile.type.startsWith("video/")) {
      alert("「動画ファイル」 を選択してください。");
      return;
    } else {
      if (!lectureName) {
        alert("「講義名」 を入力、または、選択してください");
        return;
      }
      if (!year) {
        alert("「年度」 を入力、または、選択してください");
        return;
      }
      if (!department) {
        alert("「所属」 を選択してください");
        return;
      }
      if (!teacherName) {
        alert("「教員名」 を選択してください");
        return;
      }
      if (!time) {
        alert("「時限」 を入力、または、選択してください");
        return;
      }
      if (!roomName) {
        alert("「教室名」 を入力、または、選択してください");
        return;
      }
    }

    if (selectedFile) {
      // console.log("Selected file:", selectedFile);
      // console.log("File name:", selectedFile.name);
      // console.log("File type:", selectedFile.type);
      // console.log("File size:", selectedFile.size);
      // console.log("File Upload Server:", Config.DaAlpsTusServer);

      let viewableStudents = [];
      studentList.map((user, index) => { if (checkedStudents[index]) { viewableStudents.push(user.fomsrec_user_id); } });

      uploadRef.current = new Upload(selectedFile, {
        endpoint: Config.DaAlpsTusServer,
        chunkSize: 10 * 1024 * 1024,
        retryDelays: [0, 1000, 3000, 5000],
        metadata: {
          filename: selectedFile.name,
          filetype: selectedFile.type,
        },
        onError: (error) => {
          toggleUploadProgressModal(false);
          console.error("Failed to upload file:", error);
          alert("Failed to upload file. Please try again.");
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(bytesUploaded, bytesTotal, percentage + "%");
          setProgress(percentage);
        },
        onSuccess: () => {
          toggleUploadProgressModal(false);
          console.log("Upload complete. File uploaded to:", uploadRef.current.url);

          const uploadedFileUrl = new URL(uploadRef.current.url);
          const uploadedFileNameOnly = uploadedFileUrl.pathname.split('/').pop();
          console.log("Uploaded File Name:", uploadedFileNameOnly);

          fetch(Config.DaAlpsRestServer + "assets/ingest/uploaded", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              uploaded_filename: uploadedFileNameOnly,
              original_filename: selectedFile.name,
              meta: {
                LectureName: lectureName,
                Year: year,
                DepartmentID: departmentId,
                Department: department,
                UserID: teacherId,
                TeacherName: teacherName,
                Date: Utils.GetMetaDate(date),
                TimeTable: time,
                RoomName: roomName,
              },
              viewable_fomsrec_user_ids: viewableStudents,
            }),
          })
          .then(async (response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            alert("動画をアップロードしました。実際に登録されるまで、しばらく時間が掛かります。");
          })
          .catch((error) => {
            console.error("Error fetching video properties:", error);
            alert("動画の登録に失敗しました。");
          });
        },
      });
  
      toggleUploadProgressModal(true);
      uploadRef.current.start();
    }
  };
  
  return (
    <div
      className="uploadVideoContainer"
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleFileDrop}
    >
      <h1>動画ファイルの新規取り込み</h1>
      <input
        type="file"
        accept="video/*"
        id="videoFile"
        onChange={handleVideoSelect}
        style={{ display: "none" }}
      />

      {uploadProgressModalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h2>アップロードしています</h2>
            <div id="upload-progress" className="upload-progress">
              <progress
                value={progress}
                max="100"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <button onClick={handleCancelUpload} className="cancel-button">
              キャンセル
            </button>
          </div>
        </div>
      )}

      <div className="bottom-large-mergin">
        <button
          className="videoSelectButton backgroundGrayGradiation button-round"
          onClick={() => document.getElementById("videoFile").click()}
        >
          動画ファイルを選択
        </button>
      </div>

      <div className="bottom-large-mergin">
        {!videoFile && (
          <div className="videoPlayerContainer">
            <div className="dragDropMessage">
              ここに動画ファイルをドラッグアンドドロップするか、
              <br />
              「動画ファイルを選択」ボタンをクリックしてください。
            </div>
          </div>
        )}
        {videoFile && (
          <div className="videoPlayerContainer">
            <VideoJS
              className="video-js"
              options={{
                controls: true,
                preload: 'auto',
                sources: [
                  {
                    src: videoFile,
                    type: 'video/mp4',
                  },
                ],
              }}
              hotkeys={hotkeys}
              updatePlayer={shouldUpdatePlayer} // Update this prop with the new state value
            />
          </div>
        )}
      </div>

      <div className="form-container">
        <div className="form-group bottom-small-mergin">
          <label className="label-fixed-width">{Defines.ASSET_META_ID.LECTURE_NAME.dispName}</label>
            <EditableComboboxMeta
              meta_id={Defines.ASSET_META_ID.LECTURE_NAME.id}
              value={lectureName}
              onChange={setLectureName}
            />
        </div>
        <div className="form-group bottom-small-mergin">
          <label className="label-fixed-width">{Defines.ASSET_META_ID.YEAR.dispName}</label>
          <EditableComboboxMeta
            meta_id={Defines.ASSET_META_ID.YEAR.id}
            value={year}
            onChange={setYear}
          />
        </div>
        <div className="form-group bottom-small-mergin">
          <label className="label-fixed-width">{Defines.ASSET_META_ID.AFFILIATION.dispName}</label>
          <Select className="custom-select" options={affiliationList}
            onChange={(selectedOption) => {
              setDepartmentId(selectedOption.value);
              setDepartment(selectedOption.label);
            }
          }/>
        </div>
        <div className="form-group bottom-small-mergin" style={{ marginBottom: '0px' }}>
          <label className="label-fixed-width">{Defines.ASSET_META_ID.TEACHER.dispName}</label>
          <Select className="custom-select" options={teacherList}
            onChange={(selectedOption) => {
              setTeacherId(selectedOption.value);
              setTeacherName(selectedOption.label);
            }
          }/>
        </div>
        <div className="form-group bottom-small-mergin">
          <label className="label-fixed-width">{Defines.ASSET_META_ID.DAY_ATTENDED.dispName}</label>
          <DatePicker
            className="datePicker"
            selected={date}
            onChange={(e) => setDate(e)}
            onChangeRaw={(e) => handleRawChange(e)} // Handle the raw input change event
            dateFormat="yyyy/MM/dd"
            locale="ja"
            placeholderText="日付"
            customInput={<StyledInput />}
          />
        </div>
        <div className="form-group bottom-small-mergin">
          <label className="label-fixed-width">{Defines.ASSET_META_ID.NUM_PERIOD.dispName}</label>
          <EditableComboboxMeta
            meta_id={Defines.ASSET_META_ID.NUM_PERIOD.id}
            value={time}
            onChange={setTime}
          />
        </div>
        <div className="form-group bottom-small-mergin">
          <label className="label-fixed-width">{Defines.ASSET_META_ID.ROOM_NAME.dispName}</label>
          <EditableComboboxMeta
            meta_id={Defines.ASSET_META_ID.ROOM_NAME.id}
            value={roomName}
            onChange={setRoomName}
          />
        </div>

        <div className="form-group">
					<div>
            <label className="label-fixed-width"><br/>視聴可能</label>
						<button className="buttonTableTop backgroundGrayGradiation buttonPadding" onClick={() => { handleClick_SelectAll() }}>全選択</button>
						<button className="buttonTableTop backgroundGrayGradiation buttonPadding" onClick={() => { handleClick_CancelAll() }}>全解除</button>
        </div>
        <div className="areaTableStudents">
            <table className="tableStudents" width="100%">
						<tr className="tableStudentsHeader">
							<th width="100px">{headers[0]}</th>
							<th width="130px">{headers[1]}</th>
							<th width="100px">{headers[2]}</th>
						</tr>
						{studentList.map((user, index) => {
							return (
								<tr className="tableStudentsBody">
									<td>{user.fomsrec_user_id}</td>
									<td>{user.name}</td>
									<td><input
										type="checkbox"
										id={`check_${index}`}
										index={index}
										checked={checkedStudents[index]}
										onClick={() => { handleClick_Check(index) }}
										readOnly
									/>
									</td>
								</tr>
							)
						})}
					</table>
					</div>
				</div>
			</div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="buttonNewIngest backgroundGrayGradiation button-round"
          id="buttonNewIngest"
          onClick={uploadVideoFile}
        >
          <label className="cursorUnset" htmlFor="buttonNewIngest">
            <img src="../images/upload.png" alt="" />
          </label>
          <label className="cursorUnset" htmlFor="buttonNewIngest">
            動画をアップロード
          </label>
        </button>

        <div className="bottom-large-mergin">
          <button onClick={handleCancel} className="returnButton backgroundGrayGradiation button-round-short">
            キャンセル
          </button>
        </div>
      </div>

    </div>
  );
}
