import React, { useContext, useEffect, useState, useMemo } from "react";

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as UserInfo from "../common/userInfo"
import CompFileList from "./compFileList"
import CompSpeechAnalyze from "./compSpeechAnalyze"
import CompTagInfo from "./compTagInfo"
import CompReferencesSetting from "./compReferencesSetting"

import "./compManagement.css";
import CompWatchInfo from "./compWatchInfo";
import CompViewLimitation from "./compViewLimitation";
import CompDeleteFileList from "./compDeleteFileList";

export const SelectManagementTabContext = React.createContext();

export default function () {
    const userInfo = UserInfo.getUsetInfo();
    const selectTabItems = userInfo.permission_id == Defines.USER_PERMISSION_ID.ADMIN ?
        [
            Defines.SELECT_TAB.MANAGEMENT.FILELSIT,
            Defines.SELECT_TAB.MANAGEMENT.SPEECH_ANALYZE,
            Defines.SELECT_TAB.MANAGEMENT.TAG_INFO,
            Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING,
            Defines.SELECT_TAB.MANAGEMENT.DELETED_MOVIE,
            Defines.SELECT_TAB.MANAGEMENT.VIEW_LIMITATION,
            Defines.SELECT_TAB.MANAGEMENT.WATCH_INFO,
        ] :
        [
            Defines.SELECT_TAB.MANAGEMENT.FILELSIT,
            Defines.SELECT_TAB.MANAGEMENT.SPEECH_ANALYZE,
            Defines.SELECT_TAB.MANAGEMENT.TAG_INFO,
            Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING,
            Defines.SELECT_TAB.MANAGEMENT.WATCH_INFO,
        ];

    var selectedTabIndex = 0;
    const [selectedTabItemsValue, setSelectedTabItemsValue] = useState(selectTabItems[selectedTabIndex].id);
    const handleChange_SelectedTabItems = (e) => {
        changeSelectedTabItem(e.target.value);
    };
    const changeSelectedTabItem = ((tab_id) => {
        setSelectedTabItemsValue(tab_id);
        selectedTabIndex = selectTabItems.findIndex((item) => item.id == tab_id);
    })

    var prevKeyupTimestamp = 0;
    function handleKeyup(e) {
        { // 重複発火に対応（かっこ悪い）
            const limitDurationKeyUpMsec = 10;
            if ((prevKeyupTimestamp + limitDurationKeyUpMsec) > e.timeStamp) return;
            prevKeyupTimestamp = e.timeStamp
        }

        if (e.target.tagName != 'BODY') return;

        if (e.key == "ArrowLeft") {
            var nextTabIndex = selectedTabIndex - 1;
            if (nextTabIndex < 0) nextTabIndex = selectTabItems.length - 1;
            if (nextTabIndex >= selectTabItems.length) nextTabIndex = 0;

            if (0 <= nextTabIndex && nextTabIndex < selectTabItems.length) {
                changeSelectedTabItem(selectTabItems[nextTabIndex].id);
            }
        } else if (e.key == "ArrowUp") {
            // nop
        } else if (e.key == "ArrowRight") {
            var nextTabIndex = selectedTabIndex + 1;
            if (nextTabIndex < 0) nextTabIndex = selectTabItems.length - 1;
            if (nextTabIndex >= selectTabItems.length) nextTabIndex = 0;

            if (0 <= nextTabIndex && nextTabIndex < selectTabItems.length) {
                changeSelectedTabItem(selectTabItems[nextTabIndex].id);
            }
        } else if (e.key == "ArrowDown") {
            // nop
        }
    }
    useEffect(() => {
        if (Defines.ENABLE_HANDLE_KEYUP_TAB_MOVE == true) {
            document.removeEventListener('keyup', handleKeyup);
            document.addEventListener('keyup', handleKeyup);
        } else {
            document.removeEventListener('keyup', handleKeyup);
        }
    }, []);

    const styleShow = { display: "contents" }
    const styleHidden = { display: "none" }

    const [fileDeleted, setFileDeleted] = useState(false);
    const FileDeleteCallback = () => {
        setFileDeleted(!fileDeleted);
    }

    const [fileRestored, setFileRestored] = useState(false);
    const FileRestoredCallback = () => {
        setFileRestored(!fileRestored);
    }

    return (
        <div className="compManagement widthFull fromTop" id="idAreaKeyUp">
            <SelectManagementTabContext.Provider value={[selectedTabItemsValue, setSelectedTabItemsValue]}>

                <div className="areaTab">
                    {selectTabItems.map((item) => {
                        return (
                            <span key={item.id}>
                                <input className="radioSelectTab" type="radio" name="selectedTab" value={item.id} id={item.id} onChange={(e) => handleChange_SelectedTabItems(e)} checked={item.id === selectedTabItemsValue} />
                                <label className="labelTab" htmlFor={item.id}>{item.dispName}</label>
                            </span>
                        )
                    })}
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MANAGEMENT.FILELSIT.id) ? styleShow : styleHidden}>
                    <span className="areaFileList">
                        <CompFileList isFromComponent={Defines.NAME_COMPONENT.MANAGEMENT} isOnlyDeliverd={false} onFileDeletedCallback={FileDeleteCallback} stateFileRestored={[fileRestored, setFileRestored]} />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MANAGEMENT.SPEECH_ANALYZE.id) ? styleShow : styleHidden}>
                    <span className="areaSpeechAnalyze">
                        <CompSpeechAnalyze isDispButtonAnalyze={true} isDispButtonInsertTag={true} isDispRemainTime={true} isDispConfidence={true} isDispFixed={true} />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MANAGEMENT.TAG_INFO.id) ? styleShow : styleHidden}>
                    <span className="areaTagInfo">
                        <CompTagInfo />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MANAGEMENT.REFERENCES_SETTING.id) ? styleShow : styleHidden}>
                    <span className="areaReferencesSetting">
                        <CompReferencesSetting />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MANAGEMENT.DELETED_MOVIE.id) ? styleShow : styleHidden}>
                    <span className="areaReferencesSetting">
                        <CompDeleteFileList stateFileDeleted={[fileDeleted, setFileDeleted]} onFileRestoredCallback={FileRestoredCallback} />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MANAGEMENT.VIEW_LIMITATION.id) ? styleShow : styleHidden}>
                    <span className="areaReferencesSetting">
                        <CompViewLimitation />
                    </span>
                </div>

                <div style={(selectedTabItemsValue === Defines.SELECT_TAB.MANAGEMENT.WATCH_INFO.id) ? styleShow : styleHidden}>
                    <span className="areaWatchInfo">
                        <CompWatchInfo admin={true} />
                    </span>
                </div>

            </SelectManagementTabContext.Provider>
        </div>
    )
}