import React, { useContext, useEffect, useState, useMemo } from "react";
import "./compRelatedAsset.css"

import * as Defines from "../common/defines"
import * as Utils from "../utils/Utils"
import * as Home from "../pages/home"
import * as AssetUtils from "../utils/AssetUtils"
import * as UserInfo from "../common/userInfo"
import CompFileListItem from "./compFileListItem";

export default function (props) {
    const userInfo = UserInfo.getUsetInfo();
    const [selectAsset, setSelectAsset] = useContext(Home.SelectAssetContext);
    const [favoriteAssetIds, setFavoriteAssetIds] = (Utils.isEmpty(props.stateFavoriteAssetIds) == true) ? [[], null] : props.stateFavoriteAssetIds;
    const [historyAssetIds, setHistoryAssetIds] = (Utils.isEmpty(props.stateHistoryAssetIds) == true) ? [[], null] : props.stateHistoryAssetIds;

    const [resultsValue, setResultsValue] = useState([]);
    useEffect(() => {
        if (Utils.isEmpty(selectAsset) == true) return;

        const related_ids = [];
        selectAsset.references.map((item) => {
            related_ids.push(item.reference_asset_id);
        })

        AssetUtils.AsyncGetAssetsFromAssetIds(related_ids)
            .then((data) => {
                const filterd = data.filter((item) => {
                    if (!(props.isOnlyDeliverd == true && item.status.delivery != Defines.ASSET_STATUS.ASSET_STATUS_DONE)) {
                        return item;
                    }
                })

                setResultsValue(filterd);
            })
    }, [selectAsset])

    const isCanFavorite = useMemo(() => {
        if (userInfo.user_id == 0) return false;
        else return true;
    }, []);

    return (
        <div className="compRelatedAsset">
            {
                resultsValue.map((item) => {
                    return (
                        <CompFileListItem
                            key={item.asset_id}
                            isFromComponent={Defines.SELECT_TAB.MOVIE_WATCH.RELATED_MOVIE.id}
                            asset={item}
                            condFileSearchAndFilter={[null, null]}
                            isCanDelete={false}
                            isCanFavorite={isCanFavorite}
                            stateHistoryAssetIds={[historyAssetIds, setHistoryAssetIds]}
                            stateFavoriteAssetIds={[favoriteAssetIds, setFavoriteAssetIds]}
                        />
                    )
                })
            }
        </div>
    )
}