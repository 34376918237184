// compVideoJS.js

import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const prevUpdatePlayerRef = React.useRef(null);
  const { options, onReady, updatePlayer, hotkeys } = props;
  const prevVideoFileNameRef = React.useRef(null);

  const captureCurrentFrame = (time) => {
    return new Promise((resolve) => {
      const player = playerRef.current;
      if (!player) return null;
      const currentTime = player.currentTime();

      if (time == undefined) {
        time = currentTime;
      }

      if (time !== undefined) {  // この中を skip すると、必要なイベントが発生せずにフレームがキャプチャーされない。そのため、カレントフレームの場合も time = currentTime としている。
        player.currentTime(time);  // おそらくこれが原因で、表示しているフレーム前後のフレームに移動してしまう事があるので、カレントフレームを取得するなら、captureCurrentFrame に time 引数を追加する前(開始から10秒のフレームを取得しようとする前のrev.)の処理の方がおそらく良い。
        const videoElement = player.el().querySelector("video");
        const onSeeked = () => {
          videoElement.removeEventListener("seeked", onSeeked);
          captureFrameAndResolve();
        };
        videoElement.addEventListener("seeked", onSeeked);
      } else {
        captureFrameAndResolve();
      }

      const captureFrameAndResolve = () => {
        const canvas = document.createElement("canvas");
        canvas.width = player.videoWidth();
        canvas.height = player.videoHeight();
        const ctx = canvas.getContext("2d");
        ctx.drawImage(player.el().querySelector("video"), 0, 0, canvas.width, canvas.height);
        if (time !== undefined) {
          player.currentTime(currentTime);
        }
        resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const extendedOptions = { ...options };

      if (hotkeys) {
        extendedOptions.userActions = {
          hotkeys: hotkeys
        };
      }

      const player = playerRef.current = videojs(videoElement, extendedOptions, () => {
        videojs.log('player is ready');
      onReady && onReady(player, captureCurrentFrame); // Pass the captureCurrentFrame function to the parent component
      //onCaptureFrame && onCaptureFrame(() => captureCurrentFrame(player)); // Pass the captureCurrentFrame function to the parent component
      });
    } else if (updatePlayer && prevUpdatePlayerRef.current == true) {
      const player = playerRef.current;

      // Get the current video file name
      const currentVideoFileName = options.sources && options.sources[0] ? options.sources[0].src.split('/').pop() : null;

      // Compare the previous and current video file names
      if (prevVideoFileNameRef.current !== currentVideoFileName) {
        player.autoplay(options.autoplay);
        player.src(options.sources);

        // Update the previous video file name ref
        prevVideoFileNameRef.current = currentVideoFileName;
      }
    }
    prevUpdatePlayerRef.current = updatePlayer;

  }, [options, videoRef, hotkeys]);

  // Set the initial value for prevVideoFileNameRef
  React.useEffect(() => {
    const source = options.sources && options.sources[0] ? options.sources[0].src : null;
    prevVideoFileNameRef.current = source ? source.split('/').pop() : null;
  }, []);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
}

VideoJS.defaultProps = {
  updatePlayer: true,
};

export default VideoJS;
