import { useContext, useState } from "react";
import "./homeRight.css";

import * as Defines from "../common/defines"
import * as Home from "./home"
import CompManagement from "../components/compManagement"
import CompMovieWatch from "../components/compMovieWatch"
import CompStatistic from "../components/compStatistic"
import CompSettings from "../components/compSettings"

export function HomeRight() {
    const [selectLeft, setSelectLeft] = useContext(Home.SelectLeftContext);

    return(
        <div className="homeRight" >
            {selectLeft === Defines.SELECCT_LEFT.MANAGEMENT.id &&
                <CompManagement />
            }
            {selectLeft === Defines.SELECCT_LEFT.MOVIE_WATCH.id &&
                <CompMovieWatch />
            }
            {selectLeft === Defines.SELECCT_LEFT.STATISTIC.id &&
                <CompStatistic />
            }
            {selectLeft === Defines.SELECCT_LEFT.SETTINGS.id &&
                <CompSettings />
            }
        </div>
    )
}