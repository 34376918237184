export const graphCommonOptions = {
	responsive: true,
	maintainAspectRatio: true,
	plugins: {
		title: {
			display: true,
			font: {
				size: 24,
				weight: "bold",
				family: "BIZ UDPゴシック"
			},
			color: "rgba(38, 119, 158, 1.0)",
			align: "start",
			padding: {
				bottom: 20
			}
		},
		layout: {
			padding: {
				right: 20
			}
		},
	}
}

export const viewCountOptions = {
	...graphCommonOptions,
	plugins: {
		...graphCommonOptions.plugins,
		title: {
			...graphCommonOptions.plugins.title,
			text: '再生数の推移',
		},
		legend: {
			...graphCommonOptions.plugins.legend,
			display: false,
		},
	},
	scales: {
		y: {
			title: {
				display: true,
				text: "再生回数",
				font: {
					size: 18,
				}
			},
			suggestedMin: 0,
			display: true,
			ticks: {
				font: {
					size: 0
				},
				padding: 12
			}
		},
		x: {
			grid: {
				display: false
			}
		}
	},
	elements: {
		point: {
			pointRadius: 4,
			pointBackgroundColor: 'rgb(38, 119, 158)',
		}
	}
}

export const viewTimeOptions = {
	...graphCommonOptions,
	plugins: {
		...graphCommonOptions.plugins,
		title: {
			...graphCommonOptions.plugins.title,
			text: '再生箇所',
		},
		legend: {
			...graphCommonOptions.plugins.legend,
			display: false,
		},
	},
	scales: {
		y: {
			title: {
				display: true,
				text: "再生回数",
				font: {
					size: 18,
				}
			},
			suggestedMin: 0,
			display: true,
			ticks: {
				font: {
					size: 16
				},
				padding: 12
			}
		},
		x: {
			grid: {
				display: false
			}
		}
	},
	elements: {
		point: {
			pointRadius: 0,
			pointBackgroundColor: 'rgb(38, 119, 158)',
		}
	}
}

export const viewStatusOptions = {
	...graphCommonOptions,
	plugins: {
		...graphCommonOptions.plugins,
		title: {
			...graphCommonOptions.plugins.title,
			text: "視聴状況",
			font: {
				size: 24
			}
		},
		legend: {
			...graphCommonOptions.plugins.legend,
			position: "bottom",
			align: "end",
			labels: {
				font: {
					size: 20,
				}
			}
		}
	}
}

export const speechAnalyzeTimeOptions = {
	...graphCommonOptions,
	plugins: {
		...graphCommonOptions.plugins,
		title: {
			...graphCommonOptions.plugins.title,
			text: '音声解析所要時間',
		},
		legend: {
			...graphCommonOptions.plugins.legend,
			display: false,
		},
	},
	scales: {
		y: {
			title: {
				display: true,
				text: "音声解析にかかる時間(分)",
				font: {
					size: 18,
				}
			},
			suggestedMin: 0,
			display: true,
			ticks: {
				font: {
					size: 0
				},
				padding: 12
			}
		},
		x: {
			title: {
				display: true,
				text: "動画のデュレーション(分)",
				font: {
					size: 18,
				}
			},
			grid: {
				display: false
			}
		}
	},
	elements: {
		point: {
			pointRadius: 4,
			pointBackgroundColor: 'rgb(38, 119, 158)',
		}
	}
}