import "./compEditableComboboxMeta.css";

import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

import * as RestBase from '../utils/RestBase.js'
import Config from "../config";

export function EditableComboboxMeta({ meta_id, value, onChange, placeholder = '' }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchOptions(setOptions, meta_id);
  }, [meta_id]);

  const handleBlur = (inputValue) => {
    if (inputValue !== '') {
      onChange(inputValue);
    }
  };

  return (
    <CreatableSelect
      options={options}
      isClearable={true}
      placeholder={placeholder}
      value={value ? { value: value, label: value } : null}
      onChange={(selectedOption) => {
        if (selectedOption) {
          onChange(selectedOption.value);
        } else {
          onChange('');
        }
      }}
      onBlur={(e) => handleBlur(e.target.value, e)}
      isValidNewOption={() => false}
      noOptionsMessage={() => null}
      className="custom-creatable-select"
    />
  );
}

function fetchOptions(setOptions, meta_id) {
  RestBase.GET(Config.DaAlpsRestServer + 'meta/' + meta_id)
  .then((data) => {
    let list_of_values = data || [];
    const listOfObjects = list_of_values.map((string) => ({
      value: string,
      label: string,
    }));      
    setOptions(listOfObjects);
  });
}
